import {useEffect, useRef, useState, useContext} from "react";

import axios from 'axios';
import config from "config";
import jQuery from 'jquery';

const instance = axios.create({
    baseURL: config.API_URL_FOOD,
});

const instance_two = axios.create({
    baseURL: config.API_URL,
});
const instance_two_satelite= axios.create({
    baseURL: config.API_URL,
});
instance.interceptors.request.use(async (conf) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var auth = "c42690cc30e581023adf4a4341e5395f";
    conf.headers.Authorization = auth;
    conf.headers.ContentType = 'application/json';
    return conf;
});

instance_two.interceptors.request.use(async (conf) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var auth = "c42690cc30e581023adf4a4341e5395f";
    conf.headers.Authorization = auth;
    conf.headers.ContentType = 'multipart/form-data';
    return conf;
});

// export const getAll = async () => (
//     await instance.post('users/all')
// );
// export const register_send_kdes = async (name, second_name, email, empresa, userType, trazabilidad, codeT) => {
//     await instance_two.post('register_send_kdes', {name, second_name, email, empresa, userType, trazabilidad, codeT})
// );

//await axios.post(config.API_URL+'/login',{email:email,password:password})
export const register = async (name, second_name, email, empresa, pais, trazabilidad, password, userType, telefono, cargo, dni, codeProd) => {
if (window.navigator.onLine) {
        return await instance_two.post('register', {name, second_name, email, empresa, pais, trazabilidad, password, userType, telefono, cargo, dni, codeProd})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const register_colab = async (name, email, entidad, password, autorice, trazabilidad,check_guide_pt, establishment, number_incription,select_mrv, userType, idUser, dataCheck, dataTraza, dataTrazaM, dataNamaG, dataProd, dataVFGC, permissions) => {
if (window.navigator.onLine) {
        return await instance_two.post('register_colab', {name, email, entidad, password, autorice, trazabilidad,check_guide_pt, establishment, number_incription,select_mrv, userType, idUser, dataCheck, dataTraza, dataTrazaM, dataNamaG, dataProd, dataVFGC, permissions})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};
export const register_collab_main = async (email, password) => {
if (window.navigator.onLine) {
        return await instance_two.post('register_collab_main', {email, password})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const edit_colab = async (name, email, entidad, password, autorice, trazabilidad, userType, idUser, idUserConection, idUserUser, dataCheck, dataTraza,dataTrazaM, dataProd,dataTrazaG,check_guide_pt, establishment, number_incription, dataVFGC, permissions) => {
if (window.navigator.onLine) {
        return await instance_two.post('edit_colab', {name, email, entidad, password, autorice, trazabilidad, userType, idUser, idUserConection, idUserUser, dataCheck, dataTraza,dataTrazaM, dataProd,dataTrazaG,check_guide_pt, establishment, number_incription, dataVFGC, permissions})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};


export const confirmRegister = async id => {
if (window.navigator.onLine) {
        return await instance.post(`users/confirm/${id}`)
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const forgotPassword = async email => {
if (window.navigator.onLine) {
        return await instance.post('users/forgotpassword', {email})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const confirmReset = async (id, password) => {
if (window.navigator.onLine) {
        return await instance.post(`users/resetpass/${id}`, {password})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const login = async (email, password, type_login) => {

if (window.navigator.onLine) {
        return await instance_two.post('login', {email, password, type_login})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const logout = async token => {
if (window.navigator.onLine) {
        return await instance_two.post('logout', {token})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

export const edit = async (userID, email, password) => {
if (window.navigator.onLine) {
        return await instance.post('edit-user', {userID, email, password})
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
};

/*export const checkSession = async () => (
    await instance.post('users/checkSession')
);*/
/*Nuevas Conexiones*/
 const axios_conexion = async (formData,param) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var user_id = null
    if (jsondata!=null) {
        user_id = jsondata._id
    }
    var getParam = new URLSearchParams(window.location.search).get("company");
    var getDataBase = new URLSearchParams(window.location.search).get("db");
    var getParamSession = localStorage.getItem('colab_id');
    if (jsondata==undefined) {
        var user_db = getDataBase
    }else{
        var user_db = ''
        if (jsondata.userType==2) {
            if (getParamSession==null || getParamSession=='null') {
                user_db = jsondata.database
            }else{
                user_db = 'db_traza_'+getParamSession
            }
        }else{
            user_db = jsondata.database
        }
    }
    var auth = "c42690cc30e581023adf4a4341e5395f";

    if (window.navigator.onLine) {

        var result = await axios({
          method: "post",
          url: config.API_URL+param,
          data: formData,
          headers: { "Content-Type": "multipart/form-data",
                    "Authorization": auth,
                    "header1":getParamSession,
                    "header2":user_id,
                    "header3":user_db },
           onUploadProgress: (progress) => {
            if (param=='/create_grains' || param=='/create_production_nama_g' || param=='/save_document_production' || param=='/upload_irgi') {
                var percent_load = Math.floor((progress.loaded/progress.total)*100)+"%"
                jQuery('.percent_load').html("<div class='text-muted font-italic'>\
                          <small>\
                            <span class='text-blue font-weight-700'>\
                            Subiendo: "+percent_load+"\
                            </span>\
                          </small>\
                        </div>")
             if (progress.loaded==progress.total) {
                var fileSize = progress.total < 1024 ? progress.total+'KB' : (progress.loaded / (1024*1024)).toFixed(2) + 'MB'
                jQuery('.percent_load').html("<div class='text-muted font-italic'>\
                          <small>\
                            <span class='text-green font-weight-700'>\
                                Completado: "+percent_load+" <br> Tamaño de información subido: "+fileSize+"\
                            </span>\
                          </small>\
                        </div>")
             }
            }
           },
        }).catch(err => { 

          if (param!='/get_permission_main') {
            if (err.response.status==500) {
                if (param=='/get_permission') {
                        // caches.keys().then((names) => {
                        //         console.log(names)
                        //     names.forEach((name) => {
                        //         caches.delete(name);
                        //     });
                        // });
                        //alert('Pueda que se haya hecho nuevos cambios en el sistema, por favor vuelva a ingresar.')
                        localStorage.clear();
                        //window.location.reload()
                    return;
                }
                if (typeof err.response.data!='object') {
                     if (err.response.data.indexOf('user_delete') != -1) {
                         alert('Usuario eliminado.')
                          localStorage.removeItem("token");
                          localStorage.removeItem("user");
                          window.location.reload()
                         return;
                     }
                     if (err.response.data.indexOf('no_access') != -1) {
                         alert('Usuario inhabilitado.')
                          localStorage.removeItem("token");
                          localStorage.removeItem("user");
                          window.location.reload()
                         return;
                     }         
                }
            }
            if (err.response.status==403) {
                alert(err.response.data)
                return {data:null,message:err.response.data};
            }

            alert('Ah ocurrido un error en la petición.')
          }
            return {data:null,message:err.response.data};
        });

        return result
    }else{
        window.$(window.document.body).after('<div id="from_connection" style="background-color: #ff0000bf;z-index: 999999;position: fixed;bottom: 0;width: 100%;text-align: center;color: white;font-weight: 800;">Sin conexion a internet, refresque si ya está estable.</div>');
        return {data:null,message:'Sin conexión'}
    }
}
//window.navigator.onLine
export const view_content = async (table) => {
    var result = await axios({
      method: "get",
      url: 'https://foodchaintrazabilidad.com/monitores.php',
    }).catch(err => { 

    });
    console.log(result)
}

export const main_content = async (table) => {
        var formData = new FormData();
        formData.append('table',table);
        var data = await axios_conexion(formData,'/main_content')
        return data.data;
}
export const main_content_filter = (table,dataInfo) => {
        var filter_data = Object.entries(dataInfo).filter((a,b)=> {
        if (a[0]=='content_'+table) {
            return a[1]
        }
    })
        if (filter_data.length!=0) {
            return filter_data[0][1]
        }else{
            return []
        }
}
export const close_session = async (idUser) => {
        var formData = new FormData();
        formData.append('idUser',idUser);
        await axios_conexion(formData,'/close_session')
}

export const api_debug = async (ipfsurlcheck) => {
        try{
                return await axios({
                    method: "get",
                    url: ipfsurlcheck,
                    timeout: 5000
                }).then((responces) => {
                    console.log(responces.status)
                       return responces.status
                    }).catch(err => { 
                      console.log(err.response)
                      return false;
                    });

        }catch (error) {
            return false;
            console.log(error)
        }
}
export const data_traza_verify = async (searchText,trazabilidad,skipoff) => {
    var formData = new FormData();
        formData.append('searchText',searchText);
        formData.append('trazabilidad',trazabilidad);
        formData.append('skipoff',skipoff);
    var data = await axios_conexion(formData,'/data_traza_verify')
    return data.data;
}
export const data_list_invoice = async (idUser) => {
    var formData = new FormData();
        formData.append('idUser',idUser);
        var data = await axios_conexion(formData,'/data_list_invoice')
        return data.data;    
}

export const put_token_firebase = async (idUser,token) => {
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('token',token);
    var data = await axios_conexion(formData,'/put_token_firebase')
    return data.data;
}

export const save_ipfs_debug = async (ipfscode,codigo) => {
    var formData = new FormData();
        formData.append('ipfscode',ipfscode);
        formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/save_ipfs_debug')
    return data.data;
}

export const edit_user = async (user_id,fullData,changeEmail) => {
    var formData = new FormData();
        formData.append('idUser',user_id);
        formData.append('name',fullData.nombre);
        formData.append('dni',fullData.dni);
        formData.append('second_name',fullData.apellido);
        formData.append('phone',fullData.phone);
        formData.append('email',fullData.email);
        formData.append('empresa',fullData.empresa);
        formData.append('pais',fullData.pais);
        formData.append('role',fullData.roleUser);
        formData.append('password',fullData.password);
        formData.append('changeEmail',changeEmail);
        formData.append('georeferencia',fullData.georeferencia);
        formData.append('codeProd',fullData.codeProd);
        
    var data = await axios_conexion(formData,'/edit_user')
    return data;
}
export const edit_user_location = async (user_id,georeferencia) => {
    var formData = new FormData();
        formData.append('idUser',user_id);
        formData.append('georeferencia',georeferencia);
    var data = await axios_conexion(formData,'/edit_user_location')
    return data.data;
}

export const send_email_repeat = async (email,name) => { 
    var formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    var data = await axios_conexion(formData,'/send_email_repeat')
    return data.data
}
export const send_email_contact = async (name,second_name,email,motice) => { 
    var formData = new FormData();
    formData.append("name", name);
    formData.append("second_name", second_name);
    formData.append("email", email);
    formData.append("motice", motice);
    var data = await axios_conexion(formData,'/send_email_contact')
    return data.data
}

export const upload_file_photo = async (file_document,idUser) => { 
    var formData = new FormData();
    formData.append("file_document", file_document);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/upload_file_photo')
    return data.data
}
export const get_users_colab = async (idUser) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/get_users_colab')
    return data.data
}
export const view_data_register = async (correo,idUser) => {
    var formData = new FormData();
    formData.append('correo',correo);
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/view_data_register')
    return data.data
}

export const delete_user_colab = async (idUser,email,idUserColab,motive) => {
    var formData = new FormData();
    formData.append('email',email);
    formData.append('idUser',idUser);
    formData.append('idUserColab',idUserColab);
    formData.append('motive',motive);
    var data = await axios_conexion(formData,'/delete_user_colab')
    return data.data
}
export const get_permission = async (idUser,idCompany) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idCompany',idCompany);
    var data = await axios_conexion(formData,'/get_permission')
    return data.data
}
export const get_permission_main = async (idUser) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/get_permission_main')
    return data.data
}
export const change_notifications = async (idUser) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/change_notifications')
    return data.data
}
export const forgot_password = async (email,type,code) => {
    var formData = new FormData();
        formData.append('email',email);
        formData.append('type',type);
        formData.append('code',code);
    var data = await axios_conexion(formData,'/forgot_password')
    return data;
}
export const change_forgot_password = async (email,code,password) => {
    var formData = new FormData();
        formData.append('email',email);
        formData.append('code',code);
        formData.append('password',password);
    var data = await axios_conexion(formData,'/change_forgot_password')
    return data;
}
export const get_account_wallet = async (idUser)=>{
        var formData = new FormData();
        formData.append('idUser',idUser);
        var data = await axios_conexion(formData,'/get_account_wallet')
        return data.data;
}
export const create_wallet = async (wallet,idUser)=>{
        var formData = new FormData();
        formData.append('wallet',JSON.stringify(wallet));
        formData.append('idUser',idUser);
        var data = await axios_conexion(formData,'/create_wallet')
        return data.data;
}
export const update_wallet = async (wallet,idUser)=>{
        var formData = new FormData();
        formData.append('wallet',JSON.stringify(wallet));
        formData.append('idUser',idUser);
        var data = await axios_conexion(formData,'/update_wallet')
        return data.data;
}
export const get_data_row_main = async (codigoTraza,typeT)=>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('typeT',typeT);
    var data = await axios_conexion(formData,'/get_data_row_main')
    return data;
}
export const get_data_row_plant = async (codigoTraza)=>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/get_data_row_plant')
    return data;
}

export const data_list_processes = async (idUser,idusercompany,type,select_table,type_role) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('type_role',type_role);
    var data = await axios_conexion(formData,'/list_productions')
    return data.data
}
export const list_register_kdes = async (idUser,idusercompany,type,select_table,type_role,type_register,type_status) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('type_role',type_role);
    formData.append('type_register',type_register);
    formData.append('type_status',type_status);
    var data = await axios_conexion(formData,'/list_register_kdes')
    return data.data
}

export const set_data_numb_lot = async (data) => {
    var formData = new FormData();
    formData.append('data',JSON.stringify(data));
    var data = await axios_conexion(formData,'/set_data_numb_lot')
    return data.data
}

export const data_list_addcoll = async (idUser,idUserConection,table) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idUserConection',idUserConection);
    formData.append('table',table);
    var data = await axios_conexion(formData,'/data_list_addcoll')
    return data.data
}


export const data_list_finalized = async (idUser,idusercompany,type,prefix,type_role) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',prefix);
    formData.append('type_role',type_role);
    var data = await axios_conexion(formData,'/data_list_finalized')
    return data.data
}
export const data_trazabilidad = async (idUser,codigoTraza,codigoTrazaTwo,type_form,data_select) => {
    var formData = new FormData();
    formData.append('type_form',type_form);
    formData.append('data_select',data_select);
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/get_taza_vf')
    return data.data
}
export const get_data_row_admin = async (idUser,codigoTraza,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/get_data_row_admin')
    return data.data
}
export const get_data_row_admin_meats = async (idUser,codigoTraza,status,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('status',status);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/get_data_row_admin_meats')
    return data.data
}
export const data_production = async (idUser,codigoTraza,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/getDataProduction')
    return data.data
}

export const data_fertilizer_production = async (idFer,type_form,data_select,codigo,db_code) => {
    var formData = new FormData();
    formData.append('type_form',type_form);
    formData.append('data_select',data_select);
    formData.append('idFer',idFer);
    formData.append('codigoTraza',codigo);
    formData.append('db_code',db_code);
    var data = await axios_conexion(formData,'/getDataFertilizer')
    return data.data
}
export const data_fertilizer_kdes = async (idFer,type_form,data_select,codigo,db_code,type_traza) => {
    var formData = new FormData();
    formData.append('type_form',type_form);
    formData.append('data_select',data_select);
    formData.append('idFer',idFer);
    formData.append('codigoTraza',codigo);
    formData.append('db_code',db_code);
    formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/getDataKdes')
    return data.data
}
export const row_fertilizer_production = async (idUser,codigoTraza,codigoTrazaTwo,type_form) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    formData.append('type_form',type_form);
    var data = await axios_conexion(formData,'/rowDataFertilizer')
    return data.data
}

export const row_data_kdes = async (idUser,codigoTraza,codigoTrazaTwo,type_form) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    formData.append('type_form',type_form);
    var data = await axios_conexion(formData,'/row_data_kdes')
    return data.data
}

export const row_fertilizer_production_general = async (idUser,codigoTraza,codigoTrazaTwo,type_form) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    formData.append('type_form',type_form);
    var data = await axios_conexion(formData,'/rowDataFertilizerGeneral')
    return data.data
}
instance_two_satelite.interceptors.request.use(async (conf) => {
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var user_id = null
    if (jsondata!=null) {
        user_id = jsondata._id
    }
    var getParam = new URLSearchParams(window.location.search).get("company");
    var getDataBase = new URLSearchParams(window.location.search).get("db");
    var getParamSession = localStorage.getItem('colab_id');
    if (jsondata==undefined) {
        var user_db = getDataBase
    }else{
        var user_db = ''
        if (jsondata.userType==2) {
            if (getParamSession==null || getParamSession=='null') {
                user_db = jsondata.database
            }else{
                user_db = 'db_traza_'+getParamSession
            }
        }else{
            user_db = jsondata.database
        }
    }
    var auth = "c42690cc30e581023adf4a4341e5395f";
    conf.headers.Authorization = auth;
    conf.headers.ContentType = 'application/json';
    conf.headers.header1=getParamSession;
    conf.headers.header2=user_id;
    conf.headers.header3=user_db;
    return conf;
});
export const get_data_satelite = async (codigoTraza) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    //var data = await instance_two_satelite.post('/get_data_satelite',formData)
     var data = await axios_conexion(formData,'/get_data_satelite')
     return data.data
}
export const save_data_satelite = async (codigoTraza,code_satelital)=>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('code_satelital',code_satelital);
    var data = await axios_conexion(formData,'/save_data_satelite')
    return data.data
}
export const delete_fertilizer = async (idFer,idUser,full_data_fields,full_data_name,codigo,type_form,name_table) => {
    var formData = new FormData();
    formData.append('idFer',idFer);
    formData.append('name_table',name_table);
    formData.append('full_data_name',JSON.stringify(full_data_name));
    formData.append('full_data_fields',JSON.stringify(full_data_fields));
    formData.append('type_form',type_form);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/fertilizer_delete')
    return data.data
}

export const save_vf = async (idUser,full_data_fields,full_data_name,codigo,type_form,idFv,for_file,prefix) => {
        var formData = new FormData();
        formData.append('idFv',idFv);
        formData.append('full_data_name',JSON.stringify(full_data_name));
        formData.append('full_data_fields',JSON.stringify(full_data_fields));
        formData.append('type_form',type_form);
        formData.append('prefix',prefix);
        // for_file.map((val,ind)=>{
        //     if (typeof Object.values(val)[0]=='object') {
        //         formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
        //     }else{
        //         formData.append('for_file['+Object.keys(val)+']','');
        //     }
        // })

        for_file.map((val,ind)=>{
            if (typeof Object.values(val)[0]=='object') {
                if (Object.values(val)[0].length>0) {
                    Object.values(val)[0].map((a,b)=>{
                        if (a.filed!=null) {
                         var position_table = Object.keys(val)[0]+"_"+b
                         formData.append('for_file['+position_table+']',a.filed);
                        }
                    })
                }else{
                    formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                }
            }else{
                formData.append('for_file['+Object.keys(val)+']','');
            }
        })
        formData.append('idUser',idUser);
        formData.append('codigo',codigo);
     var data = await axios_conexion(formData,'/create_vegetales_frutas')
     return data;
};

export const create_production = async (idUser,full_data_fields,full_data_name,codigo,type_form,idFv,for_file,title_notify) => {
     //    var formData = new FormData();
     //    formData.append('idFv',idFv);
     //    formData.append('full_data_name',JSON.stringify(full_data_name));
     //    formData.append('full_data_fields',JSON.stringify(full_data_fields));
     //    formData.append('type_form',type_form);
     //    for_file.map((val,ind)=>{
     //        if (typeof Object.values(val)[0]=='object') {
     //            if (Object.values(val)[0].length>0) {
     //                Object.values(val)[0].map((a,b)=>{
     //                    if (a.filed!=null) {
     //                     var position_table = Object.keys(val)[0]+"_"+b
     //                     formData.append('for_file['+position_table+']',a.filed);
     //                    }
     //                })
     //            }else{
     //                formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
     //            }
     //        }else{
     //            formData.append('for_file['+Object.keys(val)+']','');
     //        }
     //    })
     //    formData.append('idUser',idUser);
     //    formData.append('codigo',codigo);
     //    formData.append('title_notify',title_notify);
     // var data = await axios_conexion(formData,'/create_production')
     // return data.data;

        var formData = new FormData();
        formData.append('idFv',idFv);
        formData.append('full_data_name',JSON.stringify(full_data_name));
        formData.append('full_data_fields',JSON.stringify(full_data_fields));
        formData.append('type_form',type_form);
        for_file.map((val,ind)=>{
            if (typeof Object.values(val)[0]=='object') {
                if (Object.values(val)[0]!=null) {
                    if (Object.values(val)[0].length>0) {
                        Object.values(val)[0].map((a,b)=>{
                            if (a.filed!=null) {
                             var position_table = Object.keys(val)[0]+"_"+b
                             formData.append('for_file['+position_table+']',a.filed);
                            }
                        })
                    }else{
                        formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                    }
                }
            }else{
                formData.append('for_file['+Object.keys(val)+']','');
            }
        })        
        formData.append('idUser',idUser);
        formData.append('codigo',codigo);
        formData.append('title_notify',title_notify);
     var data = await axios_conexion(formData,'/create_production')
     return data.data;
};

export const create_production_kdes = async (idUser,full_data_fields,full_data_name,codigo,type_form,idFv,for_file,title_notify,typeUserRole,prefix) => {
        var formData = new FormData();
        formData.append('idFv',idFv);
        formData.append('full_data_name',JSON.stringify(full_data_name));
        formData.append('full_data_fields',JSON.stringify(full_data_fields));
        formData.append('type_form',type_form);
        for_file.map((val,ind)=>{
            if (typeof Object.values(val)[0]=='object') {
                if (Object.values(val)[0].length>0) {
                    Object.values(val)[0].map((a,b)=>{
                        if (a.filed!=null) {
                         var position_table = Object.keys(val)[0]+"_"+b
                         formData.append('for_file['+position_table+']',a.filed);
                        }
                    })
                }else{
                    formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                }
            }else{
                formData.append('for_file['+Object.keys(val)+']','');
            }
        })
        formData.append('idUser',idUser);
        formData.append('typeUserRole',typeUserRole);
        formData.append('codigo',codigo);
        formData.append('title_notify',title_notify);
        formData.append('prefix',prefix);
     var data = await axios_conexion(formData,'/create_production_kdes')
     return data.data;
};

export const fertilizer_production = async (fertilizante_agroquimico,nombre_comercial,marca,nombre_cientifico,fecha_vencimiento,fecha_aplicacion,lote_numero,cantidad_aplicada,aplicacion_numero,georeferencia,informacion_adicional,user_id,file_document,fertilizer_id,codigo) =>{
        var formData = new FormData();
        formData.append('fertilizante_agroquimico',fertilizante_agroquimico);
        formData.append('nombre_comercial',nombre_comercial);
        formData.append('marca',marca);
        formData.append('nombre_cientifico',nombre_cientifico);
        formData.append('fecha_vencimiento',fecha_vencimiento);
        formData.append('fecha_aplicacion',fecha_aplicacion);
        formData.append('lote_numero',lote_numero);
        formData.append('cantidad_aplicada',cantidad_aplicada);
        formData.append('aplicacion_numero',aplicacion_numero);
        formData.append('georeferencia',georeferencia);
        formData.append('informacion_adicional',informacion_adicional);
        formData.append('idUser',user_id);
        formData.append('file_document',file_document);
        formData.append('fertilizer_id',fertilizer_id);
        formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/fertilizer_production')
    return data;
}
//Cosecha
export const row_transport_cosecha = async (idUser,codigoTraza,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_transport_cosecha')
    return data.data
}
export const data_cosecha = async (idUser,codigoTraza,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/get_data_cosecha')
    return data.data
}
export const data_transport_vehicule_cosecha = async (idTrans) => {
    var formData = new FormData();
    formData.append('idTrans',idTrans);
    var data = await axios_conexion(formData,'/get_data_transport_vehicule_cosecha')
    return data.data
}
export const create_cosecha = async (fullData,user_id,codigo) => {
    var formData = new FormData();
        formData.append('idUser',user_id);
        formData.append('cosecha_id',fullData.cosecha_id);
        formData.append('nombre_finca',fullData.nombre_finca);
        formData.append('lote_numero',fullData.lote_numero);
        formData.append('producto',fullData.producto);
        formData.append('variedad',fullData.variedad);
        formData.append('fecha_cosecha',fullData.fecha_cosecha);
        formData.append('hora_cosecha',fullData.hora_cosecha);
        formData.append('hora_final',fullData.hora_final);
        formData.append('georeferencia',fullData.georeferencia);
        formData.append('peso_unidad',fullData.peso_unidad);
        formData.append('empaque',fullData.empaque);
        formData.append('peso_por_unidad',fullData.peso_por_unidad);
        formData.append('peso_total',fullData.peso_total);
        formData.append('total_unidades',fullData.total_unidades);
        formData.append('informacion_adicional',fullData.informacion_adicional);
        formData.append('file_document',fullData.file_document);
        formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/create_cosecha')
    return data;
}
export const create_trasport = async (fullData,user_id,codigo) => {
    var formData = new FormData();
        formData.append('idUser',user_id);
        formData.append('transport_id',fullData.transport_id);
        formData.append('fecha_salida',fullData.fecha_salida);
        formData.append('hora_salida',fullData.hora_salida);
        formData.append('nombre_finca',fullData.nombre_finca);
        formData.append('numero_guia',fullData.numero_guia);
        formData.append('lote_numero',fullData.lote_numero);
        formData.append('nombre_producto',fullData.nombre_producto);
        formData.append('variedad_producto',fullData.variedad_producto);
        formData.append('tipo_empaque',fullData.tipo_empaque);
        formData.append('total_unidades',fullData.total_unidades);
        formData.append('peso_unidad',fullData.peso_unidad);
        formData.append('peso_total',fullData.peso_total);
        formData.append('informacion_adicional',fullData.informacion_adicional_transport);
        formData.append('file_document',fullData.file_document_trasport);
        formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/create_trasport')
    return data;
}
export const create_vehicule = async (fullData,user_id,transport_id,codigo) => {
    var formData = new FormData();
        formData.append('idUser',user_id);
        formData.append('vehicule_id',fullData.vehicule_id);
        formData.append('transport_id',transport_id);
        formData.append('tipo_vehiculo',fullData.tipo_vehiculo);
        formData.append('capacidad',fullData.capacidad);
        formData.append('placa',fullData.placa);
        formData.append('nombre_chofer',fullData.nombre_chofer);
        formData.append('vehiculo_desinfectado',fullData.vehiculo_desinfectado);
        formData.append('informacion_adicional',fullData.informacion_adicional_vehicule);
        formData.append('file_document',fullData.file_document_vehicule);
        formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/create_vehicule')
    return data;
}
export const delete_trans_vehicule = async (id,idUser,codigo,vehicule_id) =>{
    var formData = new FormData();
    formData.append('idTrans',id);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('vehicule_id',vehicule_id);
    var data = await axios_conexion(formData,'/transport_delete')
    return data.data
}
//planta
export const row_data_plant = async (idUser,codigoTraza,codigoTrazaTwo) =>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    
    var data = await axios_conexion(formData,'/row_data_plant')
    return data.data
}
export const all_data_plant = async (idPlant) =>{
    var formData = new FormData();
    formData.append('idPlant',idPlant);
    var data = await axios_conexion(formData,'/get_data_plant')
    return data.data
}
export const create_plant_product = async (fullData,idUser,codigo) =>{
    var formData = new FormData();
        formData.append('idUser',idUser);

        formData.append('plant_product_id',fullData.plant_product_id);
        formData.append('fecha',fullData.fecha);
        formData.append('hora',fullData.hora);
        formData.append('guia_numero',fullData.guia_numero);
        formData.append('lote_numero',fullData.lote_numero);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_plant_product);
        formData.append('file_document',fullData.file_document_plant_product);
    var data = await axios_conexion(formData,'/create_plant_product')
    return data;
}
export const create_plant_sample = async (fullData,idUser,idPlant,codigo) =>{
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('planta_id',idPlant);

        formData.append('plant_sample_id',fullData.plant_sample_id);
        formData.append('cantidad_muestreada',fullData.cantidad_muestreada);
        formData.append('porcentaje_muestras',fullData.porcentaje_muestras);
        formData.append('seleccion_utilizada',fullData.seleccion_utilizada);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_plant_sample);
        formData.append('file_document',fullData.file_document_plant_sample);
    var data = await axios_conexion(formData,'/create_plant_sample')
    create_plant_weight(fullData,idUser,idPlant,codigo)
    create_plant_treatment(fullData,idUser,idPlant,codigo)
    return data;
}
export const create_plant_weight = async (fullData,idUser,idPlant,codigo) =>{
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('planta_id',idPlant);

        formData.append('plant_weight_id',fullData.plant_weight_id);
        formData.append('peso_unidad',fullData.peso_unidad);
        formData.append('tipo_empaque',fullData.tipo_empaque);
        formData.append('total_unidades',fullData.total_unidades);
        formData.append('peso_por_unidad',fullData.peso_por_unidad);
        formData.append('peso_total',fullData.peso_total);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_plant_weight);
        formData.append('file_document',fullData.file_document_plant_weight);
    var data = await axios_conexion(formData,'/create_plant_weight')
    return data;

}
export const create_plant_treatment = async (fullData,idUser,idPlant,codigo) =>{
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('planta_id',idPlant);

        formData.append('plant_treatment_id',fullData.plant_treatment_id);
        formData.append('fecha_inicio',fullData.fecha_inicio);
        formData.append('hora_inicial',fullData.hora_inicial);
        formData.append('fecha_final',fullData.fecha_final);
        formData.append('hora_final',fullData.hora_final);
        formData.append('tipo_tratamiento',fullData.tipo_tratamiento);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_plant_treatment);
        formData.append('file_document',fullData.file_document_plant_treatment);
    var data = await axios_conexion(formData,'/create_plant_treatment')
    return data;


}
export const get_data_transport = async (id) =>{
    var formData = new FormData();
    formData.append('idTransVehi',id);
    var data = await axios_conexion(formData,'/get_data_transport_plant')
    return data.data
}
export const row_transport_plant = async (idFer,type_form,data_select,codigo) =>{

    var formData = new FormData();
    formData.append('type_form',type_form);
    formData.append('data_select',data_select);
    formData.append('idFer',idFer);
    formData.append('codigoTraza',codigo);
    var data = await axios_conexion(formData,'/row_transport_plant')
    return data.data
    // var formData = new FormData();
    // formData.append('idUser',idUser);
    // formData.append('idPlant',idPlant);
    // formData.append('codigo',codigo);
    // formData.append('codigoTrazaTwo',codigoTrazaTwo);
    // var data = await axios_conexion(formData,'/row_transport_plant')
    // return data.data
}
export const row_transport_plant_view = async (idPlant,idUser,codigoTraza,codigoTrazaTwo) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idPlant',idPlant);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_transport_plant_view')
    return data.data
}
export const get_data_transport_trace = async (idPlant) =>{
    var formData = new FormData();
    formData.append('idPlant',idPlant);
    var data = await axios_conexion(formData,'/get_data_transport_trace')
    return data.data
}
export const add_transport_plant = async(dataGet,idPlant,idUser,codigo)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idPlant',idPlant);
    formData.append('dataGet',JSON.stringify(dataGet));
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/add_transport_plant')
    return data
}
export const delete_all_plant = async (idPlant,idUser,codigo,plant_sample_id,plant_weight_id,plant_treatment_id) =>{
    var formData = new FormData();
    formData.append('idPlant',idPlant);
    formData.append('plant_sample_id',plant_sample_id);
    formData.append('plant_weight_id',plant_weight_id);
    formData.append('plant_treatment_id',plant_treatment_id);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);    
    var data = await axios_conexion(formData,'/delete_all_plant')
    return data.data
}

export const plant_data_information = async(idUser,codigoTraza,codigoTrazaTwo)=>{
var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/plant_data_information')
    return data.data
}

export const create_plant_information = async(fullData,idUser,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);

        formData.append('plant_information_id',fullData.plant_information_id);
        formData.append('lote_numero',fullData.lote_numero);
        formData.append('fecha_empaque',fullData.fecha_empaque);
        formData.append('hora_inicial',fullData.hora_inicial);
        formData.append('hora_final',fullData.hora_final);
        formData.append('tratamiento_aplicado',fullData.tratamiento_aplicado);
        formData.append('tipo_aplicacion',fullData.tipo_aplicacion);
        formData.append('cantidad_aplicada',fullData.cantidad_aplicada);
        formData.append('concentracion',fullData.concentracion);
        formData.append('empaque_utilizado',fullData.empaque_utilizado);
        formData.append('total_unidades_empacadas',fullData.total_unidades_empacadas);
        formData.append('unidades_por_pallet',fullData.unidades_por_pallet);
        formData.append('total_pallets',fullData.total_pallets);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional);
        formData.append('file_document',fullData.file_document);
    var data = await axios_conexion(formData,'/create_plant_information')
    return data;
}

export const data_cold_pre_cold = async(idUser,codigoTraza,codigoTrazaTwo)=>{
var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/data_cold_pre_cold')
    return data.data
}
export const create_plant_pre_cold = async(fullData,idUser,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);

        formData.append('plant_pre_cold_id',fullData.plant_pre_cold_id);
        formData.append('lote_numero',fullData.lote_numero);
        formData.append('fecha_inicio',fullData.fecha_inicio);
        formData.append('hora_inicio',fullData.hora_inicio);
        formData.append('temperatura_inicio',fullData.temperatura_inicio);
        formData.append('temperatura_final',fullData.temperatura_final_pre_cold);

        formData.append('unidad_refrigerar',fullData.unidad_refrigerar_pre_cold);
        formData.append('total_unidades_refrigerar',fullData.total_unidades_refrigerar_pre_cold);


        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_pre_cold);
        formData.append('file_document',fullData.file_document_pre_cold);
    var data = await axios_conexion(formData,'/create_plant_pre_cold')
    return data;
}
export const create_plant_cold = async(fullData,idUser,plant_pre_cold_id,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('plant_pre_cold_id',plant_pre_cold_id);

        formData.append('plant_cold_id',fullData.plant_cold_id);
        formData.append('lote_numero',fullData.lote_numero_cold);
        formData.append('fecha_inicio',fullData.fecha_inicio_cold);
        formData.append('hora_inicio',fullData.hora_inicio_cold);
        formData.append('temperatura_inicio',fullData.temperatura_inicio_cold);
        formData.append('temperatura_final',fullData.temperatura_final_cold);
        formData.append('unidad_refrigerar',fullData.unidad_refrigerar);
        formData.append('total_unidades_refrigerar',fullData.total_unidades_refrigerar);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_cold);
        formData.append('file_document',fullData.file_document_cold);
    var data = await axios_conexion(formData,'/create_plant_cold')
    return data;
}

//Logistica y Transporte

export const data_transport_shipment = async(idUser,codigoTraza,codigoTrazaTwo)=>{
var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/data_transport_shipment')
    return data.data
}
export const create_transport_shipment = async(fullData,idUser,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);

        formData.append('transport_shipment_id',fullData.transport_shipment_id);
        formData.append('modo_transporte',fullData.modo_transporte);
        formData.append('fecha_carga',fullData.fecha_carga);
        formData.append('contenedor_numero',fullData.contenedor_numero);
        formData.append('sello_numero',fullData.sello_numero);
        formData.append('unidad_carga',fullData.unidad_carga);
        formData.append('total_unidades',fullData.total_unidades);
        
        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_shipment);
        formData.append('file_document',fullData.file_document_shipment);
    var data = await axios_conexion(formData,'/create_transport_shipment')
    return data;
}
export const create_transport_transport = async(fullData,idUser,transport_shipment_id,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('transport_shipment_id',transport_shipment_id);

        formData.append('transport_transport_id',fullData.transport_transport_id);
        formData.append('fecha_embarque',fullData.fecha_embarque);
        formData.append('envio_desde',fullData.envio_desde);
        formData.append('guia_numero',fullData.guia_numero);
        formData.append('georeferencia',fullData.georeferencia);
        formData.append('puerto_embarque',fullData.puerto_embarque);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_transport);
        formData.append('file_document',fullData.file_document_transport);
    var data = await axios_conexion(formData,'/create_transport_transport')
    return data;
}

//Comercializacion

export const data_export_market = async(idUser,codigoTraza,codigoTrazaTwo)=>{
var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('idUser',idUser);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/data_export_market')
    return data.data
}
export const create_commercialization_market = async(fullData,idUser,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);

        formData.append('market_id',fullData.market_id);
        formData.append('comprador_nombre',fullData.comprador_nombre);
        formData.append('empresa',fullData.empresa);
        formData.append('ciudad',fullData.ciudad);
        formData.append('provincia',fullData.provincia);
        formData.append('persona_contacto',fullData.persona_contacto);
        formData.append('telefono_contacto',fullData.telefono_contacto);
        formData.append('email',fullData.email);
        formData.append('numero_factura',fullData.numero_factura);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_market);
        formData.append('file_document',fullData.file_document_market);
    var data = await axios_conexion(formData,'/create_commercialization_market')
    return data;
}
export const create_commercialization_export = async(fullData,idUser,market_id,codigo)=>{
var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('market_id',market_id);

        formData.append('export_id',fullData.export_id);
        formData.append('comprador_nombre_export',fullData.comprador_nombre_export);
        formData.append('contacto_export',fullData.contacto_export);
        formData.append('telefono_contacto_export',fullData.telefono_contacto_export);
        formData.append('email_export',fullData.email_export);
        formData.append('numero_factura_export',fullData.numero_factura_export);

        formData.append('codigo',codigo);
        formData.append('informacion_adicional',fullData.informacion_adicional_export);
        formData.append('file_document',fullData.file_document_export);
    var data = await axios_conexion(formData,'/create_commercialization_export')
    return data;
}
export const generate_trazabilidad = async(idUser,idTraza,codigoTraza,typeTraza)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idTraza',idTraza);
    formData.append('codigoTraza',codigoTraza);
    formData.append('typeTraza',typeTraza);
    var data = await axios_conexion(formData,'/generate_trazabilidad')
    return data;
}
export const generate_trazabilidad_kdes = async(idUser,idTraza,codigoTraza,typeTraza)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idTraza',idTraza);
    formData.append('codigoTraza',codigoTraza);
    formData.append('typeTraza',typeTraza);
    var data = await axios_conexion(formData,'/generate_trazabilidad_kdes')
    return data;
}
export const generate_trazabilidad_meat = async(idUser,idTraza,codigo)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idTraza',idTraza);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/generate_trazabilidad_meat')
    return data;
}
export const row_documents_list = async (idUser,codigoTraza) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/row_documents_list')
    return data.data;   
}
export const row_documents_list_grains = async (idUser,codigoTraza,table) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('table',table);
    var data = await axios_conexion(formData,'/row_documents_list_grains')
    return data.data;   
}

export const row_documents_list_kdes = async (idUser,codigoTraza,table) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('table',table);
    var data = await axios_conexion(formData,'/row_documents_list_kdes')
    return data.data;   
}

export const row_documents_meats_list = async (idUser,codigoTraza) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigoTraza);
    var data = await axios_conexion(formData,'/row_documents_meats_list')
    return data.data;   
}
export const printPdf= async (url) =>{
    var formData = new FormData();
    formData.append('url',url);
    var data = await axios_conexion(formData,'/print_pdf_base')
    return data.data;
}
export const saveInfoBlockchain = async (blockHash,from,transactionHash,iduser,ipfsdata,trazabilidad_code,type_w) =>{
    var formData = new FormData();
    formData.append('blockHash',blockHash);
    formData.append('from',from);
    formData.append('transactionHash',transactionHash);
    formData.append('idUser',iduser);
    formData.append('ipfsdata',ipfsdata);
    formData.append('trazabilidad_code',trazabilidad_code);
    formData.append('type_w',type_w);
    var data = await axios_conexion(formData,'/saveInfoBlockchain')
    return data.data;
}

export const saveInfoBlockchainMeats = async (blockHash,from,transactionHash,iduser,ipfsdata,trazabilidad_code) =>{
    var formData = new FormData();
    formData.append('blockHash',blockHash);
    formData.append('from',from);
    formData.append('transactionHash',transactionHash);
    formData.append('idUser',iduser);
    formData.append('ipfsdata',ipfsdata);
    formData.append('trazabilidad_code',trazabilidad_code);
    var data = await axios_conexion(formData,'/saveInfoBlockchainMeats')
    return data.data;
}

export const data_list_blockchain = async (userId,idusercompany,type) =>{
    var formData = new FormData();
    formData.append('idUser',userId);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    var data = await axios_conexion(formData,'/data_list_blockchain')
    return data.data;
}
/*export const get_traza = async (idUser) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/get_traza')
    return data;
}*/

//INICIO CARNICOS
export const list_productions_meats = async (idUser) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/list_productions_meats')
    return data.data
}
export const data_trazabilidad_meat = async (idUser,codigoTraza,codigoTrazaTwo)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/get_taza_meat')
    return data.data
}
export const save_meat = async (fullData,idUser) => {
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append("idMeat",fullData.meat_id);
        formData.append('fecha',fullData.fecha);
        formData.append('nombre_producto',fullData.nombre_producto);
        formData.append('nombre_trazabilidad',fullData.nombre_trazabilidad);
        formData.append('nombre_finca',fullData.nombre_finca);
        formData.append('tamano_finca',fullData.tamano_finca);
        formData.append('unidadsup',fullData.unidadsup);
        formData.append('area',fullData.area);
        formData.append('georeferencia',fullData.georeferencia);
        formData.append('pais_origen',fullData.pais_origen);
        formData.append('ciudad',fullData.ciudad);
        formData.append('provincia',fullData.provincia);
        formData.append('especie',fullData.especie);
        formData.append('category',fullData.category);
        formData.append('otra_especie',fullData.otra_especie);
        formData.append('raza',fullData.raza);
        formData.append('proposito',fullData.proposito);
        formData.append('otro_uso',fullData.otro_uso);
        formData.append('codigo_arancelario', (fullData.codigo_arancelario.Codigo==undefined)?(fullData.codigo_arancelario):(fullData.codigo_arancelario.Codigo));
        formData.append('informacion',fullData.informacion_adicional);
        formData.append("file", fullData.file_document);
    var data = await axios_conexion(formData,'/create_meat')
    return data;
}

export const row_traza_animal = async (idUser,codigoTraza) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/row_traza_animal')
    return data.data
}
export const row_traza_animal_final = async (idUser,codigoTraza) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/row_traza_animal_final')
    return data.data
}
export const row_traza_animal_delete = async (idUser,codigoTraza) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/row_traza_animal_delete')
    return data.data
}
export const create_traza_animal = async (fullData,idUser,codigo) => {
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append("animal_id_register",fullData.animal_id_register);
        formData.append('animal_id',fullData.animal_id);
        formData.append('fecha_registro',fullData.fecha_registro);
        formData.append('hora_registro',fullData.hora_registro);
        formData.append('madre_id',fullData.madre_id);
        formData.append('animal_nro',fullData.animal_nro);
        formData.append('foodchain_id',fullData.foodchain_id);
        formData.append('fecha_nacimiento',fullData.fecha_nacimiento);
        formData.append('edad',fullData.edad);
        formData.append('unidadedad',fullData.unidadedad);
        formData.append('unidad_peso',fullData.unidad_peso);
        formData.append('raza',fullData.raza);
        formData.append('category',fullData.category);
        formData.append('sexo',fullData.sexo);
        formData.append('peso_inicial',fullData.peso_inicial);
        formData.append('informacion',fullData.informacion_adicional);
        formData.append("file_document", fullData.file_document);
        formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/create_traza_animal')
    return data;
}
export const create_traza_animal_edit = async (fullData,idUser,codigo) => {
    var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append("animal_id_register",fullData.animal_id);
        formData.append('fecha_registro',fullData.fecha_registro);
        formData.append('hora_registro',fullData.hora_registro);
        formData.append('madre_id',fullData.madre_id);
        formData.append('fecha_nacimiento',fullData.fecha_nacimiento);
        formData.append('edad',fullData.edad);
        formData.append('raza',fullData.raza);
        formData.append('sexo',fullData.sexo);
        formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/create_traza_animal_edit')
    return data;
}
export const delete_traza_animal = async (animal_id_register,fecha_muerte,motivo,lugar_siniestro,cantidad_animales,idUser,codigo) => {
    var formData = new FormData();
    formData.append('animal_id_register',animal_id_register);
    formData.append('fecha_muerte',fecha_muerte);
    formData.append('motivo',motivo);
    formData.append('lugar_siniestro',lugar_siniestro);
    formData.append('cantidad_animales',cantidad_animales);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);

    var data = await axios_conexion(formData,'/delete_traza_animal')
    return data.data
}
export const delete_register_traza_animal = async (animal_id_register,idUser,codigo) => {
    var formData = new FormData();
    formData.append('animal_id_register',animal_id_register);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/delete_register_traza_animal')
    return data.data
}
export const data_traza_animal = async (idAnimal) => {
    var formData = new FormData();
    formData.append('idAnimal',idAnimal);
    var data = await axios_conexion(formData,'/data_traza_animal')
    return data.data
}
export const register_lote = async (data,idUser,codigo,lote_numero,type_code,lote_alias) =>{
    var formData = new FormData();
    formData.append('dataGet',JSON.stringify(data));
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('lote_numero',lote_numero);
    formData.append('type_code',type_code);
    formData.append('lote_alias',lote_alias);
    var data = await axios_conexion(formData,'/register_lote')
    return data.data
}
export const data_details_animal = async (codigo,idAnimal,lote_numero) =>{
    var formData = new FormData();
    formData.append('codigo',codigo);
    formData.append('idAnimal',idAnimal);
    formData.append('lote_numero',lote_numero);
    var data = await axios_conexion(formData,'/data_details_animal')
    return data.data
}
//Lote
export const row_traza_lote = async (idUser,codigo,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_traza_lote')
    return data.data
}
export const row_traza_lote_select = async (idUser,codigo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/row_traza_lote_select')
    return data.data
}
export const row_traza_lote_job = async (idUser,codigo,lote_numero,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('lote_numero',lote_numero);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);

    var data = await axios_conexion(formData,'/row_traza_lote_job')
    return data.data
}
export const save_info_peso = async (idAnimal,valorPeso,codigo,idUser) => {
    var formData = new FormData();
    formData.append('idAnimal',idAnimal);
    formData.append('valorPeso',valorPeso);
    formData.append('codigo',codigo);
    formData.append('idUser',idUser);

    var data = await axios_conexion(formData,'/save_info_peso')
    return data.data
}
//Logistica y trasporte
export const row_transport_logistics = async (idUser,codigo,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_transport_logistics')
    return data.data
}
export const row_transport_logistics_for_valid = async (idUser,codigo,ruta) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    formData.append('ruta',ruta);
    var data = await axios_conexion(formData,'/row_transport_logistics_for_valid')
    return data.data
}

export const logistic_create_trasport = async (fullData,idUser,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);
                
                formData.append('transport_id', fullData.transport_id);
                formData.append('seleccionar_ruta', fullData.seleccionar_ruta);
                formData.append('otra_ruta', fullData.otra_ruta);
                formData.append('fecha_salida', fullData.fecha_salida);
                formData.append('hora_salida', fullData.hora_salida);
                formData.append('guia_numero', fullData.guia_numero);
                formData.append('precinto_numero', fullData.precinto_numero);
                formData.append('nombre_chofer', fullData.nombre_chofer);
                formData.append('id_chofer', fullData.id_chofer);
                formData.append('cedula', fullData.cedula);
                formData.append('tipo_camion', fullData.tipo_camion);
                formData.append('placa_camion', fullData.placa_camion);
                formData.append('vehiculo_desinfectado', fullData.vehiculo_desinfectado);
                formData.append('producto_utilizado', fullData.producto_utilizado);
                formData.append('informacion_adicional', fullData.informacion_adicional_transport);
                formData.append('file_document', fullData.file_document_trasport);
            var data = await axios_conexion(formData,'/logistic_create_trasport')
            return data.data
}
export const logistic_create_export = async (fullData,idUser,codigo) => {
                var formData = new FormData();
                //formData.append('transport_id',idTrans);
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);

                formData.append('export_id', fullData.export_id);
                formData.append('modo_transporte', fullData.modo_transporte);
                formData.append('fecha_carga', fullData.fecha_carga);
                formData.append('nro_documento', fullData.nro_documento);
                formData.append('contenedor_numero', fullData.contenedor_numero);
                formData.append('sello_numero', fullData.sello_numero);
                formData.append('unidad_carga', fullData.unidad_carga);
                formData.append('total_unidades', fullData.total_unidades);
                formData.append('informacion_adicional', fullData.informacion_adicional_export);
                formData.append('file_document', fullData.file_document_export);

            var data = await axios_conexion(formData,'/logistic_create_export')
            return data.data
}
export const data_logstic_transport_export = async (allData,idTrans) => {
    const dataTransport = allData.dataLogisticsTransport.filter(val => val.id==idTrans)
    const dataExport = allData.dataLogisticsTransportExport.filter(val => val.transport_id==idTrans)
    const data = {transport:dataTransport[0], export:dataExport[0]}
    return data
}
export const data_logstic_transport = async (allData,idTrans) => {
    const dataTransport = allData.dataLogisticsTransport.filter(val => val.id==idTrans)
    const data = {transport:dataTransport[0]}
    return data
}
export const data_logstic_export = async (allData,idTrans) => {

    const dataExport = allData.dataLogisticsTransportExport.filter(val => val.id==idTrans)
    const data = {export:dataExport[0]}
    return data
}
export const data_logist_traza_animal = async (allData,idAnimal) => {
    const dataAnimal = allData.filter(val => val.id==idAnimal)
    return dataAnimal
}
export const deleteTransExport = async (idTrans,idUser,codigo) => {
    var formData = new FormData();
    formData.append('idTrans',idTrans);
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/deleteTransExport')
    return data.data
}
export const deleteTransFrom = async (idTrans,idUser,codigo) => {
    var formData = new FormData();
    formData.append('idTrans',idTrans);
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/deleteTransFrom')
    return data.data
}
//Compartimento
export const row_compartiment = async (idUser,codigo,lote_numero,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    formData.append("lote_numero", lote_numero);
    formData.append("codigoTrazaTwo", codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_compartiment')
    return data.data
}
export const set_data_compartiment = async (allData,idCompart) => { 
    const dataCompartiment = allData.filter(val => val.id==idCompart)
    //const data = dataCompartiment[0]
    return dataCompartiment[0]
}
export const set_data_compartiment_exit = async (allData,idCompart) => { 
    const dataCompartiment = allData.filter(val => val.id==idCompart)
    //const data = dataCompartiment[0]
    return dataCompartiment[0]
}
export const create_compartiment= async (fullData,idUser,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);

                formData.append('compartiment_id', fullData.compartiment_id);
                formData.append('nombre_compartimento_recive', fullData.nombre_compartimento_recive);
                formData.append('nombre_productor', fullData.nombre_productor);
                formData.append('nombre_finca', fullData.nombre_finca);
                formData.append('georeferencia', fullData.georeferencia);
                formData.append('ciudad', fullData.ciudad);
                formData.append('estado', fullData.estado);
                formData.append('lote_numero', fullData.lote_numero);
                formData.append('guia_numero', fullData.guia_numero);
                formData.append('precinto_numero', fullData.precinto_numero);
                formData.append('fecha_recibido', fullData.fecha_recibido);
                formData.append('hora_recibido', fullData.hora_recibido);
                formData.append('cantidad_animales', fullData.cantidad_animales);
                formData.append('recibido_por', fullData.recibido_por);
                formData.append('nro_identificacion', fullData.nro_identificacion);
                formData.append('informacion_adicional', fullData.informacion_adicional);
                formData.append('file_document', fullData.file_document);

            var data = await axios_conexion(formData,'/create_compartiment')
            return data.data 
}

export const create_compartiment_exit= async (fullData,idUser,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);

                formData.append('compartiment_exit_id', fullData.compartiment_exit_id);
                formData.append('nombre_finca', fullData.nombre_finca);
                formData.append('nombre_compartimento', fullData.nombre_compartimento);
                formData.append('lote_numero', fullData.lote_numero);
                formData.append('guia_numero', fullData.guia_numero);
                formData.append('precinto_numero', fullData.precinto_numero);
                formData.append('fecha_salida', fullData.fecha_salida);
                formData.append('hora_salida', fullData.hora_salida);
                formData.append('enviado_por', fullData.enviado_por);
                formData.append('nro_identificacion', fullData.nro_identificacion);
                formData.append('cantidad_animales', fullData.cantidad_animales);
                formData.append('informacion_adicional', fullData.informacion_adicional);
                formData.append('file_document', fullData.file_document);

            var data = await axios_conexion(formData,'/create_compartiment_exit')
            return data.data 
}

export const delete_compartiment= async (idCompart,idUser,codigo) => { 
    var formData = new FormData();
    formData.append('idCompart',idCompart);
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/delete_compart')
    return data.data
}
export const delete_compartiment_exit= async (idCompart,idUser,codigo) => { 
    var formData = new FormData();
    formData.append('idCompart',idCompart);
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/delete_compartiment_exit')
    return data.data
}

//Fridge

export const row_fridge = async (idUser,codigo,lote_numero,codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append("codigo", codigo);
    formData.append("lote_numero", lote_numero);
    formData.append("codigoTrazaTwo", codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_fridge')
    return data.data
}
export const data_fridge_task_received = async (idFridge) => {
    var formData = new FormData();
    formData.append('idFridge',idFridge);
    var data = await axios_conexion(formData, '/data_fridge_task_received')
    return data.data
}
export const set_data_fridge_task = async (idFridgeTask) => {
    var formData = new FormData();
    formData.append('idFridgeTask',idFridgeTask);
    var data = await axios_conexion(formData, '/set_data_fridge_task')
    return data.data
}
export const data_logic_fridge_task_received = async (allData,idFridge) => {
    const fridge = allData.fridge.filter(val => val.id==idFridge)
    const received = allData.received.filter(val => val.fridge_id==idFridge)
    const task = allData.task.filter(val => val.fridge_id==idFridge)
    const data = {fridge:fridge[0],received:received[0],task:task[0]}
    return data
}
export const fridge_create_fridge= async (fullData,idUser,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);

                formData.append('fridge_id', fullData.fridge_id);
                formData.append('registro_numero', fullData.registro_numero);
                formData.append('nombre_frigorifico', fullData.nombre_frigorifico);
                formData.append('pais', fullData.pais);
                formData.append('ciudad', fullData.ciudad);
                formData.append('estado', fullData.estado);
                formData.append('georeferencia', fullData.georeferencia);
                formData.append('informacion_adicional', fullData.informacion_adicional_fridge);
                formData.append('file_document', fullData.file_document_fridge);

            var data = await axios_conexion(formData,'/fridge_create_fridge')
            return data.data 
}
export const fridge_create_received= async (fullData,idUser,idFridge,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);
                formData.append("idFridge", idFridge);

                formData.append('fridge_received_id', fullData.fridge_received_id);
                formData.append('nombre_finca', fullData.nombre_finca);
                formData.append('lote_numero', fullData.lote_numero_received);
                formData.append('guia_numero', fullData.guia_numero);
                formData.append('precinto_numero', fullData.precinto_numero);
                formData.append('cert_inspeccion_nro', fullData.cert_inspeccion_nro);
                formData.append('fecha_recibido', fullData.fecha_recibido);
                formData.append('hora_recibido', fullData.hora_recibido);
                formData.append('cantidad_animales', fullData.cantidad_animales_received);
                formData.append('peso_total', fullData.peso_total);
                formData.append('peso_unidad', fullData.peso_unidad);
                formData.append('lavado_desinfeccion', fullData.lavado_desinfeccion);
                formData.append('tratamiento_aplicado', fullData.tratamiento_aplicado_received);
                formData.append('informacion_adicional', fullData.informacion_adicional_received);
                formData.append('file_document', fullData.file_document_received);

            var data = await axios_conexion(formData,'/fridge_create_received')
            return data.data 
}
export const fridge_create_task= async (fullData,idUser,codigo) => {
                var formData = new FormData();
                formData.append('idUser',idUser);
                formData.append("codigo", codigo);

                formData.append('fridge_task_id', fullData.fridge_task_id);
                formData.append('lote_numero', fullData.lote_numero_task);
                formData.append('fecha_faena', fullData.fecha_faena);
                formData.append('hora_faena', fullData.hora_faena);
                formData.append('hora_final', fullData.hora_final);
                formData.append('peso_pie', fullData.peso_pie);
                formData.append('peso_unidad_pie', fullData.peso_unidad_pie);
                formData.append('peso_canales', fullData.peso_canales);
                formData.append('peso_unidad_canales', fullData.peso_unidad_canales);                                                                
                formData.append('cantidad_animales', fullData.cantidad_animales_task);
                formData.append('lavado_faena', fullData.lavado_faena);
                formData.append('tratamiento_aplicado', fullData.tratamiento_aplicado_task);
                formData.append('informacion_adicional', fullData.informacion_adicional_task);
                formData.append('file_document', fullData.file_document_task);

            var data = await axios_conexion(formData,'/fridge_create_task')
            return data.data 
}

export const deleteFridgeFunct= async (idFridge,idUser,codigo) => { 
    var formData = new FormData();
    formData.append('idFridge',idFridge);
    formData.append("idUser", idUser);    
    formData.append("codigo", codigo);
    var data = await axios_conexion(formData,'/delete_fridge')
    return data.data
}
export const deleteFridgeFunctTask= async (idFridgeTask,idUser,codigo) => { 
    var formData = new FormData();
    formData.append('idFridgeTask',idFridgeTask);
    formData.append("idUser", idUser);    
    formData.append("codigo", codigo);    
    var data = await axios_conexion(formData,'/delete_fridge_task')
    return data.data
}
export const set_vacuna_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_vacuna_register')
    return data.data
}
export const set_peso_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_peso_register')
    return data.data
}
export const set_aftosa_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_aftosa_register')
    return data.data
}
export const upload_file_img = async (file_document,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append("file_document", file_document);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/upload_file_img')
    return data.data
}
export const set_fattening_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_fattening_register')
    return data.data
}
export const set_medicine_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_medicine_register')
    return data.data
}
export const set_moreinfo_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_moreinfo_register')
    return data.data
}
export const set_task_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_task_register')
    return data.data
}
export const set_arete_register = async (dataRow,codigo,animal_id,idUser) => { 
    var formData = new FormData();
    formData.append('dataRow',JSON.stringify(dataRow));
    formData.append("codigo", codigo);
    formData.append("animal_id", animal_id);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/set_arete_register')
    return data.data
}
export const delete_vacuna = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/delete_vacuna')
    return data.data
}
export const delete_peso = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/delete_peso')
    return data.data
}
export const delete_aftosa = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);
    var data = await axios_conexion(formData,'/delete_aftosa')
    return data.data
}
export const delete_fattening = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);    
    var data = await axios_conexion(formData,'/delete_fattening')
    return data.data
}
export const delete_medicine = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);    
    var data = await axios_conexion(formData,'/delete_medicine')
    return data.data
}
export const delete_moreinfo = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);    
    var data = await axios_conexion(formData,'/delete_moreinfo')
    return data.data
}
export const delete_task = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);    
    var data = await axios_conexion(formData,'/delete_task')
    return data.data
}

export const delete_arete = async (id,idUser,codigo) => { 
    var formData = new FormData();
    formData.append("id", id);
    formData.append("codigo", codigo);
    formData.append("idUser", idUser);    
    var data = await axios_conexion(formData,'/delete_arete')
    return data.data
}
export const row_slaughterhouse = async (idUser,codigo,lote_numero, codigoTrazaTwo) => {
    var formData = new FormData();
    formData.append('codigo',codigo);
    formData.append('idUser',idUser);
    formData.append('lote_numero',lote_numero);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    var data = await axios_conexion(formData,'/row_slaughterhouse')
    return data.data
}
export const data_slaught_explode = async (id) => {
    var formData = new FormData();
    formData.append('idExplode',id);
    var data = await axios_conexion(formData, '/data_slaught_explode')
    return data.data
}
export const data_logist_slaught_explode= async (allData,idExplode) => {

    const data = allData.filter(val => val.id==idExplode)
    return data[0]
}
export const data_slaught_packing = async (id) => { 
    var formData = new FormData();
    formData.append('idPacking',id);
    var data = await axios_conexion(formData, '/data_slaught_packing')
    return data.data
}
export const data_logist_slaught_packing = async (allData,idPacking) => { 
    const data = allData.filter(val => val.id==idPacking)
    return data[0]
}
export const data_slaught_freezing = async (id) => { 
    var formData = new FormData();
    formData.append('idFreezing',id);
    var data = await axios_conexion(formData, '/data_slaught_freezing')
    return data.data
}
export const data_logist_slaught_freezing = async (allData,idFreezing) => {
    const data = allData.filter(val => val.id==idFreezing)
    return data[0]
}
export const create_slaugh_explode = async (fullData,idUser,codigo) => {
var formData = new FormData();
    formData.append('codigo',codigo);
    formData.append('idUser',idUser);

    formData.append('explode_id',fullData.explode_id);
    formData.append('fecha_despiece',fullData.fecha_despiece);
    formData.append('lote_numero',fullData.lote_numero_explode);
    formData.append('registro_numero',fullData.registro_numero);
    formData.append('hora_inicio',fullData.hora_inicio);
    formData.append('hora_final',fullData.hora_final);
    formData.append('nro_canales',fullData.nro_canales);
    formData.append('nro_medias_canales',fullData.nro_medias_canales);
    formData.append('nro_cuartos_canales',fullData.nro_cuartos_canales);
    formData.append('carcasa_entera',fullData.carcasa_entera);
    formData.append('pierna',fullData.pierna);
    formData.append('paleta',fullData.paleta);
    formData.append('costillar',fullData.costillar);
    formData.append('otro_corte',fullData.otro_corte);
    formData.append('cantidad',fullData.cantidad);
    formData.append('informacion_adicional',fullData.informacion_adicional_explode);
    formData.append('file_document',fullData.file_document_explode);
var data = await axios_conexion(formData, '/create_slaugh_explode')
return data.data
}
export const create_slaugh_packing = async (fullData,idUser,codigo) => { 
var formData = new FormData();
    formData.append('codigo',codigo);
    formData.append('idUser',idUser);

    formData.append('packing_id',fullData.packing_id);
    formData.append('lote_numero',fullData.lote_numero_packing);
    formData.append('fecha',fullData.fecha_packing);
    formData.append('corte',fullData.corte);
    formData.append('piezas_caja',fullData.piezas_caja);
    formData.append('cantidad_cajas',fullData.cantidad_cajas);
    formData.append('informacion_adicional',fullData.informacion_adicional_packing);
    formData.append('file_document',fullData.file_document_packing);

var data = await axios_conexion(formData, '/create_slaugh_packing')
return data.data
}
export const create_slaugh_freezing = async (fullData,idUser,codigo) => {
    var formData = new FormData();
    formData.append('codigo',codigo);
    formData.append('idUser',idUser);

    formData.append('freezing_id',fullData.freezing_id);
    formData.append('lote_numero',fullData.lote_numero_freezing);
    formData.append('cantidad_producto',fullData.cantidad_cajas_producto);
    formData.append('cantidad_paleta',fullData.cantidad_paleta);
    formData.append('remanente',fullData.remanente); 
    formData.append('producto',fullData.corte_freezing);
    formData.append('fecha_ingreso',fullData.fecha_ingreso);
    formData.append('hora_ingreso',fullData.hora_ingreso);
    formData.append('aplico_preenfriamiento',fullData.aplico_preenfriamiento);
    formData.append('temperatura_inicial',fullData.temperatura_inicial);
    formData.append('temperatura_final',fullData.temperatura_final);
    formData.append('unidad_temperatura_inicial',fullData.unidad_temperatura_inicial);
    formData.append('unidad_temperatura_final',fullData.unidad_temperatura_final);
    formData.append('informacion_adicional',fullData.informacion_adicional_freezing);
    formData.append('file_document',fullData.file_document_freezing);
var data = await axios_conexion(formData, '/create_slaugh_freezing')
return data.data
}
export const deleteExplodeFunct = async (id,idUser,codigo) => {
    var formData = new FormData();
    formData.append('idExplode',id);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData, '/delete_explode')
    return data.data
}
export const deletePackingFunct = async (id,idUser,codigo) => {
    var formData = new FormData();
    formData.append('idPacking',id);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData, '/delete_packing')
    return data.data
}
export const deleteFreezingFunct = async (id,idUser,codigo) => {
    var formData = new FormData();
    formData.append('idFreezing',id);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData, '/delete_freezing')
    return data.data
}
export const get_data_row_main_meat = async (codigo)=>{
    var formData = new FormData();
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/get_data_row_main_meat')
    return data.data;
}

export const get_service_pay = async (idUser,type_traza,typeMA,type_asigne)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('type_traza',type_traza);
    formData.append('typeMA',typeMA);
    formData.append('type_asigne',type_asigne);
    var data = await axios_conexion(formData,'/get_service_pay')
    return data.data;
}
export const set_info_service = async (idUser,service_id,precio,type_traza,time_date,precio_total,idOrder,traza_name,quantity_user,type_asigned_users)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('service_id',service_id);
    formData.append('precio',precio);
    formData.append('type_traza',type_traza);
    formData.append('time_date',time_date);
    formData.append('precio_total',precio_total);
    formData.append('idOrder',idOrder);
    formData.append('traza_name',traza_name);
    formData.append('quantity_user',quantity_user);
    formData.append('type_asigned_users',type_asigned_users);
    var data = await axios_conexion(formData,'/set_info_service')
    return data.data;
}
export const row_list_guide = async (idUser,code,codeLote) =>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('code',code);
    formData.append('codeLote',codeLote);
    var data = await axios_conexion(formData,'/row_list_guide')
    return data.data;
}
export const get_info_guide = async (idGuia,idUser) =>{
    var formData = new FormData();
    formData.append('idGuia',idGuia);
    formData.append('idUser',idUser);    
    var data = await axios_conexion(formData,'/get_info_guide')
    return data.data;
}
export const create_info_guide = async (fullData,idUser,code,dataInfoOrigin,fileOne,fileTwo)=>{
    var formData = new FormData();
    //delete fullData.notificationAlertRef
    formData.append('fullData', JSON.stringify(fullData));
    formData.append('idUser',idUser);
    formData.append('code',code);
    formData.append('fileOne',fileOne);
    formData.append('fileTwo',fileTwo);
    formData.append('dataInfoOrigin',JSON.stringify(dataInfoOrigin));
    var data = await axios_conexion(formData,'/create_info_guide')
    return data.data;
}
export const update_info_guide = async (fullData,idUser,code,dataInfoOrigin,fileOne,fileTwo,idGuide)=>{
    var formData = new FormData();
    //delete fullData.notificationAlertRef
    formData.append('fullData', JSON.stringify(fullData));
    formData.append('idUser',idUser);
    formData.append('code',code);
    formData.append('fileOne',fileOne);
    formData.append('fileTwo',fileTwo);
    formData.append('dataInfoOrigin',JSON.stringify(dataInfoOrigin));
    formData.append('idGuide',idGuide);
    var data = await axios_conexion(formData,'/update_info_guide')
    return data.data;
}
export const row_origen_prop_list = async (idUser, idOrigen) => {
    var formData = new FormData();
    formData.append('idOrigen',idOrigen);
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/row_origen_prop_list')
    return data.data;
}
export const delete_row_origin = async (idOrigen,idUser) => {
var formData = new FormData();
    formData.append('idOrigen',idOrigen);
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/delete_row_origin')
    return data.data;
}
export const check_guides = async (type) => {
    var formData = new FormData();
    formData.append('active_guide',type);
    var data = await axios_conexion(formData,'/check_guides')
    return data.data;
}

export const save_global_g = async (idUser,full_data_fields,full_data_name,codigo,type_form,idTG,for_file,title_notify,prefix) => {
        var formData = new FormData();
        formData.append('idTG',idTG);
        formData.append('full_data_name',JSON.stringify(full_data_name));
        formData.append('full_data_fields',JSON.stringify(full_data_fields));
        formData.append('type_form',type_form);
        for_file.map((val,ind)=>{
            if (typeof Object.values(val)[0]=='object') {
                if (Object.values(val)[0].length>0) {
                    Object.values(val)[0].map((a,b)=>{
                        if (a.filed!=null) {
                         var position_table = Object.keys(val)[0]+"_"+b
                         formData.append('for_file['+position_table+']',a.filed);
                        }
                    })
                }else{
                    formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                }
            }else{
                formData.append('for_file['+Object.keys(val)+']','');
            }
        })
        // for_file.map((val,ind)=>{
        //     if (typeof Object.values(val)[0]=='object') {
        //         formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
        //     }else{
        //         formData.append('for_file['+Object.keys(val)+']','');
        //     }
        // })
        formData.append('idUser',idUser);
        formData.append('codigo',codigo);
        formData.append('title_notify',title_notify);
        formData.append('prefix',prefix);
     var data = await axios_conexion(formData,'/create_grains')
     return data;
};
export const save_global_g_include_p = async (valuecode,coderegister) => {
    var formData = new FormData();
    formData.append('valuecode',valuecode);
    formData.append('coderegister',coderegister);
     var data = await axios_conexion(formData,'/save_global_g_include_p')
     return data.data;
};

export const create_production_nama_g = async (idUser,full_data_fields,full_data_name,codigo,type_form,idFv,for_file,title_notify,type_role='general') => {
        var formData = new FormData();
        formData.append('idFv',idFv);
        formData.append('full_data_name',JSON.stringify(full_data_name));
        formData.append('full_data_fields',JSON.stringify(full_data_fields));
        formData.append('type_form',type_form);
        for_file.map((val,ind)=>{
            if (typeof Object.values(val)[0]=='object') {
                if (Object.values(val)[0]!=null) {
                    if (Object.values(val)[0].length>0) {
                        Object.values(val)[0].map((a,b)=>{
                            if (a.filed!=null) {
                             var position_table = Object.keys(val)[0]+"_"+b
                             formData.append('for_file['+position_table+']',a.filed);
                            }
                        })
                    }else{
                        formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                    }
                }
            }else{
                formData.append('for_file['+Object.keys(val)+']','');
            }
        })        
        formData.append('idUser',idUser);
        formData.append('codigo',codigo);
        formData.append('title_notify',title_notify);
        formData.append('type_role',type_role);
     var data = await axios_conexion(formData,'/create_production_nama_g')
     return data.data;
};
export const save_document_production = async (code,table_doc,for_file,idUser) => {
        var formData = new FormData();
        formData.append('code',code);
        formData.append('table_doc',table_doc);
        formData.append('idUser',idUser);
        for_file.map((val,ind)=>{
            if (Object.values(val)[1]!=null) {
                formData.append('for_file['+ind+']',Object.values(val)[1]);
                // if (Object.values(val)[0].length>0) {
                //     Object.values(val)[0].map((a,b)=>{
                //         if (a.filed!=null) {
                //          var position_table = Object.keys(val)[0]+"_"+b
                //         }
                //     })
                // }else{
                //     formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
                // }
            }else{
                formData.append('for_file['+ind+']','');
            }
        })
         var data = await axios_conexion(formData,'/save_document_production')
         return data.data;
};
// export const create_production_nama_g = async (idUser,full_data_fields,full_data_name,codigo,type_form,idFv,for_file,title_notify) => {
//         var formData = new FormData();
//         formData.append('idFv',idFv);
//         formData.append('full_data_name',JSON.stringify(full_data_name));
//         formData.append('full_data_fields',JSON.stringify(full_data_fields));
//         formData.append('type_form',type_form);
//         for_file.map((val,ind)=>{
//             if (typeof Object.values(val)[0]=='object') {
//                 formData.append('for_file['+Object.keys(val)+']',Object.values(val)[0]);
//             }else{
//                 formData.append('for_file['+Object.keys(val)+']','');
//             }
//         })        
//         formData.append('idUser',idUser);
//         formData.append('codigo',codigo);
//         formData.append('title_notify',title_notify);
//      var data = await axios_conexion(formData,'/create_production_nama_g')
//      return data.data;
// };

export const delete_prodction_nama_g = async (idFer,idUser,full_data_fields,full_data_name,codigo,type_form,name_table) => {
    var formData = new FormData();
    formData.append('idFer',idFer);
    formData.append('name_table',name_table);
    formData.append('full_data_name',JSON.stringify(full_data_name));
    formData.append('full_data_fields',JSON.stringify(full_data_fields));
    formData.append('type_form',type_form);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/deleteProdctionNamaG')
    return data.data
}
export const delete_file_prod = async (idRe,table,column,values_img,file_select) => {
    var formData = new FormData();
    formData.append('idRe',idRe);
    formData.append('table',table);
    formData.append('column',column);
    formData.append('values_img',values_img);
    formData.append('file_select',file_select);
    var data = await axios_conexion(formData,'/delete_file_prod')
    return data.data
}
export const get_data_row_admin_grain = async (idUser,codigoTraza,codigoTrazaTwo,db_code,type_job) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    formData.append('db_code',db_code);
    formData.append('type_job',type_job);
    var data = await axios_conexion(formData,'/get_data_row_admin_grain')
    return data.data
}
export const get_data_row_admin_kdes = async (idUser,codigoTraza,codigoTrazaTwo,db_code,type_job) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('codigoTraza',codigoTraza);
    formData.append('codigoTrazaTwo',codigoTrazaTwo);
    formData.append('db_code',db_code);
    formData.append('type_job',type_job);
    var data = await axios_conexion(formData,'/get_data_row_admin_kdes')
    return data.data
}

export const generate_trazabilidad_grain = async(idUser,idTraza,codigoTraza,typeTraza)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idTraza',idTraza);
    formData.append('codigoTraza',codigoTraza);
    formData.append('typeTraza',typeTraza);
    var data = await axios_conexion(formData,'/generate_trazabilidad_grain')
    return data;
}
export const get_data_row_admin_grain_vt = async (codigoTraza,dbUser,type_table)=>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('dbUser',dbUser);
    formData.append('type_table',type_table);
    var data = await axios_conexion(formData,'/get_data_row_admin_grain_vt')
    return data;
}
export const get_data_satelite_vt = async (dbUser,codigoTraza)=>{
    var formData = new FormData();
    formData.append('dbUser',dbUser);
    formData.append('codigoTraza',codigoTraza);
    var data = await axios_conexion(formData,'/get_data_satelite_vt')
    return data.data;
}
export const img_convert_satelite = async (img_url)=>{
    var formData = new FormData();
    formData.append('img_url',img_url);
    var data = await axios_conexion(formData,'/img_convert_satelite')
    return data.data;
}

export const get_data_satelital = async (code,date)=>{
    var formData = new FormData();
    formData.append('code',code);
    formData.append('date',date);
    var data = await axios_conexion(formData,'/get_data_satelital')
    return data.data;
}
export const get_tiff = async (tiffUrl)=>{
    // var formData = new FormData();
    // formData.append('tiffUrl',tiffUrl);
    // var data = await axios_conexion(formData,'/get_tiff')
    var someUrl=config.API_URL+'/get_tiff'
    var auth = "c42690cc30e581023adf4a4341e5395f";
    const response = await fetch(someUrl,{method:'POST',
                headers: { "Content-Type": "multipart/form-data",
                           "Authorization": auth 
                       },
                body: JSON.stringify({tiffUrl:tiffUrl})});

    return response;
}
export const data_list_production = async (idUser,idusercompany,type,select_table,searching) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('searching',searching);
    var data = await axios_conexion(formData,'/data_list_production')
    return data.data
}
export const data_list_production_mrv = async (idUser,idusercompany,type,select_table,searching_dni,searching_specific,type_select,type_traza,type_traza_f_users) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('searching_dni',searching_dni);
    formData.append('searching_specific',searching_specific);
    formData.append('type_select',type_select);
    formData.append('type_traza',type_traza);
    formData.append('type_traza_f_users',type_traza_f_users);
    
    var data = await axios_conexion(formData,'/data_list_production_mrv')
    return data.data
}
export const data_list_user_activity = async (idUser,idusercompany,type,select_table,searching,type_select,type_traza) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('searching',searching);
    formData.append('type_select',type_select);
    formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/data_list_user_activity')
    return data.data
}
export const history_list_production_mrv = async (idUser,idusercompany,type,select_table,searching,type_select,type_traza) => {
    var formData = new FormData();
    formData.append('idUser',idUser);
    formData.append('idusercompany',idusercompany);
    formData.append('type',type);
    formData.append('select_table',select_table);
    formData.append('searching',searching);
    formData.append('type_select',type_select);
    formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/history_list_production_mrv')
    return data.data
}

export const get_data_row_mrv_prod = async (idUser)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/get_data_row_mrv_prod')
    return data;
}
export const add_favorite_mrv = async (code_prod,user_id_db,check)=>{
    var formData = new FormData();
    formData.append('code_prod',code_prod);
    formData.append('user_id_db',user_id_db);
    formData.append('check',check);
    var data = await axios_conexion(formData,'/add_favorite_mrv')
    return data;
}
export const data_obv_mvr = async (form,code,db,type_traza)=>{
    var formData = new FormData();
    formData.append('form',form);
    formData.append('code',code);
    formData.append('db',db);
    formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/data_obv_mvr')
    return data.data;
}
export const data_obv_vr = async (type_user_role,code,db,type_t)=>{
    var formData = new FormData();
    formData.append('type_user_role',type_user_role);
    formData.append('code',code);
    formData.append('db',db);
    formData.append('type_t',type_t);
    var data = await axios_conexion(formData,'/data_obv_vr')
    return data.data;
}
export const data_obv_uservr = async (code,db,ifmrv)=>{
    var formData = new FormData();
    formData.append('code',code);
    formData.append('db',db);
    formData.append('ifmrv',ifmrv);
    var data = await axios_conexion(formData,'/data_obv_uservr')
    return data.data;
}

export const save_report_mrv = async (fullData,data_mvr,form,db,code,signature,validorobs)=>{
    var formData = new FormData();
    formData.append('form',form);
    formData.append('db',db);
    formData.append('code',code);
    formData.append('register_id',fullData.register_id);
    formData.append('monitor_type',fullData.monitor_type);
    formData.append('entidad',fullData.entidad);
    formData.append('name_mvr',fullData.name_mvr);
    formData.append('monitor_number',fullData.monitor_number);
    formData.append('monitor_date',fullData.monitor_date);
    formData.append('init_time',fullData.init_time);
    formData.append('monitor_last_date',fullData.monitor_last_date);
    formData.append('last_time',fullData.last_time);
    formData.append('fullData',JSON.stringify(data_mvr));
    //formData.append('activity',fullData.activity);
    // formData.append('monitor_result',fullData.monitor_result);
    // formData.append('state',fullData.state_status);
    // formData.append('observation',fullData.observation);
    // formData.append('recommendation',fullData.recommendation);
    formData.append('signature',signature);
    formData.append('validorobs',validorobs);
    formData.append('nro_autorization',fullData.nro_autorization);
    formData.append('document',fullData.document);
    var data = await axios_conexion(formData,'/save_report_mrv')
    return data.data;
}
export const upload_irgi = async (db,code,fileUpload,id_mrv,role_mrv) => {
        var formData = new FormData();
        formData.append('db',db);
        formData.append('code',code);
        formData.append('fileUpload',fileUpload);
        formData.append('id_mrv',id_mrv);
        formData.append('role_mrv',role_mrv);
        var data = await axios_conexion(formData,'/upload_irgi')
        return data.data;
}
export const get_info_irgi = async (db,code,id_mrv,role_mrv) => {
        var formData = new FormData();
        formData.append('db',db);
        formData.append('code',code);
        formData.append('id_mrv',id_mrv);
        formData.append('role_mrv',role_mrv);
        var data = await axios_conexion(formData,'/get_info_irgi')
        return data.data;
}
export const get_info_user_irgi = async (db,code,id_mrv,role_mrv) => {
}
export const save_signature_mrv = async (idUser,signature,type_save)=>{
        var formData = new FormData();
        formData.append('idUser',idUser);
        formData.append('signature',signature);
        formData.append('type_save',type_save);
        var data = await axios_conexion(formData,'/save_signature_mrv')
        return data.data;
}
export const save_signature_production = async (user_db,codigo,signature_drawer,signature_digital,fullData,typeSave)=>{
        var formData = new FormData();
        formData.append('user_db',user_db);
        formData.append('codigo',codigo);
        formData.append('signature_digital',signature_digital);
        formData.append('signature_drawer',signature_drawer);
        formData.append('entidad',fullData.entidad);
        formData.append('name_mvr',fullData.name_mvr);
        formData.append('monitor_number',fullData.monitor_number);
        formData.append('monitor_date',fullData.monitor_date);
        formData.append('init_time',fullData.init_time);
        formData.append('monitor_last_date',fullData.monitor_last_date);
        formData.append('last_time',fullData.last_time);
        formData.append('typeSave',typeSave);
        var data = await axios_conexion(formData,'/save_signature_production')
        return data.data;
}
export const get_user_mrv = async (idUser)=>{
    var formData = new FormData();
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/get_user_mrv')
    return data.data;
}
export const save_report_mrv_status = async (register_data,register_id,mvr_id,code,activity_title)=>{
    var formData = new FormData();
    formData.append('register_data',register_data);
    formData.append('register_id',register_id);
    formData.append('mvr_id',mvr_id);
    formData.append('code',code);
    formData.append('activity_title',activity_title);
    var data = await axios_conexion(formData,'/save_report_mrv_status')
    return data.data;
}
export const save_report_mrv_signature = async (register_data,register_id,mvr_id,code,db_name,type_role)=>{
    var formData = new FormData();
    formData.append('register_data',register_data);
    formData.append('register_id',register_id);
    formData.append('mvr_id',mvr_id);
    formData.append('code',code);
    formData.append('db_name',db_name);
    formData.append('type_role',type_role);
    var data = await axios_conexion(formData,'/save_report_mrv_signature')
    return data.data;
}

export const get_data_mrv = async (code,type_traza)=>{
    var formData = new FormData();
    formData.append('code',code);
    formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/get_data_mrv')
    return data.data;

}
export const verify_email = async (email,verify_code) => {
    var formData = new FormData();
    formData.append('email',email);
    formData.append('verify_code',verify_code);
    var data = await axios_conexion(formData,'/verify_email_app')
    return data.data
}
export const row_info_prod = async (valid_bool,table)=>{
    var formData = new FormData();
    formData.append('valid_bool',valid_bool);
    formData.append('table',table);
    var data = await axios_conexion(formData,'/row_info_prod')
    return data.data
}
export const put_info_prod = async (code_selected,table)=>{
    var formData = new FormData();
    formData.append('code_selected',code_selected);
    formData.append('table',table);
    var data = await axios_conexion(formData,'/put_info_prod')
    return data.data
}
export const verify_info_prod = async (table)=>{
    var formData = new FormData();
    formData.append('table',table);
    var data = await axios_conexion(formData,'/verify_info_prod')
    return data.data
}

export const get_data_user_kdes = async (email,token,section,role)=>{
    var formData = new FormData();
    formData.append('email',email);
    formData.append('token',token);
    formData.append('section',section);
    formData.append('role',role);
    var data = await axios_conexion(formData,'/get_data_user_kdes')
    return data.data
}

export const register_send_kdes = async (name, second_name, email, empresa, userType, trazabilidad, codeT, userTypeRegister) => {
    var formData = new FormData();
        formData.append('name',name);
        formData.append('second_name',second_name);
        formData.append('email',email);
        formData.append('empresa',empresa);
        formData.append('userType',userType);
        formData.append('trazabilidad',trazabilidad);
        formData.append('codeT',codeT);
        formData.append('userTypeRegister',userTypeRegister);
    var data = await axios_conexion(formData,'/register_send_kdes')
    return data;
}
export const register_valid_kdes = async (name, second_name, email, empresa, pais, trazabilidad, password, userType, telefono, cargo, dni) => {
    var formData = new FormData();
        formData.append('name',name);
        formData.append('second_name',second_name);
        formData.append('email',email);
        formData.append('empresa',empresa);
        formData.append('pais',pais);
        formData.append('password',password);
        formData.append('userType',userType);
        formData.append('telefono',telefono);
        formData.append('cargo',cargo);
        formData.append('trazabilidad',trazabilidad);
        formData.append('dni',dni);
    var data = await axios_conexion(formData,'/register_valid_kdes')
    return data;
}


export const data_list_users_kdes = async (codeT,type_traza) => {
    var formData = new FormData();
    formData.append('codeT',codeT);
    formData.append('type_traza',type_traza);
    
    var data = await axios_conexion(formData,'/data_list_users_kdes')
    return data.data
}
export const send_email_repeat_kdes = async (user_id,name,empresa,email,tokenuuid,type_traza) => {
    var formData = new FormData();
        formData.append('user_id',user_id);
        formData.append('name',name);
        formData.append('empresa',empresa);
        formData.append('email',email);
        formData.append('tokenuuid',tokenuuid);
        formData.append('type_traza',type_traza);
    var data = await axios_conexion(formData,'/send_email_repeat_kdes')
    return data.data;

}
export const delete_user_kdes = async (email,idUser) => {
    var formData = new FormData();
    formData.append('email',email);
    formData.append('idUser',idUser);
    var data = await axios_conexion(formData,'/delete_user_kdes')
    return data.data
}
export const add_kdes_user = async (activation,user_id,empresa,type,code_traza,name,email,active_visual,tokenuuid,userTypeRegister) => {
    var formData = new FormData();
    formData.append('activation',activation);
    formData.append('user_id',user_id);
    formData.append('empresa',empresa);
    formData.append('type',type);
    formData.append('code_traza',code_traza);
    formData.append('name',name);
    formData.append('email',email);
    formData.append('active_visual',active_visual);
    formData.append('tokenuuid',tokenuuid);
    formData.append('userTypeRegister',userTypeRegister);
    
    var data = await axios_conexion(formData,'/add_kdes_user')
    return data.data
}
export const delete_fertilizer_kdes = async (idFer,idUser,full_data_fields,full_data_name,codigo,type_form,name_table) => {
    var formData = new FormData();
    formData.append('idFer',idFer);
    formData.append('name_table',name_table);
    formData.append('full_data_name',JSON.stringify(full_data_name));
    formData.append('full_data_fields',JSON.stringify(full_data_fields));
    formData.append('type_form',type_form);
    formData.append('idUser',idUser);
    formData.append('codigo',codigo);
    var data = await axios_conexion(formData,'/delete_fertilizer_kdes')
    return data.data
}
export const delete_file_prod_kdes = async (idRe,table,column,values_img,file_select) => {
    var formData = new FormData();
    formData.append('idRe',idRe);
    formData.append('table',table);
    formData.append('column',column);
    formData.append('values_img',values_img);
    formData.append('file_select',file_select);
    var data = await axios_conexion(formData,'/delete_file_prod_kdes')
    return data.data
}
export const get_data_row_main_kdes = async (codigoTraza,typeT,typeKdes,typeRole,user_id,user_id_connection)=>{
    var formData = new FormData();
    formData.append('codigoTraza',codigoTraza);
    formData.append('typeT',typeT);
    formData.append('typeKdes',typeKdes);
    formData.append('typeRole',typeRole);
    formData.append('user_id',user_id);
    formData.append('user_id_connection',user_id_connection);
    
    var data = await axios_conexion(formData,'/get_data_row_main_kdes')
    return data;
}
export const main_content_kdes = async (type_form, codigoTraza) => {
        var formData = new FormData();
        formData.append('type_form',type_form);
        formData.append('codigoTraza',codigoTraza);
        var data = await axios_conexion(formData,'/main_content_kdes')
        return data.data;
}
export const export_excel_api = async (arrayData) => {
    var someUrl=config.API_URL+'/export_excel_api'
    var auth = "c42690cc30e581023adf4a4341e5395f";

    var formData = new FormData();
    formData.append('arrayData',JSON.stringify(arrayData));

        axios({
            url:someUrl,
            method: 'POST',
            responseType: 'blob', 
            data: formData,
            headers:{"Authorization": auth}
        }).then((response) => {
            //console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `trazabilidad_data_user_activity_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });

    // var formData = new FormData();
    // formData.append('arrayData',JSON.stringify(arrayData));
    // var data = await axios_conexion(formData,'/export_excel_api')
    // const url = URL.createObjectURL(new Blob([data.data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'exc.xlsx'); //or any other extension
    // document.body.appendChild(link);
    // link.click();
    //return data.data;
}
export const export_excel_traza = async (arrayData) => {
    var someUrl=config.API_URL+'/export_excel_traza'
    var auth = "c42690cc30e581023adf4a4341e5395f";

    var formData = new FormData();
    formData.append('arrayData',JSON.stringify(arrayData));

        axios({
            url:someUrl,
            method: 'POST',
            responseType: 'blob', 
            data: formData,
            headers:{"Authorization": auth}
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `trazabilidad_data_fda_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });

}
export const get_training = async () => {
        var formData = new FormData();
        formData.append('type','videos');
        var data = await axios_conexion(formData,'/get_training')
        return data.data;
}

export const message_assistant = async () => {
        var formData = new FormData();
        formData.append('type','assistant');
        var data = await axios_conexion(formData,'/message_assistant')
        return data.data;
}
export const createTask = async (userId, title, description, color, startDate, endDate) => (
    await instance.post('tasks/add', {userId, title, description, color, startDate, endDate})
);

export const editTask = async (id, title, description, color, startDate, endDate) => (
    await instance.post('tasks/edit', {id, title, description, color, startDate, endDate})
);

export const getTasksByUser = async userId => (
    await instance.post('tasks/user', {userId})
);

export const getTaskById = async id => (
    await instance.post(`tasks/one/${id}`)
);

export const deleteTask = async id => (
    await instance.post('tasks/delete', {id})
);

// posts

export const getPosts = async () => (
    await instance.post('posts/all')
);

export const getPostsFromToday = async () => (
    await instance.post('posts/published')
);

export const getPostsById = async id => (
    await instance.post(`posts/one/${id}`)
);

export const addPost = async (title, content, categories, tags, authorName, publishedAt) => (
    await instance.post('posts/add', {title, content, categories, tags, authorName, publishedAt})
);

export const editPost = async (id, title, content, categories, tags, authorName, publishedAt) => (
    await instance.post('/posts/edit', {id, title, content, categories, tags, authorName, publishedAt})
);

export const deletePost = async id => (
    await instance.post('posts/delete', {id})
);

export const getPostByCateogory = async category => (
    await instance.post('posts/categories', {category})
);

// categories

export const getCategories = async () => (
    await instance.post('categories/all')
);

export const addCategory = async (title, description) => (
    await instance.post('categories/add', {title, description})
);

export const getCategoryById = async id => (
    await instance.post(`categories/one/${id}`)
);

export const deleteCategory = async id => (
    await instance.post('categories/delete', {id})
);

export const editCategory = async (title, description, id) => (
    await instance.post('categories/edit', {title, description, id})
);
