import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Input,
  Collapse
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_processes,get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import VistaTraceProd from "views/pages/examples/Production/VistaTraceProViewTable";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import GNama from "views/pages/forms/Production/Grains/GrainNama/StartGrainNamaForm.js";
import ViewObservationMVR from "views/pages/tables/Production/ViewObservationMVR.js";
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";

export default class TraceViewProduction extends React.Component {
  static contextType = UserContext
  state={
    data_traza:[],
    trazabilidad_meat:[],
    company_id:null,
    setinfoState:false,
    collapse:[],
    globalFilter:'',
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    visibleRight:false,
    code:null,
    full_data_gc:[]
  }
 async componentDidMount(){
  this.context.getAffiliates()
  }
 setInfoInit = async (tData) =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }
          var dataPermission = this.context.valueGlobal
          var setTypeT = ""
          if (dataPermission.type_traza.indexOf("P")!=-1) {
            setTypeT="P"
          }else{
            if (dataPermission.type_traza.indexOf("VCF")!=-1) {
              setTypeT="VCF"
            }
          }
          var data_type_area = dataPermission.type_traza.indexOf(setTypeT)
          if (data_type_area==-1) {
            this.setState({get_permission:false})
            return;
          }
            if (dataPermission.type_traza==null) {
          jQuery('#tg').remove()
          return
        }else{
          if (dataPermission.type_traza.indexOf(setTypeT)==-1) {
            jQuery('#tg').remove()
          }
        }
        var thisExtends = this
        var data = await data_list_processes(jsondata.user_id_origin,dataPermission.user_id_connection,dataPermission.type,'tvt',jsondata.userType)
        var gerParam = new URLSearchParams(this.props.location.search).get("company");
        if (setTypeT=="P") {
          this.setState({
            company_id:gerParam,
            full_data_gc:data,
            data_nama_g:data.nama_grain[1],
            data_nama_g_th:data.nama_grain[0],
            type_role_user:dataPermission.type_traza,
          })
        }

        if (setTypeT=="VCF") {
          this.setState({
            company_id:gerParam,
            full_data_gc:data,
            data_nama_g:data.FV_GC[1],
            data_nama_g_th:data.FV_GC[0],
            type_role_user:dataPermission.type_traza,
          })
        }

      }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }
  setContent = (data) => {
    if (data==undefined) {
      return
    }
    if (data.length!=0) {
    var content=[]
      data.map((a,b)=>{
        var get_codigo = null
         var content_one = Object.entries(a).map((val,ind)=>{
            var url_selected = null
            if (val[0]=='codigo') {
              if (val[1]?.split('-')[0]=='FCTGN') {
                url_selected = "/admin/vista-trace-admin-grain-nama"
              }
              if (val[1]?.split('-')[0]=='FCT') {

               url_selected = "/admin/vista-trace-admin"
              }
            }
            if (val[0]=='codigo') {
              get_codigo=val[1]
            }
 var content_two = <>
  {(val[0]!='codigo')?(<td ><span className="text-muted">{val[1]}</span></td>):(null)}
  {(val[0]=='codigo')?(<><td ><span className="text-muted">{val[1]}</span></td><td><ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:url_selected,
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver en Producción
                     </ComponentLink>
                     <br />
                     <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:"/admin/prouction-observations-mvr",
                        search: (this.state.company_id==null)?("?code="+val[1]):("?company="+this.state.company_id+"&code="+val[1]),
                        state:{
                          codigoTraza:val[1]
                        }
                        }}
                      >
                     Ver Todas las Observaciones
                     </ComponentLink>
{/*                    <br />
                    <button style={{backgroundColor: 'transparent',border: 'none',padding: 0}} onClick={() => {this.toggleFunction(b,this.state.collapse[val[1]],val[1])}}>
                      Desplegar para ver mas detalle
                    </button>
                  */}
                   </td></>):(null)}
                      </>
                      return content_two
        })
        content[b] = <>
                    <tr>
                    {content_one}
                    </tr>
                        <tr>
                          <td colspan="5">
                          <Collapse isOpen={this.state.collapse[get_codigo]}>
                          <div style={{width: '56%'}}>
                            <VistaTraceProd code={this.state.codeTTV}/>
                          </div>
                          </Collapse>
                          </td>
                        </tr>
                    </>
      })
    }
    return content
  }
setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }
  changeRegistersType = (type) => {
    var setData=[]
    if (type=="P") {
      setData = this.state.full_data_gc.nama_grain
    }
    if (type=="VCF") {
      setData = this.state.full_data_gc.FV_GC
    }
    this.setState({
      data_nama_g:setData[1],
      data_nama_g_th:setData[0],
    })
  }
render(){
  this.setInfoInit()

    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Tabla gestión de cultivos resumen de actividades
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
              <Input 
              type="select" 
              // value={trazabilidad} 
              onChange={e => {
                this.changeRegistersType(e.target.value)
              }}
              >
                <option value="0">Filtro de Registros</option>
                {(this.state.type_role_user?.indexOf("VCF")!=-1)?(<option value="VCF">Frutas y Vegetales</option>):(null)}
                {(this.state.type_role_user?.indexOf("P")!=-1)?(<option value="P">Granos</option>):(null)}
              </Input>
            </span>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button 
                    icon="pi pi-cog" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    onClick={ () => {
                      var setUrlPush = 'vista-trace-admin-grain-nama'
                      if (rowData.codigo.indexOf('FCTVFGC-')!=-1) {
                        setUrlPush = 'trace_view_admin_fv_gc'
                      }
                      this.props.history.push( "/admin/"+setUrlPush+( (this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo) ) )

                    }}
                    tooltip="Ver este proceso" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />

                <Button 
                    icon="pi pi-eye" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod" 
                    onClick={ () => {
                      this.setState({visibleRight:true, code:rowData.codigo})
                      //this.props.history.push( '/admin/prouction-observations-mvr'+((this.state.company_id==null)?("?code="+rowData.codigo):("?company="+this.state.company_id+"&code="+rowData.codigo)) )
                    }}
                    tooltip="Ver observaciones" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }} 
                />
{/*                <Button 
                    icon="pi pi-pencil" 
                    rounded 
                    outlined 
                    className="mr-2 button-fod"
                    //onClick={() => editProduct(rowData)} 
                    tooltip="Editar Información" 
                    tooltipOptions={{ 
                        position: 'bottom',
                        className: "hoverClass",
                    }}
                />*/}

            </>
        );
    };
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
  return (
    <>
        <div className="mt-3">
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    {/*<Toast ref={toast} />*/}

                    <DataTable
                        value={this.state.data_nama_g}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column field="id" key="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {this.setContentTh(this.state.data_nama_g_th).map((a,b)=>{
                          return <Column
                          body={(data)=>{
                            if (data[a.table]!=null) {
                              if (data[a.table].length>10) {
                                return `${data[a.table].substring(0, 10)}...`
                              }else{
                                return data[a.table]
                              }
                            }else{
                              return "Sin datos"
                            }
                          }}
                           field={a.table} header={a.title} ></Column>
                        })}
                        <Column field="created_at" header="Creado" sortable></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                    </DataTable>

    
                </div>
            </div>
        </div>
      </div>
      <SidebarCustom 
          onVisible={this.state.visibleRight} 
          onHide={() => this.setState({visibleRight:false})} 
          onWidth={ 800 }
          position="right" 
          title={'Observación'}>
          <ViewObservationMVR {...this.props} hide={ () => this.setState({visibleRight:false}) } code={this.state.code} />
      </SidebarCustom>
    </>
  );
 }
}

//export default TraceViewProduction;
