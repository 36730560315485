import React,{useState} from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse
} from "reactstrap";
import config from "config";
import GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob } from 'geotiff';
import * as plotty from 'plotty';
import { get_data_satelital,get_tiff } from "network/ApiAxios";
import jQuery from 'jquery';
import jsPDF from 'jspdf';

export const PutContentStatus = (props,type) => {
   var data_status_color = '#7a7a7a78'
   var data_status_status = 'Cargando...'
   if (props[type]!=undefined) {
        if (props[type]['data_array'].length==0) {
           data_status_status = 'Sin Registro'
           data_status_color = '#7a7a7a78'
        }else{
           data_status_status = props[type]['data_array'][0].status
           if (props[type]['data_array'][0].status=='finalizado') {
            data_status_color = '#58d99978'
           }
           if (props[type]['data_array'][0].status=='desarrollo') {
            data_status_color = '#f8d01478'
           }
        }
   }else{
    data_status_color = '#7a7a7a78'
    data_status_status = ''
   }
        return {color:data_status_color,register_status:data_status_status}
}

export const PutContentStatusSatelite = (props,type) => {
   var data_status_color = '#7a7a7a78'
   var data_status_status = 'Cargando...'
   if (props[type]!=undefined) {
        if (props[type]['data_array'].length==0) {
           data_status_status = 'Sin Registro'
           data_status_color = '#7a7a7a78'
        }else{
           data_status_status = props[type]['data_array'][0].status
           if (props[type]['data_array'][0].status=='finalizado') {
            data_status_color = '#58d99978'
           }
           if (props[type]['data_array'][0].status=='desarrollo') {
            data_status_color = '#f8d01478'
           }
        }
   }else{
    data_status_color = '#7a7a7a78'
    data_status_status = ''
   }
        return {color:data_status_color,register_status:data_status_status}
}

export function PutContent(props) {

var contenido = []
var contenidoData = []
if (props.data_function.allDataTrza[props.type_function]!=undefined) {
        if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
           props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{

            var get_select =putForSelect(Object.entries(value))
            Object.entries(value).map((a,b)=>{
                  //contenidoData[b]=a[1]
              if (a[0]!='status' && a[0]!= 'info_mrv' && a[0]!='id' && a[0]!='register_link_id' && a[0]!='trazabilidad_id' && a[0]!='user_id' && a[0]!='codigo' && a[0]!='token_id' && a[0]!='trazabilidade_ida' && a[0]!='created_at' && a[0]!='updated_at') {
                if (a[0].indexOf('select_')==-1) {
                  if (a[1]!=null && a[1]!='') {
                    if (a[0].indexOf('file_')!=-1) {
                      var file_data = a[1]
                      if (file_data!=null) {
                        var val_file_data = file_data.split('/')
                        contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> {val_file_data[1]+get_select[a[0]]}</Col></>
                      }
                    }else{
                      if (String(a[1]).indexOf('|')!=-1) {
                        contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> 
                        {a[1].split('|').map((valOne,indOne)=>{
                          return <div>{valOne}</div>
                        })}
                        </Col></>
                      }else{
                        //contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> {a[1]+get_select[a[0]]}</Col></>
                        if (a[0]!='field_duplicate') {
                          contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),a[0])}:</label><br /> {a[1]+get_select[a[0]]}</Col></>
                        }else{
                          contenido[b] = setContentDuplicate(a[1],props.data_function.allDataTrza[props.type_function]['title_label'].split('|'),get_select)
                        }
                      }
                    }
                  }
                }
              }
                  if (a[0]=='id') {
                    contenido[b] = <><Col key={b-1} style={{display:'none'}} className={"get_info_register_"+props.type_function} id-register={a[1]}>{a[1]}</Col></>
                  }
            })
           })
        }
}
        return (contenido)
}
  const putForSelect = (field) => {
                  var get_data_select = []
                  field.map((a,b)=> {
                    if (a[0].indexOf('select_')!=-1) {
                      get_data_select[a[0].replaceAll('select_','')] = ' '+a[1]
                    }else{
                      get_data_select[a[0].replaceAll('select_','')] = ''
                    }
                  })
                  // if (field[0].indexOf('select_')!=-1) {
                  //   get_data_select[field[0]] = field[1]
                  // }
                  return get_data_select
  }
  const dateFollow=(paramDateFollow)=>{
    var varDate = new Date(paramDateFollow);
    return varDate.getDate()+'-'+varDate.getMonth()+'-'+varDate.getFullYear()
  }
export function PutContentSub(props) {
 var contenido = []
 var contenido_one = []
 var contenido_two = []
 if (props.data_function.allDataTrza[props.type_function]!=undefined) {

         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
            var get_title_main = forSelectTitleMain(props.data_function.allDataTrza[props.type_function]['divider_title_select'])
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
                contenido_one[index] = <>
                                      <div className="col-md-3" style={{marginBottom: '10px'}}>
                                      {(value.info_mrv==1)?(<label className="form-control-label">Corrección {dateFollow(value.created_at)}</label>):(<label className="form-control-label">Original</label>)}
                                        <p className={(value[get_title_main]=='null')?(''):(' '+value[get_title_main]+value.id)} onClick={()=>{
                                          props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index, 
                                          (value[get_title_main]=='null')?('Sin titulo'):(value[get_title_main]), value.id )
                                        }}
                                          id="elementor-tab-title-8621" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                          <div >
                                          {(value[get_title_main]=='null')?('Sin titulo'):(value[get_title_main])}
                                          </div>
                                        </p>
                                      </div></>
       contenido_two[index] = <>
                              <li key={index} style={{listStyle: 'none'}}>
                                  <div className="elementor-toggle-item" style={{zIndex: '99'}} >
                                        {/*
                                        <div className="elementor-toggle-title">
                                        Datos {index+1}
                                        </div>
                                        */}
{/*                                    <div className="row">
                                    </div>*/}
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul className="p-0">
                                    <div md="12">
                                                  <Card className="p-0">
                                                    <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                    <Row>
                                                      <Col md={2}>
                                                        <h4 className="mb-0" style={{color: '#ffffff',fontSize: '11px'}}>Estado:</h4>
                                                      </Col>
                                                      <div className="col-md-3">
                                                        <h4 className="mb-0" style={{color: '#ffffff',fontSize: '11px'}}>{value.status}</h4>
                                                      </div>
                                                    </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                      <Row>
                                                      <PutContentDesglos 
                                                      data_function={[value]} 
                                                      type_function={props.type_function} 
                                                      data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                                                      />
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                                </div>
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li></>
            })
         }
}
contenido = [<div className="row text-center">{contenido_one}</div>,contenido_two]

         return (contenido)
}
// export function PutContentSub(props) {
//  var contenido = []
//  if (props.data_function.allDataTrza[props.type_function]!=undefined) {

//          if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
//             props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
//        contenido[index] = <li key={index}>
//                                   <div className="elementor-toggle-item">
//                                       <h6 onClick={()=>{props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
//                                         <div className="elementor-toggle-title">Datos {index+1}</div>
//                                       </h6>
//                                     <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} style={{width: '250%',position: 'relative',right: '100%'}} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
//                                     <ul>
//                                     <Col md="12" style={{margin: 'auto'}}>
//                                                   <Card>
//                                                     <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
//                                                     <Row>
//                                                       <Col md={9}>
//                                                         <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
//                                                       </Col>
//                                                       <Col md={3}>
//                                                         <h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>
//                                                       </Col>
//                                                     </Row>
//                                                     </CardHeader>
//                                                     <CardBody>
//                                                       <Row>
//                                                       <PutContentDesglos 
//                                                       data_function={[value]} 
//                                                       type_function={props.type_function} 
//                                                       data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
//                                                       />
//                                                       </Row>
//                                                     </CardBody>
//                                                   </Card>
//                                                 </Col>
//                                     </ul>
//                                     </Collapse>
//                                   </div>
//                                 </li>              
//             })
//          }
// }
//          return (contenido)
// }
function forSelectTitleMain(data) {
  var title_main=''
  data.split('|').map((a,b)=>{
    var get_g = a.split('*')
      if (get_g[1]==1) {
        title_main = get_g[0]
      }
  })
    return title_main
}
export function PutContentSubTwo(props) {

 var contenido = []
 if (props.data_function.allDataTrza[props.type_function]!=undefined) {
         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
          var get_title_main = forSelectTitleMain(props.data_function.allDataTrza[props.type_function]['divider_title_select'])
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
       contenido[index] = <li key={index}>
                                  <div className="elementor-toggle-item">
                                      <h6 onClick={()=> {props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Guía: {(value[get_title_main]=='null')?('Sin número de guía'):(value[get_title_main])}- Finca: {value.nombre_de_la_finca} - {value.fecha_salida+' '+value.hora_salida}</div>
                                      </h6>
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                  <PutContentSubSunTree
                  data_function={[value]}
                  data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                  type_function={props.type_function}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datatc"}
                  titleType={props.titleType}
                  />

                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_two]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_two]['title_label']}
                  type_function={props.type_function_two}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datav"}
                  titleType={props.titleTypeTwo}
                  />
                                    </ul>
                                    </Collapse>
                                  </div>
                                </li>
            })
         }
}

         return (contenido)
}
export function PutContentSubPlant(props) {

 var contenido = []
  if (props.data_function.allDataTrza[props.type_function]!=undefined) {
         if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
            var get_title_main = forSelectTitleMain(props.data_function.allDataTrza[props.type_function]['divider_title_select'])
            props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{

       contenido[index] = <div className="elementor-toggle-item" key={index}>
                                      <h6 onClick={()=> {props.openedChild(props.states.openColapseChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                        <div className="elementor-toggle-title">Guía: {(value[get_title_main]=='null')?('Sin número de guía'):(value[get_title_main])}</div>
                                      </h6>
                                    <Collapse isOpen={props.states.openColapseChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                    <ul>
                  <PutContentSubSunTree
                  data_function={[value]}
                  data_function_label={props.data_function.allDataTrza[props.type_function]['title_label']}
                  type_function={props.type_function}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datadp"}
                  titleType={props.titleType}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_two]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_two]['title_label']}
                  type_function={props.type_function_two}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datasp"}
                  titleType={props.titleTypeTwo}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_tree]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_tree]['title_label']}
                  type_function={props.type_function_tree}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"dataw"}
                  titleType={props.titleTypeTree}
                  />
                 <PutContentSubSunTree
                  data_function={[props.data_function.allDataTrza[props.type_function_four]['data_array'][index]]}
                  data_function_label={props.data_function.allDataTrza[props.type_function_four]['title_label']}
                  type_function={props.type_function_four}
                  states={props.states}
                  openedChild={props.openedChild}
                  openedNextChild={props.openedNextChild}
                  chillSelector={"datapt"}
                  titleType={props.titleTypeFour}
                  />
                                    </ul>
                                    </Collapse>
                                  </div>
            })
         }
}

         return (contenido)
}
const getContentSelectTr=(prps)=>{
 var content = []
 content[0] =<>
                   <tr>
                    <td style={{border: '1px solid',padding: '3px'}}>
                     <span style={{color: 'black', fontSize: '13px'}}>
                      Descripción
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Vigor del Cultivo<br/>
                     C*(0,6-0,8) M*(0,3-0,5)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Salud del Cultivo<br/>
                     C(0,3-0,5) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad de la Vegetación<br/>
                     C(0,4-0,6) M(0,2-0,4)
                     </span>
                    </td>
                    <td style={{border: '1px solid',padding: '3px',width: '160px'}}>
                     <span style={{color: 'black', fontSize: '12px'}}>
                     Humedad del Suelo<br/>
                     C(0,7-0,8) M(0,6-0,7)
                     </span>
                    </td>
                  </tr>
 </>
   prps.data_function.allDataTrza[prps.first_data_table].title_label.split('|').map((val,indx)=>{
     content[indx+1] = <>
                   <tr>
                    <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                     <span style={{color: 'black', fontSize: '13px'}}>
                      {val}
                     </span>
                    </td>
                       <PutFormContentSelectTh {...prps} idxCon={val.replaceAll('ñ','n').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(/[^a-zA-Z]+/g, '_').replaceAll(' ','_').replaceAll('/','_').toLowerCase()} />
                  </tr>
     </>
   })
   return content
}
const getInfomationsTh = (infor,idxCon)=>{
  if (infor.length!=0) {
    return infor[0][idxCon]
  }
}
export function PutFormContentSelectTh(props) {
var contenido = []
var contenidoData = []
props.data_table.map((val,inx)=>{
  contenido[inx] = <>
<td style={{border: '1px solid',padding: '3px'}}>
  <span style={{color: 'black', fontSize: '13px'}}>
  {getInfomationsTh(props.data_function.allDataTrza[val.table]['data_array'],props.idxCon)}
  </span>
</td></>
})
return (contenido)
}
const loadFileTiff=async(indentifier,urlTiff,dataView,colorPallet)=>{
  const response = await get_tiff(urlTiff)
  const arrayBufferRes = await response.arrayBuffer();
  const tiff = await fromArrayBuffer(arrayBufferRes);
  const image = await tiff.getImage();
  const data = await image.readRasters();

  const array1 = data[0];
   var maxBand = parseFloat(dataView[4])
   var minBand = parseFloat(dataView[3])
    try{
    var canvas = document.querySelector(".plotView"+indentifier);
    //plotty.addColorScale("mycolorspectral", ["#2b83ba", "#fdae61", "#ffffbf", "#abdda4", "#d7191c"], [1, 1, 0.4, 0.8, 0]);
    plotty.addColorScale("mycolorspectral", ["#1a9641", "#fdae61", "#ffffc0", "#a6d96a", "#d7191c"], [1, 1, 0.4, 0.8, 0]);
          var plots = new plotty.plot({
            canvas,
            data: data[0],
            width: image.getWidth(),
            height: image.getHeight(),
            domain: [minBand, maxBand],
            colorScale: 'mycolorspectral'
          });
          //plots.setColorScale(colorPallet)
          plots.render();
          var canvasUrl = document.querySelector(".plotView"+indentifier);
          const dataURL = canvasUrl.toDataURL();
          var img = document.querySelector(".ploteViewImg"+indentifier).src=dataURL;

          var getWidthVar = canvasUrl.getAttribute('width')
          var getHeightVar = canvasUrl.getAttribute('height')
          var getImgStyle = document.querySelector(".ploteViewImg"+indentifier)
          var getWidth =parseInt(getWidthVar)
          var getHeight =parseInt(getHeightVar)
          if (getWidth<100 || getWidth>100) {
            canvasUrl.style.width=String(100)+"px"
            getImgStyle.style.width=String(100)+"px"
          }
          if (getHeight<100 || getHeight>100) {
            var calculeHeith = (getHeight/getWidth)*100
            canvasUrl.style.height=String(calculeHeith)+"px"
            getImgStyle.style.height=String(calculeHeith)+"px"
          }
    }catch(error){
      console.log(error)
    }
}
     const openModalCanvas = (target,indentifier,indentifierSpan,typeClk)=>{
      if (typeClk=='span') {
        var targetCanvas = jQuery('.'+indentifier)[0]
        var getWidthVar = targetCanvas.getAttribute('width')
        var getHeightVar = targetCanvas.getAttribute('height')
        var getWidth =parseInt(getWidthVar)
        var getHeight =parseInt(getHeightVar)

        jQuery(target).removeAttr('style')
        jQuery(targetCanvas).removeAttr('style')
        jQuery('.closeIconTime'+indentifier.replace('plotView','')).hide()
        target.style.color= "black";
        target.style.fontSize= "13px";

        if (getWidth<10 || getWidth>10) {
          targetCanvas.style.width=String(100)+"px"
          var calculeHeith = (getHeight/getWidth)*100
          targetCanvas.style.height=String(calculeHeith)+"px"
        }
        targetCanvas.style.position='absolute'
        targetCanvas.style.top='0px'
        targetCanvas.style.left='0px'
        targetCanvas.style.right='0px'
        targetCanvas.style.margin='auto'
      }
      if (typeClk=='canvas') {

        jQuery('.closeIconTime'+indentifier.replace('plotView','')).show()
        // NDVI
        // NDRE
        // NDMI
        // Suelo
        var getWidthVar = target.getAttribute('width')
        var getHeightVar = target.getAttribute('height')
        var getWidth =parseInt(getWidthVar)
        var getHeight =parseInt(getHeightVar)

        target.style.width=String(400)+"px"
        var calculeHeith = (getHeight/getWidth)*400
        target.style.height=String(calculeHeith)+"px"
        
        target.style.position='fixed'
        target.style.top='0px'
        target.style.bottom='0px'
        target.style.left='0px'
        target.style.right='0px'
        target.style.margin='auto'
        target.style.borderRadius='10px'
        target.style.zIndex= "1060";
        var targetSpan = jQuery('.'+indentifierSpan)[0]
        targetSpan.style.color= "black";
        targetSpan.style.fontSize= "13px";
        targetSpan.style.width= "100%";
        targetSpan.style.height= "100%";
        targetSpan.style.position= "fixed";
        targetSpan.style.background= "#ffffffc4";
        targetSpan.style.inset= "0px";
        targetSpan.style.margin= "auto";
        targetSpan.style.zIndex= "1055";
      }
    }

    const viewImgTiff=(dataUrl,indentifier,dataView)=>{
      // img_convert_satelite(dataUrl).then((data,indx)=>{
      // })
      var contents = []
//      var get_url_img = dataUrl.split('getfile=')
      if (dataUrl!=null || dataUrl!='') {
      var getReplace = dataUrl.replace('http://app.yttrium.farm:9000','https://app.itrio.ai')
        contents[0]= <>
{/*        <a data-url={getReplace} style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} href={getReplace}>Descargar este archivo</a>
        <br/>*/}
            <img className={"ploteViewImg"+indentifier} style={{width:'100px',height:'100px',position: 'absolute',top: '0px',left: '0px',right: '0px',margin: 'auto'}} />

            <canvas
                onClick={(e)=>{
                  openModalCanvas(e.target,"plotView"+indentifier,"spanPlotView"+indentifier,'canvas')
                }}
             onLoad={loadFileTiff(indentifier,getReplace,dataView)} className={"plotView"+indentifier} style={{position: 'absolute',top: '0px',left: '0px',right: '0px',margin: 'auto'}}></canvas>
        </>
      }
      return contents
    }
 const printer_pdf = async (dateSatelite) => {
              //var canvasUrl = document.querySelector(".plotView"+indentifier);
              // const dataURL = canvasUrl.toDataURL();
              // var img = document.querySelector(".plotViewImg"+indentifier).src=dataURL;
              // var data_change = {}
              // jQuery('[class*="plotView"]').map((a,b)=>{
              //   const dataURL = b.toDataURL();
              //   //data_change[] = dataURL
              //   var img = document.querySelector("."+jQuery(b).attr('class').replace('plotView','ploteViewImg')).src=dataURL;

              // })
//              this.setState({plotViewImg:data_change})
            jQuery('[class*="plotView"]').hide()
            jQuery('[class*="btnDPrint"]').prop('disabled',true)
             var component = document.querySelector(".contentForPDF").innerHTML
             var divContents = component

            var doc = new jsPDF({
              orientation: 'landscape' ,
              unit: 'px',
              format: 'A3',
            });
            doc.setFont('Inter-Regular', 'normal');
            doc.html(divContents, {
              async callback(doc) {
                var get_blob = await doc.output('bloburl')
                window.open(get_blob,'_blank')
                await jQuery('[class*="plotView"]').show()
                jQuery('[class*="btnDPrint"]').prop('disabled',false)
                jQuery('.clk_'+dateSatelite).click()
              },
                x: 0,
                y: 0,
                width: 900,
                windowWidth: 1100
            });
}
const getElementTr=(data,colorPallet)=>{
 var content = []
    if (data!=null) {
   content[0] =<>
                     <tr>
                      <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                       <span style={{color: 'black', fontSize: '13px'}}>
                        Descripción
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Vigor del Cultivo<br/>
                       C*(0,6-0,8) M*(0,3-0,5)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Salud del Cultivo<br/>
                       C(0,3-0,5) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad de la Vegetación<br/>
                       C(0,4-0,6) M(0,2-0,4)
                       </span>
                      </td>
                      <td style={{border: '1px solid',padding: '3px'}}>
                       <span style={{color: 'black', fontSize: '12px'}}>
                       Humedad del Suelo<br/>
                       C(0,7-0,8) M(0,6-0,7)
                       </span>
                      </td>
                    </tr>
   </>
                    if (data['Descripción']!=undefined) {
                      Object.values(data['Descripción']).map((val,indx)=>{
                        if (val!='Índice del Cultivo') {
                          content[indx+1] = <>
                                        <tr>
                                         <td style={{border: '1px solid',padding: '3px',width: '265px'}}>
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                           {val}
                                          </span>
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{openModalCanvas(e.target,"plotView_NDVI","spanPlotView_NDVI",'span')}}  className={'spanPlotView_NDVI'}><i class="fas fa-times closeIconTime_NDVI" onClick={()=>{jQuery('.spanPlotView_NDVI').click()}} style={{display:'none',fontSize:'15px',color: 'gray'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {viewImgTiff(data['Vigor del cultivo'][indx],'_NDVI',data['Vigor del cultivo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Vigor del cultivo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{openModalCanvas(e.target,"plotView_NDRE","spanPlotView_NDRE",'span')}} className={'spanPlotView_NDRE'}><i class="fas fa-times closeIconTime_NDRE" onClick={()=>{jQuery('.spanPlotView_NDRE').click()}} style={{display:'none',fontSize:'15px',color: 'gray'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {viewImgTiff(data['Salud del cultivo'][indx],'_NDRE',data['Salud del cultivo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Salud del cultivo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{openModalCanvas(e.target,"plotView_NDMI","spanPlotView_NDMI",'span')}}  className={'spanPlotView_NDMI'}><i class="fas fa-times closeIconTime_NDMI" onClick={()=>{jQuery('.spanPlotView_NDMI').click()}} style={{display:'none',fontSize:'15px',color: 'gray'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {viewImgTiff(data['Humedad de la Vegetacion'][indx],'_NDMI',data['Humedad de la Vegetacion'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Humedad de la Vegetacion'][indx]}
                                          </span>
                                          )}
                                         </td>
                                         <td style={{border: '1px solid',padding: '3px',maxWidth: '135px',textAlign:'center'}}>
                                         {(val=='URL Imagen')?(<>
                                          <span onClick={(e)=>{openModalCanvas(e.target,"plotView_Suelo","spanPlotView_Suelo",'span')}} className={'spanPlotView_Suelo'}><i class="fas fa-times closeIconTime_Suelo" onClick={()=>{jQuery('.spanPlotView_Suelo').click()}} style={{display:'none',fontSize:'15px',color: 'gray'}}></i></span>
                                          <span style={{color: 'black', fontSize: '13px',width: 'auto',height: '100px',position: 'relative',display: 'flex'}}>
                                            {viewImgTiff(data['Humedad del Suelo'][indx],'_Suelo',data['Humedad del Suelo'])}
                                          </span>
                                          </>):(
                                          <span style={{color: 'black', fontSize: '13px'}}>
                                          {data['Humedad del Suelo'][indx]}
                                          </span>
                                          )}
                                         </td>
                                       </tr>
                          </>
                        }

                      })
                    }
    }
   return content
}
const getDataSatelital =async(codeVal,dateVal)=>{
  var getData =await get_data_satelital(codeVal,dateVal)
  return getData
}
export function PutContentSubTablaSatelite(props) {
  const [valueData,setValueData]=useState([])
  const [colorPallet,setColorPallet]=useState('mycolorspectral')
  const [dateSatelite,setDateSatelite]=useState('Fecha')
  var data_content=[]
        data_content = <Card className="mb-4">
          <CardHeader style={{padding: '5px'}}>
            <Row>
              <Col md={12}>
              <h3 className="mb-0">Reporte Satelital del Cultivo</h3>
              </Col>
          </Row>
          </CardHeader>
          <CardBody className="pt-0">
                {(props.data_f_satelite==null)?('Cargando...'):
                 ((props.data_f_satelite.data_satelital==null)?('Sin datos'):
                  (
                    (typeof props.data_f_satelite.data_satelital=='string')?(props.data_f_satelite.data_satelital):(<>
                    <Row>
{                    Object.entries(props.data_f_satelite.data_satelital).map((val,index)=>{
                                   return <>
                                   <Col md={4}>
                                      <label
                                        className={"form-control-label mb-0 mt-1 clk_"+val[0]}
                                        htmlFor="example3cols2Input"
                                        onClick={async()=>{
                                          if (props.states.openColapseChild[0]==false) {
                                            props.openedChild(props.states.openColapseChild[0],0)
                                          }
                                          setValueData('load')
                                          setDateSatelite(val[0])
                                          var get_date = await getDataSatelital(props.data_f_satelite.code_satelital,val[0])
                                          setValueData(get_date)
                                        }}
                                      >
                                      {val[0]}
                                      </label>
                                   </Col>
                                    </>
                                  })}
                    </Row>
                    {(valueData=='load')?(<label
                                        className="form-control-label mb-0 mt-1"
                                        htmlFor="example3cols2Input">
                                        Cargando...
                                      </label>):(<>
                                    <Collapse isOpen={props.states.openColapseChild[0]}>


{/*                                  <select 
                                  value={colorPallet}
                                  onChange={e => {setColorPallet(e.target.value)}}
                                        >
                                    <option value="mycolorspectral">Rampa de colores</option>
                                    <option value="inferno">Inferno</option>
                                    <option value="magma">Magma</option>
                                    <option value="plasma">Plasma</option>
                                    <option value="turbo">Turbo</option>
                                    <option value="viridis">Viridis</option>
                                  </select>*/}
                                     <div className="contentForPDF">
                                      <div style={{textAlign: 'center'}}>
                                        <label
                                        className="form-control-label mb-0 mt-1"
                                        htmlFor="example3cols2Input">
                                        {dateSatelite}
                                      </label>
                                      </div>

                                     <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
                                        <thead className="thead-light">
                                          <tr>
                                            <th style={{border: '1px solid',padding: '3px',width: '265px'}}>Ínidice del Cultivo</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDVI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDRE</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDMI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>HR Suelo</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {getElementTr(valueData,colorPallet)}
                                        </tbody>
                                      </table>
                                     <div style={{color: 'black'}}>
                                       <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
                                     </div>
                                     </div>
                                     <Button 
                                       className="btn-icon btnDPrint" 
                                       color="traza-blue" 
                                       type="button" 
                                       onClick={()=>{ printer_pdf(dateSatelite) } }>
                                       <span className="btn-inner--icon mr-1">
                                         <i className="fas fa-file-pdf" />
                                       </span>
                                       <span className="btn-inner--text">Guardar en PDF</span>
                                     </Button>
                                    </Collapse>
                                      </>)}
{/*                    Object.entries(props.data_f_satelite.data_satelital).map((val,index)=>{
                                   return <>
                                    <Collapse isOpen={props.states.openColapseChild[index]}>
                                     <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
                                        <thead className="thead-light">
                                          <tr>
                                            <th style={{border: '1px solid',padding: '3px',width: '265px'}}>Ínidice del Cultivo</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDVI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDRE</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>NDMI</th>
                                            <th style={{border: '1px solid',padding: '3px'}}>HR Suelo</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {getElementTr(val[1],index)}
                                        </tbody>
                                      </table>
                                     <div style={{color: 'black'}}>
                                       <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
                                     </div>
                                    </Collapse>
                                    </>
                                  })
*/}
                                  </>
                    )
                  )
                )
              }
          </CardBody>
        </Card>
        return(data_content)
//         var data_content=[]
//          data_content = 
//         <Col md="12" style={{margin: 'auto',padding: '0px'}}>
//         <Card>
//         <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
//         <Row>
//         <Col md={1}>
//         <h4 className="mb-0" style={{color: '#ffffff'}}>Estado</h4>
//         </Col>
// {/*        <div>
//         <h4 className="mb-0" style={{color: '#ffffff'}}>{PutContentStatus(props.data_function.allDataTrza,props.first_data_table).register_status}</h4>
//         </div>*/}
//         </Row>
//         </CardHeader>
//         <CardBody>
//               <div style={{color: 'black',textAlign: 'center'}}>
//                 <h3 style={{color: 'black',margin:'0px'}}>
//                 Reporte Satelital del Cultivo
//                 </h3>
//                 <span style={{fontSize: '13px'}}>Finca identificada como </span><br/>
//                 <span style={{fontSize: '13px'}}>Proyecto IICA-BidLab. Plataforma Satelital Yttrium.</span>
//               </div>
//         <Row>
//          <table className="align-items-center table-flush" responsive style={{border: '1px solid',width: '100%',color: 'black', fontSize: '13px'}}>
//             <thead className="thead-light">
//               <tr>
//                 <th style={{border: '1px solid',padding: '3px'}}>Ínidice del Cultivo</th>
//               {props.data_table.map((val,inx)=>{
//                 return <th style={{border: '1px solid',padding: '3px', textAlign:'center'}}>{val.title}</th>
//               })}
//               </tr>
//             </thead>
//             <tbody>
//             {getContentSelectTr(props)}
//             </tbody>
//           </table>
//         </Row>
//               <div style={{color: 'black'}}>
//                 <span style={{fontSize: '12px'}}>* C: Crecimiento. M: Maduración.</span><br/>
//               </div>
//         </CardBody>
//         </Card>
//         </Col>

//           return(data_content)
}
export function PutContentSubSunTree(props) {
 var contenido = []
         if (props.data_function.length!=0) {
            props.data_function.map((value,index)=>{
              if (value==undefined) {
         contenido[index] = <li key={index}>
                                    <div className="elementor-toggle-item">
                                        <h6 onClick={()=>{props.openedNextChild(props.states.openColapseNextChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                                <div className="elementor-toggle-title">{props.titleType}</div>

                                        </h6>
                                        {/*style={{width: '280%',position: 'relative',right: '115%'}}*/}
                                      <Collapse isOpen={props.states.openColapseNextChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                      <ul>
                                      <Col md="12" style={{margin: 'auto'}}>
                                                    <Card>
                                                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                      <Row>
                                                        <Col md={9}>
                                                          {/*<h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>*/}
                                                        </Col>
                                                        <Col md={3}>
                                                          {/*<h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>*/}
                                                        </Col>
                                                      </Row>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Row>
    <Col md={4} style={{padding:0}}><label style={{fontWeight: 600,margin: 0}}>Sin datos</label></Col>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </Col>
                                      </ul>
                                      </Collapse>
                                    </div>
                                  </li>
              }else{
         contenido[index] = <li key={index}>
                                    <div className="elementor-toggle-item">
                                        <h6 onClick={()=>{props.openedNextChild(props.states.openColapseNextChild[props.chillSelector+index],props.chillSelector+index)}} id="elementor-tab-title-8621" className="elementor-tab-title" data-tab={props.chillSelector+index} role="tab" aria-controls="elementor-tab-content-8621" aria-expanded="false">
                                                <div className="elementor-toggle-title">{props.titleType}</div>

                                        </h6>
                                        {/*style={{width: '280%',position: 'relative',right: '115%'}}*/}
                                      <Collapse isOpen={props.states.openColapseNextChild[props.chillSelector+index]} id="elementor-tab-content-8621" className="elementor-clearfix" data-tab={props.chillSelector+index} role="tabpanel" aria-labelledby="elementor-tab-title-8621">
                                      <ul>
                                      <Col md="12" style={{margin: 'auto'}}>
                                                    <Card>
                                                      <CardHeader style={{padding: '10px',backgroundColor: 'rgb(122, 193, 66)'}}>
                                                      <Row>
                                                        <Col md={9}>
                                                          <h4 className="mb-0" style={{color: '#ffffff'}}>Status</h4>
                                                        </Col>
                                                        <Col md={3}>
                                                          <h4 className="mb-0" style={{color: '#ffffff'}}>{value.status}</h4>
                                                        </Col>
                                                      </Row>
                                                      </CardHeader>
                                                      <CardBody>
                                                        <Row>
                                                       <PutContentDesglos
                                                        data_function={[value]} 
                                                        type_function={props.type_function} 
                                                        data_function_label={props.data_function_label}/>
                                                        </Row>
                                                      </CardBody>
                                                    </Card>
                                                  </Col>
                                      </ul>
                                      </Collapse>
                                    </div>
                                  </li>
              }
            })
         }
         return (contenido)
}
export function PutContentDesglos(props) {
var contenido = []
if (props.data_function!=undefined) {
        if (props.data_function.length!=0) {
           props.data_function.map((value,index)=>{
            var get_select =putForSelect(Object.entries(value))
            Object.entries(value).map((a,b)=>{

              if (a[0]!='status' && a[0]!= 'info_mrv' && a[0]!='id' && a[0]!='register_link_id' && a[0]!='trazabilidad_id' && a[0]!='user_id' && a[0]!='codigo' && a[0]!='token_id' && a[0]!='trazabilidade_ida' && a[0]!='created_at' && a[0]!='updated_at') {
                if (a[0].indexOf('select_')==-1) {
                  if (a[1]!=null && a[1]!='') {
                    if (a[0].indexOf('file_')!=-1) {
                      var file_data = a[1]
                      if (file_data!=null) {
                        var val_file_data = file_data.split('/')
                        contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {val_file_data[1]+get_select[a[0]]}</Col></>
                      }
                    }else{
                      if (String(a[1]).indexOf('|')!=-1) {
                        contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> 
                        {a[1].split('|').map((valOne,indOne)=>{
                          return <div>{valOne}</div>
                        })}
                        </Col></>
                      }else{
                        if (a[0]!='field_duplicate') {
                          contenido[b] = <><Col key={b} md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {a[1]+get_select[a[0]]}</Col></>
                        }else{
                          contenido[b] = setContentDuplicate(a[1],props.data_function_label.split('|'),get_select)
                        }
                      }
                    }
                  }
                }
              }
              if (a[0]=='id') {
                contenido[b] = <><Col key={b-1} style={{display:'none'}} className={"get_info_register_"+props.type_function} id-register={a[1]}>{a[1]}</Col></>
              }

              // if (a[0]!='status' && a[0]!= 'info_mrv' && a[0]!='id' && a[0]!='register_link_id' && a[0]!='trazabilidad_id' && a[0]!='user_id' && a[0]!='codigo' && a[0]!='token_id' && a[0]!='trazabilidade_ida' && a[0]!='created_at' && a[0]!='updated_at') {
              //  if (a[1]!=null && a[1]!='') {
              //   if (a[0].indexOf('file_')!=-1) {
              //     var file_data = a[1]
              //     if (file_data!=null) {
              //       var val_file_data = file_data.split('/')
              //       contenido[b] = <><Col md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> {val_file_data[1]}</Col></>
              //     }
              //   }else{
              //     if (a[0]!='field_duplicate') {
              //       contenido[b] = <><Col md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(props.data_function_label.split('|'),a[0])}:</label><br /> { a[1]+get_select[a[0]] }</Col></>
              //     }else{
              //       contenido[b] = setContentDuplicate(a[1],props.data_function_label.split('|'))
              //     }
              //   }
              //  }
              // }

            })
           })
        }
}
        return (contenido)
}
const setContentDuplicate=(data,data_function_label)=>{
  var contenido = []
  var contenidoTwo = []
  if (data!=null && data!='') {
    var dataJson = JSON.parse(data)
    dataJson.map((a,b)=>{
      var get_select =putForSelect(Object.entries(a))
      var getContentCol = Object.entries(a).map((ad,bd)=>{
        if (ad[0].indexOf('select_')==-1) {
          if (ad[1]!='' && ad[1]!=null) {
            return <><Col md={4} style={{padding:0,fontSize:'12px'}}><label style={{fontWeight: 600,margin: 0}}>{putTitle(data_function_label,ad[0])}:</label><br /> { ad[1]+get_select[ad[0]] }</Col></>
          }
        }
      })
      contenidoTwo[b]=<><br /><label style={{fontWeight: 600,margin: 0, fontSize: '12px'}}>Producto aplicado {b+1}</label><hr/><Row>{getContentCol}</Row></>
    })
  }
  return <><Col md={12}>{contenidoTwo}</Col></>
}
export function PutContentFile(props) {
               // (this.props.DataRow.fruit_vegetable==null)
               //          ?
               //          'Sin datos'
               //          :
               //        [this.props.DataRow.fruit_vegetable].map((value,index)=>{
               //        if(value==null){
               //        var name_file = "Sin archivo";
               //        }else{
               //        if (value.archivo==null) {
               //        var name_file = "-";
               //        }else{
               //        var name_file = String(value.archivo).split('/').pop();
               //        }
                      
               //        }
               //   return ((value==null)?('-'):((value.archivo==null)?('-'):(
               //    )))
               //  })
               var contenido = []
               if (props.data_function.allDataTrza[props.type_function]!=undefined) {
          if (props.data_function.allDataTrza[props.type_function]['data_array'].length!=0) {
                    props.data_function.allDataTrza[props.type_function]['data_array'].map((value,index)=>{
                      Object.entries(value).map((a,b)=>{
                        if (a[0].indexOf('file_')!=-1) {
                              contenido[b] = <>
            <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-c7ffd8c elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c7ffd8c" data-element_type="widget" data-widget_type="icon-box.default">
            <div className="elementor-widget-container">
                              <div className="elementor-icon-box-wrapper">
                                 <div className="elementor-icon-box-icon">
                                 <span className="elementor-icon elementor-animation-">
                                 <i aria-hidden="true" className="fas fa-clipboard-list"></i>        
                                 </span>
                                 </div>
                                 <a target="_blank" href={config.URL_PRODUCTION+"/storage/file_documents/"+a[1]} className="elementor-icon-box-content">
                                 <h3 className="elementor-icon-box-title">
                                 <span>
                                 {a[1]}         
                                 </span>
                                 </h3>
                                 </a>
                               </div>
            </div>
            </div>
            </div>
                               </>
                        }
                      })
                    })
                 }
               }
               if (contenido.length==0) {
                return 'Sin archivo'
               }else{
                return (contenido)
               }
}

const putTitle = (data,title) => {
  var result = ''
  data.map((a,b)=>{
    var the_same = a.replaceAll('ñ','n').normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(/[^a-zA-Z]+/g, '_').replaceAll(' ','_').replaceAll('/','_').toLowerCase()
      if (title.indexOf(the_same)!=-1) {
        result = a
      }
  })
      return result
}