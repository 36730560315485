import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { NavLink as ComponentLink } from "react-router-dom";
import { data_obv_uservr, save_report_mrv_signature, get_user_mrv,save_signature_mrv,get_permission } from "network/ApiAxios";
import {GuideMessage} from "views/pages/components/GuideMessage";
import {UserContext} from "layouts/store";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config";
import SignatureCanvas from 'react-signature-canvas';
import 'views/pages/examples/Production/style.css';
import VistaTraceNGMRV from "views/pages/examples/Production/VistaTraceNamaGViewMRV";
import SidebarCustom from "views/pages/forms/Production/SidebarCustom.js";

class DetailsObservationsRev extends React.Component {
  static contextType = UserContext
  state = {
    data_information:[],
    user_type:null,
    //codeget: this.props.code,
    codeget:new URLSearchParams(window.location.search).get("code"),
    collapse:{['collapse'+0]:false},
    sendOpenModal:false,
    sendOpenModalFormMrv:false,
    get_data_user_mrv:null,
    draw_digital_signature:null,
    system_digital_signature:null,
    sigDrawn:false,
    asigSigDrawn:false,
    btnd:false,
    routeget:null,
    routegetThree:null,
    table_params:null,
    title_params:null
  }
  async componentDidMount(){
    //var userdata = localStorage.getItem('user');
    //var jsondata = JSON.parse(userdata);
    this.putData()
  }
  componentDidUpdate(prevS,prevP){
  }
  putData = async (data,ind_two)=>{
    var getDb = new URLSearchParams(window.location.search).get("db");
    var getMrv = new URLSearchParams(window.location.search).get("mrv");
    var getCode = this.state.codeget;
    if (this.state.codeget==null) {
      getCode=this.props.code
    }
    var get_data_obv = await data_obv_uservr(getCode,getDb,getMrv)
     var get_data = get_data_obv.data
     if (get_data!='') {
       this.setState({data_information:get_data})
     }
     var userdata = localStorage.getItem('user');
     var jsondata = JSON.parse(userdata);
     this.setState({user_type:jsondata.userType})
  }
  putContentInformation =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black'}}>{a}</Col></>
    })
    return data_content
  }
  putContentInformationPrio =(data,ind_two)=>{
    var data_content=[]
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = <><Col md={12} style={{color: 'black',borderRadius: '3px',textAlign: 'center', margin: '1px', backgroundColor: a.split('-')[1]}}>{a.split('-')[0]}</Col></>
    })
    return data_content
  }
  putContentInformationStatus =(data,ind_two,ind_three,ind_general,activitySignature,activitySignatureDraw,dataGeneral,data_for_filter)=>{
    var data_content=[]
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
    var getMrv = new URLSearchParams(window.location.search).get("mrv");
    Object.values(data).map((a,b)=>{
      data_content[b+ind_two] = 
      <>
        <Col md={12} style={{color: 'black',borderRadius: '3px', margin: '1px', backgroundColor: a.split('-')[1]}}>
          {(a.split('-')[0]=='Revisar')?(
            (dataGeneral.monitor_type==jsondata.type_traza)?(
                  <button className="btn" style={{padding:0}} onClick={()=>{this.setInfoMrvValidation(activitySignature[b],activitySignatureDraw[b],dataGeneral.mvr_id,0,'sin',data_for_filter,ind_general,b)}}>Esperando por validación</button>
              ):('Esperando por validación')
            ):(a.split('-')[0])}
          {(a.split('-')[0]=='Realizado Conforme')?(
              (activitySignature[b]=='none')?(
                (this.state.user_type==4)?(
                  (dataGeneral.monitor_type==jsondata.type_traza)?(
                      <button className="btn" style={{padding:0}} onClick={()=>{this.setInfoSignature(activitySignature[b],activitySignatureDraw[b],dataGeneral.mvr_id,0,'sin',data_for_filter,ind_general,b)}}>-Sin firma</button>
                  ):('-Sin firma')
                ):('-Sin firma')
              ):(
              (activitySignature[b]==undefined)?(
                (this.state.user_type==4)?(
                    (dataGeneral.monitor_type==jsondata.type_traza)?(
                      <button className="btn" style={{padding:0}} onClick={()=>{this.setInfoSignature(activitySignature[b],activitySignatureDraw[b],dataGeneral.mvr_id,0,'sin',data_for_filter,ind_general,b)}}>-Sin firma</button>
                    ):('-Sin firma')
                ):('-Sin firma')
              ):(<>
                  <button className="btn" style={{padding:0}} onClick={()=>{this.setInfoSignature(activitySignature[b],activitySignatureDraw[b],dataGeneral.mvr_id,1,'con')}}>-Ver firma</button>
              </>)
            )
            ):(null)}
        </Col>
{/*        <Col md={12} style={{color: 'black',borderRadius: '3px', margin: '1px', backgroundColor: a.split('-')[1]}}>
          {a.split('-')[0]}
        </Col>*/}
      </>
    })
    return data_content
  }
  setInfoSignature=async(signature_digital,signature_draw,mvr_id,type_user,type_obs,data_for_filter,indx_one,indx_two)=>{
    var get_infor_mrv = await get_user_mrv(mvr_id)
    if (type_user==0) {
      if (get_infor_mrv.asigned_signature==1) {
        this.setState({asigSigDrawn:true})
        if (get_infor_mrv.draw_digital_signature==null) {
          this.setState({sigDrawn:true})
        }
      }else{
        this.setState({asigSigDrawn:false})
      }
      //indx_one
      this.setState({indx_one:indx_one,indx_two:indx_two,state_data_for_filter:data_for_filter,type_obs:type_obs,sendOpenModal:!this.state.sendOpenModal,get_data_user_mrv:get_infor_mrv,draw_digital_signature:signature_draw,system_digital_signature:signature_digital})
    }else{
      this.setState({type_obs:type_obs,sendOpenModal:!this.state.sendOpenModal,get_data_user_mrv:get_infor_mrv,draw_digital_signature:signature_draw,system_digital_signature:signature_digital})
    }
  }
  setInfoMrvValidation=async(signature_digital,signature_draw,mvr_id,type_user,type_obs,data_for_filter,indx_one,indx_two)=>{
       var getParam = this.props.code;
       var getParamDb = new URLSearchParams(window.location.search).get("db");
      this.setState({sendOpenModalFormMrv:!this.state.sendOpenModalFormMrv,routeget:getParam,routegetThree:getParamDb,table_params:data_for_filter?.datastate['activity_table'][0],title_params:data_for_filter?.datastate['activity'][0]})
  }  
  getContetnTwo =(data,ind_two,ind_general,codigo,dataGeneral,data_for_filter)=>{

                                       var data_content_td=[]
                                       var data_content_tr=[]
                                       var activitySignature = []
                                       var activitySignatureDraw = []
                                       var order_data = Object.entries(data).sort()
                                               order_data.map((valdata,inddata)=>{
                                              if (valdata[0]!='activity') {
                                                if (valdata[0]=='signature_digital') {
                                                  activitySignature=valdata[1]
                                                }
                                                if (valdata[0]=='signature_draw') {
                                                  activitySignatureDraw=valdata[1]
                                                }
                                                if (valdata[0]=='init_date') {
                                                  data_content_td[0]=<>
                                                    <td style={{fontSize: '12px',padding: 0,width: '8%'}}>
                                                      {this.putContentInformation(valdata[1],inddata)}
                                                    </td>
                                                  </>
                                                }
                                                data_content_td[1]=<><td style={{fontSize: '12px',padding: 0,width: '7%',color:'black'}}>{codigo}</td></>
                                                if (valdata[0]=='observation') {
                                                  data_content_td[2]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                                }
                                               if (valdata[0]=='recommendation') {
                                                  data_content_td[3]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformation(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='priority') {
                                                 data_content_td[4]=<><td style={{fontSize: '12px',padding: 0,width: '13%'}}>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               if (valdata[0]=='state_status') {
                                                 data_content_td[5]=<><td style={{fontSize: '12px',padding: 0,width: '25%'}}>{this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general,activitySignature,activitySignatureDraw,dataGeneral,data_for_filter)}</td></>
                                               }
                                               if (valdata[0]=='motive') {
                                                 data_content_td[6]=<><td style={{fontSize: '12px',padding: 0,width: '20%'}}>{this.putContentInformationPrio(valdata[1],inddata)}</td></>
                                               }
                                               data_content_tr=<tr>{data_content_td}</tr>
                                              }
                                      //           if (valdata[0]!='activity') {
                                      //             if (valdata[0]=='observation') {
                                      //             data_content[0] = <>
                                      //               <Col md={3}>
                                      //                   <Col md={12} style={{color: 'black'}}>
                                      //                     <label className="form-control-label m-0" style={{color: 'black'}}>Observaciones</label>
                                      //                   </Col>
                                      //                   {this.putContentInformation(valdata[1],inddata)}
                                      //               </Col>
                                      //               </>
                                      //             }
                                      //             if (valdata[0]=='recommendation') {
                                      //               data_content[1] = 
                                      //               <><Col md={3}>
                                      //                   <Col md={12} style={{color: 'black'}}>
                                      //                     <label className="form-control-label m-0" style={{color: 'black'}}>Recomendación</label>
                                      //                   </Col>
                                      //                   {this.putContentInformation(valdata[1],inddata)}
                                      //               </Col></>
                                      //             }
                                      //             if (valdata[0]=='priority') {
                                      //               data_content[2] = 
                                      //               <><Col md={3}>
                                      //                   <Col md={12} style={{color: 'black',textAlign: 'center'}}>
                                      //                     <label className="form-control-label m-0" style={{color: 'black'}}>Prioridad</label>
                                      //                   </Col>
                                      //                   {this.putContentInformationPrio(valdata[1],inddata)}
                                      //               </Col></>
                                      //             }
                                      //             if (valdata[0]=='state_status') {
                                      //               data_content[3] = 
                                      //               <><Col md={3}>
                                      //                   <Col md={12} style={{color: 'black'}}>
                                      //                     <label className="form-control-label m-0" style={{color: 'black'}}>Estado de Cumplimiento</label>
                                      //                   </Col>
                                      //                   {this.putContentInformationStatus(valdata[1],inddata,ind_two,ind_general)}
                                      //               </Col></>
                                      //             }
                                      //           }
                                               })
                                                 return (data_content_tr)
  }
  setCounterObs =(data)=>{
var get_data_pr = []
  Object.values(data).map((val,ind)=>{
    get_data_pr[ind] = Object.keys(val).length
  })
    var get_numb_max = Math.max(...get_data_pr)
    return get_numb_max
  }

  getContetnHeader =(data,ind_two)=>{
                                      var data_content=[]
                                      var order_data = Object.entries(data).sort()
                                              order_data.map((valdata,inddata)=>{
                                                if (valdata[0]!='activity') {
                                                  if (valdata[0]=='observation') {
                                                  data_content[inddata+ind_two] = <>
                                                      <label className="form-control-label m-0" style={{color: 'black'}}> - Observaciones: {this.setCounterObs(data)}</label>
                                                    </>
                                                  }
                                                }
                                              })
                                                return (data_content)
  }
  toggleFunction = (index,res) =>{
    this.setState({collapse:{[index]:!this.state.collapse[index]}})
  }
  closeModal=(data)=>{
    this.setState({sendOpenModal:data,type_obs:null,get_data_user_mrv:null,draw_digital_signature:null,system_digital_signature:null})
  }
  closeModalMrv=(data)=>{
    this.setState({sendOpenModalFormMrv:data})
  }
    generateSigDraw=async()=>{
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var getDataUser = this.state.get_data_user_mrv
          getDataUser.system_digital_signature = 'Generando Código'
          var putDataUser = getDataUser
          this.setState({get_data_user_mrv:putDataUser})
          var data_result = await save_signature_mrv(jsondata._id,null,2)
          if (data_result.success) {
            var get_data_user_mrv = await get_permission(jsondata._id)
            this.setState({get_data_user_mrv:get_data_user_mrv[0]})
          }
    }
    saveSignature= async()=>{
         var get_signature=this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          if (get_signature.indexOf('iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC')!=-1) {
            alert('Es Necesario una Firma')
            return
          }
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,get_signature,0)
          if (data_result.success) {
            var get_data_user_mrv = await get_permission(jsondata._id)
            this.setState({get_data_user_mrv:get_data_user_mrv[0]})
            this.setState({sigDrawn:false})
          }
    }
    asignedSigDraw=async(data)=>{
          this.setState({asigSigDrawn:data})
          var userdata = localStorage.getItem('user');
          var jsondata = JSON.parse(userdata);
          var data_result = await save_signature_mrv(jsondata._id,data,1)
    }
    changeSigDraw=(data)=>{
      this.setState({sigDrawn:data})
    }
    saveActivity=async(indx_one,indx_two,dataUser,state_data_for_filter)=>{
      this.setState({btnd:true})
       let items = JSON.parse(this.state.data_information[indx_one]['data_adjunt'])
       let item = items.filter((a,b)=> JSON.stringify(a).indexOf(JSON.stringify(state_data_for_filter))!=-1)
       let itemFirst = item[0]
       var change_siganture_draw = {...itemFirst.datastate.signature_draw,[indx_two]:dataUser.draw_digital_signature}
       var change_siganture_digital = {...itemFirst.datastate.signature_digital,[indx_two]:dataUser.system_digital_signature}
       itemFirst.datastate.signature_draw = change_siganture_draw
       itemFirst.datastate.signature_digital = change_siganture_digital
       item[0] = itemFirst;
       let itemsTwo = this.state.data_information;
       let itemTwo = itemsTwo[indx_one];
       itemTwo.data_adjunt = JSON.stringify(items)
       itemsTwo[indx_one]=itemTwo
       var getParam = this.props.code;
       var getParamDb = new URLSearchParams(window.location.search).get("db");
       var getParamType = new URLSearchParams(window.location.search).get("mrv");
       await save_report_mrv_signature(JSON.stringify(items),itemTwo.id,itemTwo.mvr_id,getParam,getParamDb,getParamType)
       this.setState({btnd:false})
       this.setState({sendOpenModal:false,type_obs:null,get_data_user_mrv:null,draw_digital_signature:null,system_digital_signature:null})
       this.putData()
    }
render(){
    const content_signature_mrv = (asigSigDrawn,sigDrawn,get_data_user_mrv,type_content)=>{
      var content = null
      if (type_content=="cdwr") {
        if (asigSigDrawn) {
          if (sigDrawn) {
                   content=<><div style={{border: '1px solid #dee2e6',width:"380px",height:"200px",margin:'auto',marginBottom:'40px'}}>
                                    <SignatureCanvas 
                                    penColor='black' 
                                    canvasProps={{width: 380, height: 200, className: 'sigCanvas'}}
                                    ref={(ref) => { this.sigPad = ref }}
                                    />
                                    <button className="btn" onClick={()=>{this.saveSignature()}}>Guardar Firma</button>
                                </div></>
          }else{
            if (get_data_user_mrv?.draw_digital_signature!=null) {
                                   content = <><div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
                                      <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                                        <img 
                                          style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                                          src={get_data_user_mrv?.draw_digital_signature} 
                                          />
                                      </div>
                                        <br />
                                    </div>
                                    <div style={{width:"380px",margin:'auto'}}>
                                      <button className="btn" onClick={()=>{this.changeSigDraw(!sigDrawn)}}>Cambiar Firma</button>
                                    </div></>
            }
          }
        }
      }
      if (type_content=="ctwogbtn") {
          content=<><div style={{textAlign:'center'}}>
                <label className="form-control-label">Asignar dibujo firma</label> <input type="checkbox" onChange={()=>{this.asignedSigDraw(!asigSigDrawn)}} checked={asigSigDrawn}/>
              </div>
              <div style={{textAlign:'center'}}>
                {(get_data_user_mrv?.system_digital_signature==null)?(<>
                        <div style={{width:"380px",margin:'auto'}}>
                          <button className="btn" onClick={()=>{this.generateSigDraw()}}>Generar firma digital (Genere la firma digital para guardar)</button>
                        </div>
                  </>):(<>
                      <h5>{get_data_user_mrv?.system_digital_signature}</h5>
                  </>)}
              </div></>
      }
      if (type_content=="csaveinfo") {
        if (get_data_user_mrv?.system_digital_signature!=null) {
          if (get_data_user_mrv?.system_digital_signature!='Generando Código') {
            content=<><Button 
              className="btn-icon" 
              color="traza-blue" 
              type="button"
              disabled={this.state.btnd}
              onClick={()=>{this.saveActivity(this.state.indx_one,this.state.indx_two,get_data_user_mrv,this.state.state_data_for_filter)}}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-check-circle" />
                </span>
                <span className="btn-inner--text">Guardar</span>
              </Button></>
          }
        }
      }
      return content
    }
    const content_signature_normal=(sigDrawn,draw_digital_signature,system_digital_signature,type_content)=>{
      var content = null
      if (type_content=="cdwr") {
        if (!sigDrawn) {
          if (draw_digital_signature!='none' || draw_digital_signature!=null) {
            content=<><div style={{border: '1px solid #dee2e6',width:"380px",height:"auto",margin:'auto'}}>
              <div style={{display:'flex',justifyContent: 'center',alignItems: 'center'}}>
                <img 
                  style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                  src={draw_digital_signature} 
                  />
              </div>
                <br />
            </div></>
          }
        }
      }
      if (type_content=='ctwogbtn') {
              content=<><div style={{textAlign:'center'}}>
                <h5>{system_digital_signature}</h5>
              </div></>
      }
      return content
    }
    var userdata = this.context.valueGlobal;
  return (
    <>
      <SidebarCustom 
          onVisible={this.state.sendOpenModal} 
          onHide={() => this.closeModal(!this.state.sendOpenModal) } 
          onWidth={ 650 }
          position="right" 
          title={'Firma'}>

        <ModalBody>
            {(this.state.user_type==4)?(
              (this.state.type_obs=='con')?(
                  content_signature_normal(this.state.sigDrawn,this.state.draw_digital_signature,this.state.system_digital_signature,'cdwr')
                ):(
                  content_signature_mrv(this.state.asigSigDrawn,this.state.sigDrawn,this.state.get_data_user_mrv,'cdwr')
                )
              ):(
              content_signature_normal(this.state.sigDrawn,this.state.draw_digital_signature,this.state.system_digital_signature,'cdwr')
            )}
            {(this.state.user_type==4)?(
                (this.state.type_obs=='con')?(
                  content_signature_normal(this.state.sigDrawn,this.state.draw_digital_signature,this.state.system_digital_signature,'ctwogbtn')
                ):(
                  content_signature_mrv(this.state.asigSigDrawn,this.state.sigDrawn,this.state.get_data_user_mrv,'ctwogbtn')
                )
              ):(
              content_signature_normal(this.state.sigDrawn,this.state.draw_digital_signature,this.state.system_digital_signature,'ctwogbtn')
            )}
        <hr />

          <div style={{textAlign:'center'}}>
            <h5>{this.state.get_data_user_mrv?.email}</h5>
            <h5>Cédula N# {this.state.get_data_user_mrv?.dni}</h5>
          </div>

        </ModalBody>
        <ModalFooter>
        {(this.state.user_type==4)?(
          (this.state.type_obs=='con')?(null):(
            content_signature_mrv(this.state.asigSigDrawn,this.state.sigDrawn,this.state.get_data_user_mrv,'csaveinfo')
          )
        ):(null)}
        </ModalFooter>

      </SidebarCustom>

  <Modal isOpen={this.state.sendOpenModalFormMrv} size="xl">
  <ModalHeader>
    <h5>Validación de información</h5>
  </ModalHeader>
        <ModalBody>

          <VistaTraceNGMRV 
          {...this.props} 
          code={this.state.routeget} 
          db_user={this.state.routegetThree} 
          table_params={this.state.table_params}
          title_params={this.state.title_params}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={()=> {this.closeModalMrv(!this.state.sendOpenModalFormMrv)} }>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


      {/*<SimpleHeader name="Vista Observaciones" parentName="Vista Observaciones" section="Vista Observaciones" section_two="Tabla" section_table_form="table_view_obvmvr" />*/}
        <Container className="mt-2" fluid>
                  <div className="card" role="alert" >
                  {(this.state.data_information.length==0)?(
                        <Col md={12} className="card mb-2" style={{padding: '1px',backgroundColor: 'white', textAlign:'center'}}>
                            <Col md={12}>
                              <Row>
                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th>Observaciones</th>
                                    <th>Recomendación</th>
                                    <th>Prioridad</th>
                                    <th>Estado de Cumplimiento</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <td>
                                  Sin Observaciones
                                  </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Row>
                          </Col>
                        </Col>
                    ):(
                                      this.state.data_information.map((val,indx)=>{
                                        return (<>
                                                  <Col md={12} style={{fontWeight: '700',color: '#002a5c'}}>
                                                    {(val.monitor_type=='ON')?('Monitoreo'):(null)}
                                                    {(val.monitor_type=='RE')?('Revisión'):(null)}
                                                    {(val.monitor_type=='VA')?('Validación'):(null)}
                                                  </Col>
                                                  {
                                                    (val.data_adjunt!=null)?(
                                                    JSON.parse(val.data_adjunt).map((valadjun,ind)=>{
                                                      return (<>
                                                                    <Col md={12} className="card mb-2" style={{padding: '1px',backgroundColor: 'white', textAlign:'center'}}>
                                                                        <label className="form-control-label m-0" style={{color: 'black'}}>Titulo/Actividad: {' '}
                                                                        {(valadjun.value!='general')?(valadjun.name):((valadjun.datastate.activity==undefined)?(null):(valadjun.datastate.activity[0]))}
                                                                        </label>
                                                                        <Col md={12}>
                                                                          <Row>
                                                                          <table className="align-items-center table-bordered" style={{margin: 0,width: '100%'}} responsive>
                                                                            <thead className="thead-light">
                                                                              <tr>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>Fecha de Inicio</th>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>ID Foodchain</th>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>Observaciones</th>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>Recomendación</th>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>Prioridad</th>
                                                                                <th style={{fontSize: '12px',padding: 0,color:'black'}}>Estado de Cumplimiento</th>
                                                                                {(JSON.stringify(valadjun.datastate).split('motive').length-1!=0)?(
                                                                                  <th style={{fontSize: '12px',padding: 0,color:'black'}}>Motivo</th>
                                                                                ):(null)}
                                                                              </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                              {this.getContetnTwo(valadjun.datastate,ind,indx,val.codigo,val,valadjun)}
                                                                            </tbody>
                                                                          </table>
                                                                        </Row>
                                                                      </Col>
                                                                    </Col>
                  
                                                          </>)
                                                    })
                                                    ):(null)
                                                }
                                                  {(val.monitor_type=='VA')?(<>
                                                    <Col md={12}>
                                                      <Row style={{justifyContent: 'center'}}>
                                                        <Col md={4}>
                                                        <label className="form-control-label">Firma:</label><br />
                                                          <img 
                                                          style={{ backgroundSize: '200px 50px',width: 'auto',height: 'auto',backgroundColor: 'white'}} 
                                                          src={val.signature} 
                                                          />
                                                        </Col>
                                                        <Col md={4}>
                                                          <label className="form-control-label">Firma digital:</label><br />
                                                        <span className="alert-title" data-notify="title">{val.nro_autorization}</span>
                                                        </Col>
{/*                                                        <Col md={4}>
                                                        <label className="form-control-label">Documento:</label><br />
                                                        {(val.document==null) ? "Sin archivo": (<Button style={{background: 'none', boxShadow: 'none', border: 'none',display:'contents'}} onClick={()=> {window.open(config.URL_PRODUCTION+"/storage/file_documents/"+val.document)}}>{String(val.document).split('/')[1]?.split('.')[1]}</Button>)}
                                                        </Col>*/}
                                                      </Row>
                                                    </Col>
                                                    </>):(null)}
                                                  </>)
                                      })
                                    )}
                  </div>
      </Container>
    </>
  );
 }
}

DetailsObservationsRev.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default DetailsObservationsRev;
