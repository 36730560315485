import {useEffect, useRef, useState, useContext} from "react";
import { Container, Row, Col } from "reactstrap";
import AWS from 'aws-sdk';
import {message_assistant} from "network/ApiAxios";
import './style_assistant.css'
import {UserContext} from "layouts/store";
import { useLocation } from 'react-router-dom'
//import * as faceapi from 'face-api.js';
//import { TranscribeService } from "aws-sdk/clients";
const imgLips = '/layout/images/assistant_img/lips_visame.jpeg'
//const imgLips = '/layout/images/assistant_img/lips_animated.png'
var imgBot = "/layout/images/assistant_img/4-waving-with-a-hand.png";
const credentials = {
  "accessKeyId": process.env.REACT_APP_CLIENTID,
  "secretAccessKey": process.env.REACT_APP_SECRETKEY,
};
AWS.config.update({
    accessKeyId: process.env.REACT_APP_CLIENTID,
    secretAccessKey: process.env.REACT_APP_SECRETKEY,
    region: process.env.REACT_APP_REGION
})
const polly = new AWS.Polly();
//const imgBot = "/assets/img/assistant_img/25-waving-for-hello-with-a-smiling-face.png";
function Assistant() {
  const { valueAssistant, valueAssistantForm  } = useContext(UserContext);
  const audioRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  const [dataText, setDataText] = useState([])
  const [imgData, setImgData] = useState(null)
  const [dataMessageTwo, setDataMessageTwo] = useState([])
  var dataMessage = []
  var dataMessageForm = []
  const [dataTrackPosition, setDataTrackPosition] = useState([0,0])
  const [dataTrackPositionMouth, setDataTrackPositionMouth] = useState([0,0])
  
  const [dataTrackPositionForm, setDataTrackPositionForm] = useState([0,0])
  const [controlShow, setControlShow] = useState(0)
  const [controlShowMain, setControlShowMain] = useState(0)
  const [pass, setPass] = useState(0)
  const [changeType, setChangeType] = useState('initAssistant')
  const [positionMouth, setPositionMouth] = useState({})
  const [reproType, setReproType] = useState(1)
  const [earAudio, setEarAudio] = useState(1)
  const [viewCanvas, setViewCanvas] = useState(true)
  const [timeInterval, setTimeInterval] = useState(null)
  const [passOneTrck, setPassOneTrck] = useState(0)
  const history = useLocation();
    useEffect(async() => {
      var getSessionEar = localStorage.getItem("earAudio")
      if (getSessionEar!=null) {
        setEarAudio(JSON.parse(getSessionEar))
      }
      if (valueAssistant!=undefined) {
        if (Object.values(valueAssistant).length!=0) {
          if (window.$('[id*=insideModal]').length==0) {
            initAssistant()
          }
        }
      }
    },[valueAssistant]);

    useEffect(() => {
        if (Object.values(valueAssistant).length!=0) {
          if (Object.values(valueAssistant.mess_data).length!=0) {          
            if (window.$('[id*=insideModal]').length==0) {
              initAssistant()
            }
          }
        }
        return ()=>{
          setIsPlaying(false)
        }
    },[history.pathname]);

    useEffect(() => {
      const div = document.getElementById('clickBot');
      if (window.$('[id*=insideModal]').length!=0) {
        pauseAudio()
        div.style.right = '660px';
        var getLent=window.$('[id*=insideModal]').length
        var getSplit = window.$(window.$('[id*=insideModal]')[getLent-1]).attr('id').split('-')
        var getLent=window.$('[class*=oneModal]').length
        if (getLent!=0) {
          initAssistantModal(getSplit[0],getSplit[1],getSplit[2])
        }
      }else{
        div.style.right = '2%';
        pauseAudio()
      }
    },[window.$('[id*=insideModal]').length]);
    const pauseAudio = () =>{
        const audio = document.getElementById("audioElement")
        if (!audio.paused) {
          setDataTrackPosition([0,0])
          setDataTrackPositionMouth([0,0])
          eraserLips()
          audio.pause()
        }
    }

    window.onclick = async (e) =>{
      if (window.$(e.target).attr('id')!='clickBotOption') {
        if (window.$(e.target).attr('onclick')!=undefined) {
          if (window.$(e.target).attr('onclick').indexOf("clkMessPos")!=-1) {
            return;
          }
        }
          var myTimeout = setTimeout(checkFromNext, 500);
      }
    }
    const checkFromNext =()=>{
            const audioVer = document.getElementById("audioElement")
        // if (!audioVer.paused) {
        //   pauseAudio()
        // }
      if (window.$(".p-component-overlay").length==0) {
        if (changeType == 'initAssistantModal') {
          //pauseAudio()
          initAssistant()
        }
      }else{
        // if (window.$(".p-component-overlay").length==2) {
        // }
        var getLentOneM=window.$('[class*=oneModal]').length
        if (getLentOneM==0) {
          if (window.$('[id*=insideTable]').length!=0 && window.$(".p-component-overlay").length==1) {
            var getLent=window.$('[id*=insideTable]').length
            var getSplit = window.$(window.$('[id*=insideTable]')[getLent-1]).attr('id').split('-')
            initAssistantModal(getSplit[0],getSplit[1],getSplit[2])
          }else{
            if (audioVer.paused) {
              if (window.$('[id*=insideModal]').length!=0 && window.$(".p-component-overlay").length>1) {
                pauseAudio()
                var getLentIM=window.$('[id*=insideModal]').length
                var getSplit = window.$(window.$('[id*=insideModal]')[getLentIM-1]).attr('id').split('-')
                initAssistantModal(getSplit[0],getSplit[1],getSplit[2])
              }
            }

          }
        }
      }

    }
    var setForFrom={}
    window.setAssistantValidate = (valField,type_acc) =>{
      //reproType=2
      //setReproType(2)
      var getLent=window.$('[id*=insideModal]').length
      if (window.$(window.$('[id*=insideModal]')[getLent-1]).attr('id') != undefined) {
        var getSplit = window.$(window.$('[id*=insideModal]')[getLent-1]).attr('id').split('-')
            var setDatAssistant = {}
            Object.entries(valueAssistantForm).map((av,bv)=>{
              setDatAssistant[av[0].toLowerCase()]=av[1]
            })
            var getSplitLower = getSplit[2].toLowerCase()
        if (setDatAssistant[getSplitLower]!=undefined) {
          if (getSplitLower!=undefined) {
            var getTypeData = setDatAssistant[getSplitLower].filter(a => a.element==type_acc)
            dataMessageForm = getTypeData
            setForFrom={type_acc,valField,getTypeData}
            dataTrackPositionForm[1]=0
            contentGlobalMessForm(type_acc,valField,getTypeData)
          }
        }
      }
      //console.log(getTypeData)
    }
    const fPositionInit = (dataArr,exclude,excludeTwo,typeOption) => {
        var getArrDataPosition = []
        if (typeOption=='fNormal') {
        Object.values(dataArr).map((ap,bp)=> {
            var filterDataP = ap.filter((avp)=> avp.element.indexOf("message_init")==-1 && 
                                     avp.element.indexOf("first_field")==-1 && 
                                     avp.element.indexOf("fields_validations")==-1 && 
                                     avp.element.indexOf("save_success")==-1 && 
                                     avp.element.indexOf("save_error")==-1 && 
                                     avp.element.indexOf("global-body")==-1 &&
                                     avp.element.indexOf(exclude)==-1 && 
                                     avp.element.indexOf(excludeTwo)==-1 )
            if (filterDataP.length!=0) {
              getArrDataPosition.push(...filterDataP)
            }
          })
        }

        if (typeOption=='fModal') {
            if (excludeTwo!=undefined || excludeTwo!=null) {
              Object.values(dataArr).map((ap,bp)=> {
                var filterDataP = ap.filter((avp)=> avp.element.indexOf(exclude)!=-1 && avp.element.indexOf(excludeTwo)!=-1 )
                if (filterDataP.length!=0) {
                  getArrDataPosition.push(...filterDataP)
                }
              })
            }else{
              Object.values(dataArr).map((ap,bp)=> {
                var filterDataP = ap.filter((avp)=> avp.element.indexOf(exclude)!=-1 )
                if (filterDataP.length!=0) {
                  getArrDataPosition.push(...filterDataP)
                }
              })
            }
        }
          return getArrDataPosition
    }
  const convertPercentWidthLegal = (dataPc) => {
    var pixels = dataPc;
    //var screenWidth = window.innerWidth
    var screenWidth = document.querySelector("html").offsetWidth;
    //var screenWidth = window.screen.width;
    var percentage = ( screenWidth * pixels ) / 100 ;
    return  percentage+'px'
  }
  const convertPercentHeithLegal = (dataPc) => {
    var pixels = dataPc;
    //var screenWidth =  window.innerHeight
    var screenWidth = document.querySelector("html").offsetHeight;
    //var screenWidth = window.screen.height;
    var percentage = ( screenWidth * pixels ) / 100 ;
    return  percentage+'px'
  }
    const initAssistant = () => {
      setChangeType('initAssistant')
        if (Object.values(valueAssistant.mess_data).length!=0) {
          portionContentMessage(true,fPositionInit(valueAssistant.mess_data,'insideModal','insideTable','fNormal'))
        }

      if (valueAssistant.mess_data[window.location.pathname]!=null || valueAssistant.mess_data[window.location.pathname]!=undefined) {
      if (valueAssistant.mess_data[window.location.pathname].length==0) {
        eraserDatas()
      }else{        
        var getArrData = valueAssistant.mess_data[window.location.pathname].filter((av)=> av.element.indexOf('insideModal')==-1 && av.element.indexOf('insideTable')==-1 )
        var getPostAs = valueAssistant.position_data.find(pf=>pf.title_path==window.location.pathname)
        var setPositionDevice = '_dash'
          if (document.body.offsetWidth<=991) {
            setPositionDevice = '_movile'
            window.$("#clickBot").removeAttr('style')
          }

          // if (getPostAs['resolution_percent'+setPositionDevice]!=null && getPostAs['resolution_percent'+setPositionDevice]!='') {
          //   var jpRes = JSON.parse(getPostAs['resolution_percent'+setPositionDevice])
          //   // var getW = convertPercentWidthLegal(jpRes.resultPercentW)
          //   // var getH = convertPercentHeithLegal(jpRes.resultPercentH)
          //   var getW = (jpRes.resultPercentW)+"%"
          //   var getH = (jpRes.resultPercentH)+"%"
          //   const div = document.getElementById('clickBot');
          //   div.style.right = getW;
          //   div.style.bottom = getH;
          // }
          // var getFG = getArrData.filter(gf => gf.element=='global-body')
          // if (getFG.length!=0) {
          //   getArrData = getFG
          // }
          if (getArrData.length!=0) {
            if (getArrData[0].data!=null) {
              var setData = JSON.parse(getArrData[0].data).reverse()
              const audioVer = document.getElementById("audioElement")
                if (audioVer.paused) {
                  setImgData('https://api.foodchaintrazabilidad.com/developer/foodchain-back/public/storage/file_documents/assistant/'+setData[0].identifier_img+'.png')
                }
                setDataText(setData[0].message)
                setControlShow(setData.length)
                setControlShowMain(getArrData.length)
                setDataMessageTwo(getArrData)
                dataMessage = [...getArrData]
                setDataTrackPositionForm([0,0])
                //portionContentMessage(true,getArrData)
                  if (passOneTrck==1) {
                    setPassOneTrck(0)
                  }
            }
          }
      }
      }else{
        eraserDatas()
      }
    }
    const initAssistantModal = (prefix,table,title_table) => {
      setChangeType('initAssistantModal')
      if (valueAssistant.mess_data[window.location.pathname]!=null || valueAssistant.mess_data[window.location.pathname]!=undefined) {
            //console.log(valueAssistant.mess_data[window.location.pathname])
              var getArrData = valueAssistant.mess_data[window.location.pathname].filter((av)=>av.element.indexOf(prefix)!=-1)
            if (table!=undefined || table!=null) {
              getArrData = valueAssistant.mess_data[window.location.pathname].filter((av)=>av.element.indexOf(prefix)!=-1 && av.element.indexOf(table)!=-1)
              if (valueAssistantForm[title_table]!=undefined) {
                var getDataMI = valueAssistantForm[title_table].find((elem)=> elem.element=="message_init")
                if (getDataMI!=undefined) {                
                  if (getDataMI.data!=null) {
                    getArrData.unshift(getDataMI)
                  }
                }
              }
            }
            if (document.body.offsetWidth<=991) {
                const div = document.getElementById('clickBot');
                div.style.right = '80%';
            }
            // const audioVer = document.getElementById("audioElement")
            // if (!audioVer.paused) {
            //   pauseAudio()
            // }
              if (getArrData.length!=0) {
                if (getArrData[0].data!=null) {
                  var setData = JSON.parse(getArrData[0].data).reverse()
                  setImgData('https://api.foodchaintrazabilidad.com/developer/foodchain-back/public/storage/file_documents/assistant/'+setData[0].identifier_img+'.png')
                  setDataText(setData[0].message)
                  setControlShow(setData.length)
                  setControlShowMain(getArrData.length)
                  setDataMessageTwo(getArrData)
                  dataMessage = [...getArrData]
                  //setDataMessage()
                  //convertTextToSpeech(setData[0].message)
                  //dataTrackPositionForm[0]=dataTrackPositionForm[0]+1
                  //portionContentMessage(true,getArrData)
                  if (passOneTrck==0) {
                      //pauseAudio()
                    setTimeout(function() {
                      window.$('#clickBotOption').click()
                      //logisticsChange(dataMessage)
                    },500)
                    setPassOneTrck(1)
                  }
                  //window.setAssistantValidate(null,'message_init')
                }
              }
      }else{
        eraserDatas()
      }
        if (Object.values(valueAssistant.mess_data).length!=0) {
          portionContentMessage(true,fPositionInit(valueAssistant.mess_data,prefix,table,'fModal'))
        }
    }
    // window.onclick = () =>{
    //   console.log('hola')
    // }
    const eraserDatas = () =>{

          setImgData(null)
          setDataText([])
          setControlShow(0)
          setControlShowMain(0)
          //setDataMessage([])
          dataMessage = []
          setDataTrackPosition([0,0])
          setDataTrackPositionMouth([0,0])

    }
    var dataAudio = null
    var setLiricalText = []
    var currentAudio = null
    const convertTextToSpeech = (nextMess) => {
      var dataTextMain = dataText
      if (nextMess!=undefined) {
        dataTextMain=nextMess
      }
      //var text = 'Saludos'
      var text = dataTextMain
      var paramsSpeech = {
            Engine:'neural',
            LanguageCode:'es-MX',
            OutputFormat: 'mp3',
            Text: text,
            VoiceId: 'Andres'
        }
      var paramsSpeechJson = {
            Engine:'neural',
            LanguageCode:'es-MX',
            OutputFormat: 'json',
            SpeechMarkTypes:['viseme'],
            Text: text,
            VoiceId: 'Andres'
        }
        if (document.getElementById("audioElement")!=null) {
          //currentAudio=document.getElementById("audioElement")
            polly.synthesizeSpeech(paramsSpeechJson, (errorText, dataText) => {
                if (errorText) {
                    console.log(errorText);
                } else {
                   var string = new TextDecoder().decode(dataText.AudioStream);
                   //console.log(string)
                    const dataArr = string.split('\n')
                    var setReleaseData = []
                    dataArr.map((ar,br)=>{
                      if (ar!="") {
                        var setDataJson = JSON.parse(ar)
                        setReleaseData.push(setDataJson)
                      }
                    })
                    if (window.$('[id*=insideModal]').length==0) {
                      changeIfNF(dataTrackPosition[1]>1,setReleaseData,'viseme','onModal')
                    }else{
                      changeIfNF(dataTrackPositionForm[1]>1,setReleaseData,'viseme','onModal')
                    }
                }
            });
          polly.synthesizeSpeech(paramsSpeech, (error, data) => {if (error) {console.log(error);} else {
                    if (window.$('[id*=insideModal]').length==0) {
                      changeIfNF(dataTrackPosition[1]>1,data,'audio','ofModal')
                    }else{
                      changeIfNF(dataTrackPositionForm[1]>1,data,'audio','ofModal')
                    }
            // if (dataTrackPosition[1]>1) {
            //   setTimeout(function() {
            //     setAudioFile(data);
            //   },500)
            // }else{
            //   setAudioFile(data);
            // }

          }});
        }
    }
    const changeIfNF = (trackPosition,dataApiAudio,typeFile,viewModal) =>{
                    if (trackPosition) {
                      setTimeout(function() {
                        if (!isPlaying) {
                          if (typeFile=='viseme') {
                            setLiricalTextF(dataApiAudio)
                          }else{
                            setAudioFile(dataApiAudio);
                          }
                        }
                      },500)
                    }else{
                      if (typeFile=='viseme') {
                        setLiricalTextF(dataApiAudio)
                      }else{
                        setAudioFile(dataApiAudio);
                      }
                    }
    }
    const setAudioFile = async (audioFile) =>{
        var audio = document.getElementById("audioElement");
        // if (audio==null) {
        //   audio=currentAudio
        //   document.getElementById("audioElement") = currentAudio
        // }
          if (audioFile) {
              const audioArrayBuffer = audioFile.AudioStream.buffer;
              const audioURL = URL.createObjectURL(new Blob([audioArrayBuffer], {type: "audio/mpeg"}));
             

              audio.src = audioURL;
              audio.playbackRate=0.97;

              // audio.addEventListener('loadeddata', () => {
              //     setDuration(audio.duration);
              // });

              //audio.addEventListener('timeupdate', updateProgressBar);
              audio.ontimeupdate = () => {updateProgressBar()}
              //audio.addEventListener('loadstart', startAudio);
              audio.addEventListener('ended', () => {
                  setIsPlaying(false); // Set isPlaying to false when audio has ended
              });
              audio.play()
              //setIsPlaying(true)
              //togglePlay()

              return () => {
                  URL.revokeObjectURL(audioURL);
                  //audio.removeEventListener('timeupdate', updateProgressBar);
                  //audio.removeEventListener('loadstart', startAudio);
                  audio.removeEventListener('ended', () => {
                      setIsPlaying(false);
                  });
              };
          }

    }
    const updateProgressBar = () => {
     const audio = document.getElementById("audioElement")
     if (audio!=null) {
       const progress = (audio.currentTime / audio.duration) * 100
       //console.log(audio.currentTime,progress)
       if (Math.ceil(progress)==100) {
          if (audio.ended) {
            if (Object.keys(setForFrom).length!=0) {
              if (window.$('[id*=insideModal]').length!=0) {
                contentGlobalMessForm(setForFrom.type_acc,setForFrom.valField,setForFrom.getTypeData)
                return
              }
            }
            contentGlobalMess(false)
          }
       }
     }
    }
const delayFunc = (ms,data) => {
    //return new Promise((resolve,reject) => setTimeout(resolve,ms));
    //getDataL.time-backTime.time
    var getDataIndex = data.findIndex((word) => parseInt(word.time)==ms );
    var backDataIndex = data[getDataIndex-1]
    if (backDataIndex!=undefined) {
      return ms-backDataIndex.time
    }
}
        var setVerifyTimer = []
        var intervalID = null
    const setLiricalTextF = (setLiricalTextData) => {
        var regex = '([a-zA-Z]|[á-ú]){1,2}'
        var setRegex = new RegExp(regex, 'g');
        const audio = document.getElementById("audioElement")
        var counter = 0
        var startTime = Date.now();
        intervalID = setInterval(function() {
        var elapsedTime = Date.now() - startTime;
        var getDataL = setLiricalTextData.find((word) => parseInt(word.time)>=parseInt(elapsedTime) );
          if (getDataL!=undefined) {
            if (setVerifyTimer.includes(getDataL.time)) {
            }else{
              var setInitTime = null
              if (delayFunc(getDataL.time,setLiricalTextData)==undefined) {
                setInitTime=getDataL.time
              }else{
                setInitTime=delayFunc(getDataL.time,setLiricalTextData)
              }
                //animateLisp("M","")
                if (!isPlaying) {
                  animateLisp(getDataL.value)
                }
            }
              setVerifyTimer.push(getDataL.time)
          }
          if (audio!=null) {
            if (parseInt(elapsedTime)>=parseInt(audio.duration*1000)) {
              setVerifyTimer=[]
              clearInterval(intervalID)
            }
          }else{
            clearInterval(intervalID)
          }
          counter++
        }, 10);
        setTimeInterval(intervalID)
        if (isPlaying) {
          clearInterval(intervalID)
        }
    }

    const togglePlay = async () => {
        const audio = document.getElementById("audioElement")

        if (isPlaying) {
          audio.pause()
        } else {
          audio.play()
        }
        setIsPlaying(!isPlaying)
    }
    const plotRectangle =(el, rect) => {
      var div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.border = '2px solid ' + (rect.color || 'magenta');
      div.style.width = rect.width + 'px';
      div.style.height = rect.height + 'px';
      div.style.left = el.offsetLeft + rect.x + 'px';
      div.style.top = el.offsetTop + rect.y + 'px';
      document.body.appendChild(div);
      return div;
    }

    const moveContent = (element) => {
      if (element!='global-body') {
         //var getElementTarget = window.$(element)[0].getBoundingClientRect()
         var getElementTargetTop = window.$(element).offset().top-50
         var getElementTargetLeft = window.$(element).offset().left-100
        // var getElementTarget = document.querySelector(element).getBoundingClientRect()
        // var getElementTargetTop = document.querySelector(element).offsetTop
        // var getElementTargetLeft = document.querySelector(element).offsetLeft
        const div = document.getElementById('clickBot');
        div.style.position='absolute'
        div.style.top = `${getElementTargetTop}px`;
        div.style.left = `${getElementTargetLeft}px`;
      }
    }

    const contentGlobalMessForm = (type_acc,valField,dataMessageForm) => {
    if (dataMessageForm!=null || dataMessageForm!=undefined) {
      if (dataMessageForm[dataTrackPositionForm[0]]!=undefined) {
        if (dataMessageForm[dataTrackPositionForm[0]].data!='null') {
          if (JSON.parse(dataMessageForm[dataTrackPositionForm[0]].data)!=null) {
              var getData = JSON.parse(dataMessageForm[dataTrackPositionForm[0]].data).reverse()
              var countTotalMess = getData.length
              if (countTotalMess>dataTrackPositionForm[1]) {
                if (getData[dataTrackPositionForm[1]]!=undefined) {
                  //setControlShow(countTotalMess)
                  nextTrackForm(getData[dataTrackPositionForm[1]],type_acc,valField)
                }
              }
            }
          }
        }
      }
    }
    const nextTrackForm = async (setData,type_acc,valField) => {
          var textRegex = '\[[a-z]*\]'
          var regex = new RegExp(textRegex, 'g');
          var setMessage = setData.message
          if (type_acc=='fields_validations') {
            if (valField!=null) {
              setMessage=setData.message.replace(regex,valField)
            }
          }
          setImgData('https://api.foodchaintrazabilidad.com/developer/foodchain-back/public/storage/file_documents/assistant/'+setData.identifier_img+'.png')
          setDataText(setMessage)
          convertTextToSpeech(setMessage)
          //dataTrackPositionForm[1]=0
          //dataTrackPosition[0]=dataTrackPosition[0]+1
          dataTrackPositionForm[1]=dataTrackPositionForm[1]+1
          dataTrackPositionMouth[1]=dataTrackPositionMouth[1]+1

          //togglePlay()
    }
    const contentGlobalMess = (typeMain) => {
      if (typeMain) {
        initAssistant()
      }else{

        if (dataMessage!=null || dataMessage!=undefined) {
          if (dataMessage[dataTrackPosition[0]]!=undefined) {
            if (dataMessage[dataTrackPosition[0]].data!='null') {
              if (dataMessage[dataTrackPosition[0]].data!=null) {
                  var getData = JSON.parse(dataMessage[dataTrackPosition[0]].data).reverse()
                  var countTotalMess = getData.length
                  if (countTotalMess>dataTrackPosition[1]) {
                    if (getData[dataTrackPosition[1]]!=undefined) {
                      //console.log(dataTrackPosition,getData[dataTrackPosition[1]]) nomis.velasquez4@gmail.com
                      nextTrack(getData[dataTrackPosition[1]],getData[dataTrackPosition[1]+1])
                      setControlShow(countTotalMess)
                    }
                  }
                }
              }
            }
          }
      }
    }
    const nextTrack = async (setData,continueData) => {

      setImgData('https://api.foodchaintrazabilidad.com/developer/foodchain-back/public/storage/file_documents/assistant/'+setData.identifier_img+'.png')
      setDataText(setData.message)
      convertTextToSpeech(setData.message)
      dataTrackPosition[1]=dataTrackPosition[1]+1
      dataTrackPositionMouth[1]=dataTrackPositionMouth[1]+1
          //togglePlay()
    }
    const nextContentMessage = () => {
      const audio = document.getElementById("audioElement")
      audio.pause()
      dataTrackPositionMouth[0]=dataTrackPositionMouth[0]+1
      dataTrackPositionMouth[0]=0

      dataTrackPosition[0]=dataTrackPosition[0]+1
      dataTrackPosition[1]=0
      contentGlobalMess(false)
    }
    const restartContentMessage = () => {
        setEarAudio(0)
        localStorage.setItem("earAudio","0")
        const audio = document.getElementById("audioElement")
        const div = document.getElementById('clickBot');
        //div.style.position='fixed'
        setDataTrackPosition([0,0])
        setDataTrackPositionMouth([0,0])
        contentGlobalMess(true)
        setIsPlaying(false)
        window.$("#audioElement").removeAttr('src')

        clearInterval(timeInterval)
        //eraserLips()

        //setViewCanvas(false)
        //window.$('#canvasId').remove()
        audio.pause()
        //setImgData(null)
        //window.location.reload()
    }
    const [dataContent, setDataContent] = useState([])
      //var setDataMessageVar = []
    const portionContentMessage = (checkR,setDataMessageVar) => {

        setDataMessageVar.map((a,b)=>{
          if (a.element!='global-body') {
            if (window.$(a.element)[0]!=undefined) {
              var getElementTargetWid = window.$(a.element)[0].getBoundingClientRect().width
              var getElementTargetHeid = window.$(a.element)[0].getBoundingClientRect().height+5
            }
            if (window.$(a.element).prev().hasClass('cssContentMini')) {
            }else{
              if (window.$(a.element).prev().next().hasClass('cssContentMini')) {
              }else{
                if (window.$('#'+a.identifer).length==0) {
                  window.$(a.element).attr('onclick','clkMessPos("'+a.identifer+'")')
                  if (a.view_mark==1) {
                    if (window.$(a.element).find('.fasExclama').length==0) {
                      window.$(a.element).last().append('<span class="fas fa-question-circle fasExclama" style="margin-left: 10px; color:red; font-size:15px;"></span>')
                    }
                  }

                }
              }
            }
          }
        })
        window.clkMessPos=(identifer)=>{
          if (earAudio==1) {
            if (timeInterval!=null) {
              pauseAudio()
              eraserLips()
            }
            if (intervalID!=null) {
              pauseAudio()
              clearInterval(intervalID)

            }
            if (document.getElementById("audioElement")!=null) {
              setPositionsView(identifer,setDataMessageVar)
            }
          }

        }
    }

    const setPositionsView = (ident,setDataMessageVar) => {
      dataMessage=setDataMessageVar
      var finder_data_indx = setDataMessageVar.findIndex(a=>a.identifer==ident)
      nextSetPositionsView(finder_data_indx)
      //window.$('#'+ident).remove()
    }
    const nextSetPositionsView = (idxId) => {
      dataTrackPosition[0]=idxId
      dataTrackPosition[1]=0

      dataTrackPositionMouth[0]=idxId
      dataTrackPositionMouth[1]=0
      contentGlobalMess(false)
    }
    const logisticsChange=(data,changeMute)=>{
      //animateLisp("T")
      var earAudioPass=earAudio
      if (changeMute!=undefined) {
        earAudioPass=changeMute
      }

      if (earAudioPass==1) {
        if (window.$('[id*=insideModal-]').length!=0) {
          //console.log(window.$('[id*=insideModal]').length)
          window.setAssistantValidate(null,'message_init')
        }else{
          dataMessage=data
          restartContentMessageStart()
        }
      }
    }
    const restartContentMessageStart = () => {
        restartContentMessageFinit()
        contentGlobalMess(false)
    }
    const restartContentMessageFinit = () => {
        const audio = document.getElementById("audioElement")
        const div = document.getElementById('clickBot');
        div.style.position='fixed'
        setDataTrackPosition([0,0])
        setDataTrackPositionMouth([0,0])
        animateLisp("F")
        contentGlobalMess(true)
        setIsPlaying(false)
        audio.pause()
    }
          var img_data = new Image()
          var canvas = document.getElementById("canvasId")
          var lyrics = document.getElementById("lyrics")
          var coordinates = [
                            {letter:"A",x:-602/3*0,y:-582/3*0},
                            {letter:"E",x:-602/3*2,y:-582/3*0},
                            {letter:"I",x:-602/3*2,y:-582/3*0},
                            {letter:"O",x:-602/3*1,y:-582/3*0},
                            {letter:"U",x:-602/3*0,y:-582/3*2},

                            {letter:"B",x:-602/3*1,y:-582/3*2},
                            {letter:"C",x:-602/3*1,y:-582/3*1},
                            {letter:"D",x:-602/3*2,y:-582/3*1},
                            {letter:"F",x:-602/3*2,y:-582/3*2},
                            {letter:"H",x:-602/3*1,y:-582/3*1},
                            {letter:"J",x:-602/3*1,y:-582/3*2},
                            {letter:"K",x:-602/3*0,y:-582/3*0},
                            {letter:"L",x:-602/3*2,y:-582/3*1},
                            {letter:"M",x:-602/3*1,y:-582/3*2},
                            {letter:"N",x:-602/3*2,y:-582/3*1},
                            {letter:"P",x:-602/3*1,y:-582/3*2},
                            {letter:"Q",x:-602/3*0,y:-582/3*2},
                            {letter:"R",x:-602/3*0,y:-582/3*1},
                            {letter:"S",x:-602/3*1,y:-582/3*1},
                            {letter:"T",x:-602/3*1,y:-582/3*1},
                            {letter:"V",x:-602/3*2,y:-582/3*2},
                            {letter:"W",x:-602/3*0,y:-582/3*1},
                            {letter:"X",x:-602/3*2,y:-582/3*1},
                            {letter:"Z",x:-602/3*2,y:-582/3*0},
          ]
          var requestID = null
          var controls_button = document.getElementById("controls")
          var ctx = null
          var x = 0
          var y = 0
          var playing = false
          var currentIdx = 0
          var count = 0
    const loadCanvasLips = () =>{
        if (canvas!=null) {
          ctx = canvas.getContext("2d")
          // x,y coordinates in pixels to display mouth shape for each word's phonemes
          // One way to adjust the speed of the animation is to dictate how many frames
          // each phoneme 
          
          canvas.width = 0
          canvas.height = 0

          //lyrics.style.fontSize = "2em"
          //img_data.onload = animateLisp
          //img_data.src = "https://thepracticaldev.s3.amazonaws.com/i/iubpy8p58bkfg7vwvxoh.jpg"
          img_data.src = imgLips
          animateLisp("F")
        }
    }

          const eraserLips=()=>{
            //ctx.clearRect(0, 0, 0, 0);
            clearInterval(timeInterval)
          }
          const animateLisp =(dataVal)=> {
            var setDataPos = []
            var setPositionTwo = 0
                if (dataMessage[dataTrackPosition[0]]!=undefined) {
                  setDataPos = JSON.parse(dataMessage[dataTrackPosition[0]].data).reverse()
                  setPositionTwo=dataTrackPosition[1]-1
                }else{
                  if (dataMessageForm[dataTrackPositionForm[0]]!=undefined) {
                     var setDataPos = JSON.parse(dataMessageForm[dataTrackPositionForm[0]].data).reverse()
                     setPositionTwo=dataTrackPositionForm[1]-1
                  }
                }

              var dataGlobalTrack=setDataPos[setPositionTwo]
              //   dataGlobalTrack = setDataPos[dataTrackPosition[1]]
              // if (setDataPos.length>1) {
              // }else{
              //   dataGlobalTrack = setDataPos[0]
              // }
              //console.log(dataGlobalTrack,dataMessage,dataMessageForm)


              if (dataGlobalTrack!=undefined) {
                var firstL = coordinates.find((letterRow)=> letterRow.letter.toLowerCase().indexOf(dataVal.toLowerCase())!=-1)
                  if (firstL==undefined) {
                    firstL={letter:"M",x:-602/3*1,y:-582/3*2}
                  }
                    canvas.width = 602/3
                    canvas.height = 582/3 - 70
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    canvas.style.width="18px"
                    canvas.style.height="11px"
                  if (dataGlobalTrack.mouth!=undefined) {
                    canvas.style.left=parseInt(dataGlobalTrack.mouth._x)-8 +'px' //30
                    canvas.style.top=parseInt(dataGlobalTrack.mouth._y)-5 +'px' //38
                  }else{
                    canvas.style.top="21px"
                    canvas.style.left="19px"
                  }

                    var changeScale = 0
                    var xScale = 1.7+changeScale;
                    var yScale = 2.3+changeScale;
                    var xWith = -0.3+changeScale;
                    var yHeight = -1.1+changeScale;
                    ctx.setTransform(xScale, 0, 0, yScale, canvas.width * xWith, canvas.height * yHeight);
                    ctx.drawImage(img_data, firstL.x, firstL.y)
              }
          }

          const earAssistant = () => {
            setEarAudio(1)
            //setViewCanvas(true)
            localStorage.setItem("earAudio","1")
            //window.location.reload()
          }
//,right: 37,top: 37,width: '17px',height: '8px'
  return (
    <>

    <p id="lyrics"></p>
    <div className="setMiniContents">{dataContent}</div>
      <div className="clickBot" id="clickBot" >
      <audio id="audioElement" autoPlay />
      {(viewCanvas)?(
        <canvas 
        id="canvasId" 
        onLoad={loadCanvasLips()} 
        style={{position:'absolute',borderBottomRightRadius: '30px',borderBottomLeftRadius: '30px'}} 
        width="0" 
        height="0"></canvas>
        ):(null)}
      {(imgData!=null)?(
          <div onClick={()=>{logisticsChange(dataMessageTwo)}} id="clickBotOption">
            <img style={{width: '80px', height: '120px'}} src={imgData} id="clickBotOption" className="imgDetections" />
          </div>
        ):(null)}
        {(controlShow!=0)?(
            <div id="clickBotOption" style={{width: '81px', textAlign: 'center',fontSize: '20px',color: 'white',backgroundColor: '#2d5980',borderRadius: '15px'}}>
              {/*<i class="fas fa-exclamation" onClick={()=>{portionContentMessage(true)}}></i>*/}
                {(earAudio==1)?(
                  <i id="clickBotOption" class="fas fa-play" onClick={()=>{logisticsChange(dataMessageTwo)}}></i>
                ):(null)}

                {(earAudio==1)?(
                  <i id="clickBotOption" style={{marginLeft: '15px'}} class="far fa-stop-circle" onClick={()=>{restartContentMessage()}}></i>
                  ):(
                  <i id="clickBotOption" class="fas fa-volume-up" onClick={()=>{earAssistant()}}></i>
                )}
              {/*<i style={{marginLeft: '15px'}} class="fas fa-step-forward" onClick={()=>{nextContentMessage()}}></i>*/}
{/*                {((dataTrackPosition[0]+1)==controlShowMain)?(null):(
                  (controlShow!=dataTrackPosition[1])?(null):(<>
                    <i style={{marginLeft: '15px'}} class="fas fa-step-forward" onClick={()=>{nextContentMessage()}}></i>
                  </>)
                )}*/}

            </div>
          ):(null)}
      </div>
    </>
  );
}

export default Assistant;