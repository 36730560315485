import React, {createRef, useEffect} from "react";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Collapse,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { data_list_production_mrv, add_favorite_mrv, get_permission } from "network/ApiAxios";
import { NavLink as ComponentLink } from "react-router-dom";
import ViewPermision from "views/pages/components/ViewPermissionDenied.js";
import jQuery from "jquery";
import {UserContext} from "layouts/store";
import {GuideMessage} from "views/pages/components/GuideMessage";
import MRVProd from "views/pages/forms/Production/MRV/MRVProViewTable";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import * as firedatabase from "firebase/database";
import { db } from "network/ConfigFirebase";
export class ReportProductionMVR extends React.Component {
  static contextType = UserContext
  state={
    data_users_prod:[],
    company_id:null,
    collapse:[],
    type_search:1,
    type_nama:'0',
    dropdownOpen:[],
    filters:{global: { value: null, matchMode: FilterMatchMode.CONTAINS }},
    for_load:'',
    type_traza_f_users:''
  }
 async componentDidMount(){
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var dataPermission = this.context.valueGlobal
  if (this.props.dataTypeRole==null) {
    jQuery('#tg').remove()
    return
  }else{
    if (this.props.dataTypeRole=="G") {
      jQuery('#tg').remove()
    }
  }
  var setTypeT = ""
  if (dataPermission.type_traza_gc==null) {
    setTypeT="P"
  }else{
    if (dataPermission.type_traza_gc.indexOf("P")!=-1) {
      setTypeT="P"
    }else{
      if (dataPermission.type_traza_gc.indexOf("VCF")!=-1) {
        setTypeT="VCF"
      }
    }
  }
  var gerParam = new URLSearchParams(this.props.location.search).get("company");
  this.setState({
    company_id:gerParam,
    type_role_user:dataPermission.type_traza_gc,
    type_traza_f_users:setTypeT
  })
  this.putInfoProdSelect(this.state.type_search,setTypeT)
  this.initFireBase()
  this.updateCheck()
  }

  componentDidUpdate(){
    //this.updateCheck()
  }
  updateCheck=()=>{
      var counterCheck = 0; 
      document.querySelectorAll('.check_FCTGN_alls').forEach((a,b)=>{
          if (a.checked==true) {
            counterCheck = counterCheck+1;
          }
        } )
      //if (counterCheck>0) {document.querySelector('.btnCheck').textContent = 'Desmarcar'; document.querySelector('.btnCheck').setAttribute('onclick', 'changeCheck(2)');}else{document.querySelector('.btnCheck').textContent = 'Marcar'; document.querySelector('.btnCheck').setAttribute('onclick', 'changeCheck(1)');}
      if (counterCheck==document.querySelectorAll('.check_FCTGN_alls').length) {
        if (document.querySelector('.btnCheck')!=null) {      
          document.querySelector('.btnCheck').textContent = 'Desmarcar';
          document.querySelector('.btnCheck').setAttribute('onclick', 'changeCheck(2)');
        }
      }else{
        if (document.querySelector('.btnCheck')!=null) {      
          document.querySelector('.btnCheck').textContent = 'Marcar';
          document.querySelector('.btnCheck').setAttribute('onclick', 'changeCheck(1)');
        }
      }
  }
  get_db = (codigo,data) => {
    var get_data = data.filter((a,b)=>a.btnides==codigo)
    return 'db_traza_'+get_data[0].user_id
  }
  addDataFavorite = async (code_prod,user_id_db,check) =>{
    this.updateCheck()
    await add_favorite_mrv(code_prod,user_id_db,check)
  }
  putCheck = async (selec,code,data) =>{
    await data
    jQuery('.'+selec).prop('checked',false)
    data.map((a,b)=>{
      if (a.code_prod!=null) {
        jQuery('.check_'+a.code_prod).prop('checked',true)
      }
    })
  }
  changetogle= async(indx,dropdownOpen,ele)=>{
    await this.state.dropdownOpen
    //adjustTable
    // if (dropdownOpen==undefined) {
    //   jQuery(".adjustTable").height(String(getHDD+10))
    // }else{
    //    if (dropdownOpen) {
    //        jQuery(".adjustTable").height(String(getHDD+10))
    //    }else{
    //    jQuery(".adjustTable").height('0')
    //    }
    // }
    this.setState({dropdownOpen:{[indx]:!dropdownOpen}})
    var getHDD = document.querySelector('.ddmgeth_'+indx)?.offsetHeight
    if (this.state.dropdownOpen[indx]) {
      jQuery(".adjustTable").height(String(getHDD+10))
    }else{
      jQuery(".adjustTable").height('0')
    }
    //document.querySelector('.adjustTable').style.height=getHDD+"px"
  }
  rowData=(data,data_db_user,codigo)=>{
    var getData = JSON.parse(data)
    var content = []
    var setUrlPush = 'detail_monitoring'
    if (codigo.indexOf('FCTVFGC-')!=-1) {
      setUrlPush = 'detail_monitoring_fv_gc'
    }
    getData.map((a,b)=>{
      if (JSON.stringify(a.datastate.state_status).split('Revisar').length-1!=0) {
        content[b]=<><DropdownItem>
                  <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:setUrlPush,
                        search: "?db="+data_db_user+"&code="+codigo,
                        state:{
                          table_params:a.datastate.activity_table[0],
                          title_params:a.datastate.activity[0]
                        }
                        }}
                      >
                      {a.datastate.activity[0]}
                     </ComponentLink>
        </DropdownItem></>
      }
    })
    return content
  }
  tracking=(data,code_row)=>{
    var contetn = []
    Object.values(data).map((a,b)=>{
    var getdataiter = []
    Object.values(this.state.data_users_prod[2]).map((va,id)=>{
      getdataiter.push(...va)
    })
      if (a!=null) {
        if (a.length!=0) {
          a.map((valT,indT)=>{
            if (valT.codigo==code_row) {
              if (valT.data_adjunt!=null) {
                    contetn[0]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px',fontWeight: '700'}}>{this.dateFollow(valT.updated_at,valT)}</span><br /></>
                  if (valT.data_adjunt.split('Por Hacer').length-1) {
                    contetn[1]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{valT.data_adjunt.split('Por Hacer').length-1} Por Hacer</span><br /></>
                  }
                  if (valT.data_adjunt.split('Revisar').length-1) {
                    contetn[2]=<>
                    <Dropdown isOpen={(this.state.dropdownOpen[b]==undefined)?(false):(this.state.dropdownOpen[b])} toggle={(e)=> this.changetogle(b,this.state.dropdownOpen[b],e) }>
                      <DropdownToggle caret style={{fontSize: '12px',color:'orange',padding: 0,boxShadow: 'none',backgroundColor: 'transparent',border: 'none'}}>{valT.data_adjunt.split('Revisar').length-1} Revisar</DropdownToggle>
                      <DropdownMenu className={"ddmgeth_"+b}>
                        {/*<DropdownItem>Some Action</DropdownItem>*/}
                        {this.rowData(valT.data_adjunt,this.get_db(valT.codigo,getdataiter),valT.codigo)}
                        {/*<DropdownItem divider />*/}
                      </DropdownMenu>
                    </Dropdown>
                    <br />
                    </>
                  }
                  if (valT.data_adjunt.split('No Conforme').length-1) {
                    contetn[3]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{valT.data_adjunt.split('No Conforme').length-1} No Conforme</span><br /></>
                  }
                  if (valT.data_adjunt.split('Realizado Conforme').length-1) {
                    contetn[4]=<><span className="text-muted" style={{margin: '0px',fontSize: '12px'}}>{valT.data_adjunt.split('Realizado Conforme').length-1} Realizados Conforme</span><br /></>
                  }
                  
                }
              }
          })

        }

      }
    })
    return contetn
  }
  dateFollow=(paramDateFollow,fullData)=>{
    var varDate = new Date(fullData.monitor_date+' '+fullData.init_time);
    return varDate.getDate()+'-'+varDate.getMonth()+'-'+varDate.getFullYear()+' '+varDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    //var varDate = new Date(paramDateFollow)
    //return varDate.getDate()+'-'+varDate.getMonth()+'-'+varDate.getFullYear()+' '+varDate.getHours()+':'+varDate.getMinutes()+':'+varDate.getSeconds()
  }
  setContent = (data,data_ind,data_three) => {
    if (data==undefined) {
      return
    }
    var contentth = []

    if (data.length!=0) {
    var content=[]
    var getdataiter = []
    var get_value_input_dni = jQuery('#input_search_dni').val()
    var getConvertTextDni=get_value_input_dni.replace(/\D/g, "").toUpperCase()

    var get_value_input = jQuery('#input_search_specific').val()

    // if (get_value_input!="") {
    // }else{
    //   if (get_value_input_dni!="") {      
    //     Object.values(data).map((a,b)=>{
    //       var getFilter = a.filter(val=> 
    //       ((val.cedula).replace(/\D/g, "").toUpperCase()).indexOf(getConvertTextDni) != -1
    //       )
    //       getdataiter.push(...getFilter)
    //     })
    //   }
    // }
      var getConvertText=get_value_input.replaceAll(" ","").toUpperCase()
      var getFilter = []
      Object.values(data).map((a,b)=>{
        if (a.length!=0) {

          // getFilter = a.filter(val=> 
          //   ((val.nombre_productor+val.apellido_productor+val.empresa+val.parcela_nama_no_nama+val.btnides+val.estado_provincia_comarca+val.producto)
          //   .replaceAll(" ","")
          //   .toUpperCase())
          // .indexOf(getConvertText) != -1 && 
          // ((val.cedula).replace(/\D/g, "").toUpperCase()).indexOf(getConvertTextDni) != -1
          // ) 
          //E-8-119148
          getFilter =  a.map((val,indx)=>{
            if (val.cedula!=null) {
              if (
                  ((val.nombre_productor+val.apellido_productor+val.empresa+val.parcela_nama_no_nama+val.btnides+val.estado_provincia_comarca+val.producto)
                  .replaceAll(" ","")
                  .toUpperCase())
                .indexOf(getConvertText) != -1 && 
                ((val.cedula).replace(/\D/g, "").toUpperCase()).indexOf(getConvertTextDni) != -1
              ) {
                  return val
                //getFilter = 
              }
            }

          })
          getFilter = getFilter.filter(Boolean)
          if (getFilter.length!=0) {
            getdataiter.push(...getFilter)
          }
        }
      })
    if (getdataiter.length==0) {
        // if (this.state.for_load=='carga') {        
        //   content = <><tr><td>Cargando...</td></tr></>
        // }
        content = <><tr><td>Sin Resultado</td></tr></>
      return content
    }

    if (this.state.type_search==0) {
      contentth[contentth.length+1] = '<th style="width: 10px;">+ Favorito </br><button class="btn btnCheck" style="padding: 0;" onclick="changeCheck(1)">Marcar</button></th>'
    }else{
      contentth[contentth.length+1] = '<th style="width: 10px;">+ Favorito </br><button class="btn btnCheck" style="padding: 0;" onclick="changeCheck(2)">Desmarcar</button></th>'
    }
    
      getdataiter.map((a,b)=>{
        var get_codigo = null
        var content_one = []
if (a.stado_status=="desarrollo") {
         content_one[b] = Object.entries(a).map((val,ind)=>{
            if (val[0]=='btnides') {
              get_codigo=val[1]
            }
         data_ind.map((valind,indind)=>{
             if (valind.split('*')[1].indexOf(val[0])!=-1) {
               if (val[0]!='comunidad') {
                 contentth[ind+1]='<th>'+valind.split('*')[0].replaceAll(" / Provincia / Comarca","").replaceAll("Parcela ","")+'</th>'
               }
               if (val[0]=="nombre_productor") {
                 contentth[ind+1]='<th>Nombre</th>'
               }
               if (val[0]=="apellido_productor") {
                 contentth[ind+1]='<th>Apellido</th>'
               }
             }
           })
 var content_two = <>
  {(val[0]=='code_tracking')?(<>
    <td><span className="text-muted">
      {
        this.tracking(data_three,val[1])
      }
    </span></td>
    </>):(null)}
  {(val[0]=='btnides_fav')?(<><td style={{textAlign: 'center'}}><input type="checkbox" className={"check_FCTGN_alls check_"+val[1]} onClick={(e)=>{this.addDataFavorite(val[1],this.get_db(val[1],getdataiter),e.target.checked)}} /></td></>):(null)}
  {(val[0]!='btnides_fav' && val[0]!='btnides' && val[0]!='user_id' && val[0]!='code_prod' && val[0]!='code_tracking' && val[0]!='stado_status' && val[0]!='comunidad')?(<td style={{maxWidth: '15ch',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}><span className="text-muted" style={{fontSize: "12px"}} title={val[1]}>{val[1]}</span></td>):(null)}
  {(val[0]=='btnides')?(<><td>
                {(this.props.dataTypeRole=='VA')?(null):(<>
                  <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:(val[1].indexOf('FCTVFGC-')!=-1)?('detail_monitoring_fv_gc'):('detail_monitoring'),
                        search: "?db="+this.get_db(val[1],getdataiter)+"&code="+val[1],
                        state:{
                          db_codigo:this.get_db(val[1],getdataiter)
                        }
                        }}
                      >
                      + Info {val[1]}
                     </ComponentLink>
                     <br />
                </>)}
                  <ComponentLink
                      className="font-weight-bold"
                                            to={{
                        pathname:(val[1].indexOf('FCTVFGC-')!=-1)?("fv_gc_end_mrv"):("grains_nama_end_mrv"),
                        search: "?db="+this.get_db(val[1],getdataiter)+"&code="+val[1],
                        state:{
                          db_codigo:this.get_db(val[1],getdataiter)
                        }
                        }}
                      >
                      Ver Reporte {(this.props.dataTypeRole=='VA')?(val[1]):(null)}
                     </ComponentLink>
                   </td>
                   {(this.props.dataTypeRole=='VA')?(null):(<>
                    <td>
                      <ComponentLink
                          className="font-weight-bold"
                                                to={{
                            pathname:"prouction-observations-mvr",
                            search: "?db="+this.get_db(val[1],getdataiter)+"&code="+val[1]+"&mrv="+this.props.dataTypeRole,
                            state:{
                              db_codigo:this.get_db(val[1],getdataiter)
                            }
                            }}
                          >
                          Ver Observaciones 
                       </ComponentLink>
                       {(this.props.dataTypeRole=='RE' || this.props.dataTypeRole=='VA')?(<>
                        <br/>
                      <ComponentLink
                          className="font-weight-bold"
                                                to={{
                            pathname:"view_observations_mvr",
                            search: "?db="+this.get_db(val[1],getdataiter)+"&code="+val[1]+"&mrv="+this.props.dataTypeRole,
                            state:{
                              db_codigo:this.get_db(val[1],getdataiter)
                            }
                            }}
                          >
                          Observaciones Realizados
                       </ComponentLink>
                        </>):(null)}
                     </td>
                    </>)}
                   </>):(null)}
                      </>
                      this.putCheck("check_"+val[1],val[1],getdataiter)
                      return content_two
        })
}
        content[b] = <>
                    <tr key={b}>
                    {content_one}
                    </tr>
                    </>
      })
    }
    //console.log(data_three,'<-')
      //contentth[contentth.length] = '<th style="width: 10px;">Nama/No-Nama</th>'
      contentth[contentth.length] = '<th style="width: 10px;">Empresa</th>'
    if (data_three.length!=0) {
      contentth[contentth.length] = '<th style="width: 10px;">Seguimiento</th>'
    }
    contentth[contentth.length] = '<th>Opciones</th>'
    if (data_three.length!=0) {
      if (this.props.dataTypeRole!='VA') {
        contentth[contentth.length] = '<th>Observaciones</th>'
      }
    }
    jQuery('.putContentTh').html(contentth+"<script> function changeCheck(){  var counterCheck = 0; document.querySelectorAll('.check_FCTGN_alls').forEach((a,b)=>{a.click(); if (a.checked==true) {counterCheck= ++counterCheck} } )  } </script>")
    return content
  }
   toggleFunction = (index,res,code) =>{
    if (res==undefined) {
    this.setState({collapse:{[code]:true}})
    //this.state.collapse[code] = true;
    }else{
      if (this.state.collapse[code]==true) {
        this.setState({collapse:{[code]:false}})
        //this.state.collapse[code] = false;
      }else{
        this.setState({collapse:{[code]:true}})
        //this.state.collapse[code] = true;
      }
    }
    this.setState({codeTTV:code})
    this.setState({setinfoState:false})
  }
// setContentTh = (data) => {
//   var content = []
//     if (data!=undefined) {
//       Object.values(data).map((a,b)=>{
//         content[b]=<th>{a.split('*')[0]}</th>
//       })
//     }
//     return content
//   }

 // setContentTh = (data,data_ind) => {
 //   var content = []
 //      if (data!=undefined) {
 //      data_ind.map((valind,indind)=>{
 //        data.map((a,b)=>{
 //            Object.entries(a).map((val,ind)=>{
 //                if (valind.split('*')[1].indexOf(val[0])!=-1) {
 //                 content[ind]=<th>{valind.split('*')[0]}</th>
 //                }
 //                })
 //            })
 //        })
 //      }
 //      return content
 //   }

setContentTh = (data) => {
  var content = []
    if (data!=undefined) {
      let uniqueChars = [...new Set(data)];
      Object.values(uniqueChars).map((a,b)=>{
        content[b]= {title:a.split('*')[0],table:a.split('*')[1]}
      })
    }
    return content
  }
initFireBase = () => {
  var userId = this.getUserId()
        firedatabase.onValue(this.getDBFire(), (snapshot) => {
          if (snapshot.exists()) {
             var data = snapshot.val();
             var dataKey = Object.keys(data);
             var getData = Object.values(data)
             if (getData[getData.length-1].notifications!=undefined) {
              if (getData[getData.length-1].collab=='Si') {
                this.putInfoProdSelect(this.state.type_search,this.state.type_traza_f_users)
                //this.notify("info", "¡Nueva Notificación!", 'Nueva Notificación: '+getData[getData.length-1].titulo);
              }
               // if (getData[getData.length-1].user_id!=userId) {
               // }
             }
          }
      });
}
getUserId = () => {
  var userdata = localStorage.getItem('user');
  var jsondata = JSON.parse(userdata);
  var data_response = null
  if (jsondata.userType==4) {
    data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
  }else{
    if (jsondata.database==undefined) {
      data_response = {id_user:jsondata._id,database_user:'user/'+jsondata._id}
    }else{
      data_response = {id_user:jsondata._id,database_user:jsondata.database+'/'+'user/'+jsondata._id}
    }
  }
    return data_response
}
getDBFire = () => {
  const productsCollection = firedatabase.ref(db,this.getUserId().database_user)
  return productsCollection
};
   putInfoProd = async () => {
    this.setState({type_search:'0'})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var get_value_dni = jQuery('#input_search_dni').val()
    if (get_value_dni=='') {
      alert('No puede estar vacio el campo de Cédula')
      return
    }
    var get_value_specific = jQuery('#input_search_specific').val()
    this.setState({for_load:'carga' })
    var data = await data_list_production_mrv(jsondata._id,
                                              this.props.sendDataCompany.user_id_connection,
                                              this.props.sendDataCompany.type,
                                              'ttp',
                                              get_value_dni.replace(/\D/g, ""),
                                              get_value_specific,
                                              2,
                                              this.props.dataTypeRole,
                                              this.state.type_traza_f_users)
    this.setState({data_users_prod:data.data_productions,for_load:'' })
    this.updateCheck()

    window.changeCheck(1)

   } 
   putInfoProdSelect = async (type_search,setTypeT) => {
    this.setState({type_search:type_search})
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var get_value_dni = jQuery('#input_search_dni').val()
    var get_value_specific = jQuery('#input_search_specific').val()
    if (type_search==1) {
      jQuery('#input_search_dni').val('')
      jQuery('#input_search_specific').val('')
      this.setState({data_users_prod:[],for_load:'carga' })
      if (get_value_dni!=undefined) {
        var data = await data_list_production_mrv(jsondata._id,
                                                  this.props.sendDataCompany.user_id_connection,
                                                  this.props.sendDataCompany.type,
                                                  'ttp',
                                                  get_value_dni.replace(/\D/g, ""),
                                                  get_value_specific,
                                                  1,
                                                  this.props.dataTypeRole,
                                                  setTypeT)
        this.setState({data_users_prod:data.data_productions,for_load:'' })
      }
    }
   }
  changeRegistersType = (type) => {
    // var setData=[]
    // if (type=="P") {
    //   setData = this.state.full_data_gc.nama_grain
    // }
    // if (type=="VCF") {
    //   setData = this.state.full_data_gc.FV_GC
    // }
    this.setState({
      type_traza_f_users:type
    })
  }
render(){
    const header = (
        <div className="flex flex-column flex-md-row md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0" style={{ color: '#002a5c' }}>
                Tabla Lista MRV
            </h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                type="search" 
                value={this.state.globalFilter}
                //onInput={(e) => this.setState({globalFilter:e.currentTarget.value}) }
                onChange={(e)=>{onGlobalFilterChange(e.target.value)}}
                placeholder="Buscar..." 
                />
            </span>

            <Button 
                label="Nueva" 
                icon="pi pi-plus" 
                severity="success" 
                className="btn-fod-green mr-2" 
                onClick={() => this.setState({visibleRight:true})} 
                />
        </div>
    );
    const onGlobalFilterChange = (valueinp) => {
        const value = valueinp;
        let _filters = { ...this.state.filters };

        _filters['global'].value = value;

        //setFilters(_filters);
        this.setState({filters:_filters})
        this.setState({globalFilter:valueinp})
    };
  return (
    <>
      {/*<SimpleHeader name="Lista de Productores" parentName="Lista de Productores" section="mrv" section_two="Tabla" section_table_form="table_traza_proccess" />*/}
        <div className="mt-3">

        <Card id="tg">
          <CardHeader className="border-0">
            <Row>
              <Col md="12">
                <h5 className="mb-0">Tabla Lista MRV</h5>
              </Col>
                <div style={{width: '18%'}}>
                    <InputGroup style={{border: 'solid 1px'}}>
                      <Input style={{height: '25px',padding: '0px'}} placeholder="Buscar Cédula" id="input_search_dni"/>
                      {/*<button style={{height: '25px',padding: '0px'}} className="btn fa fa-search" onClick={()=>{this.putInfoProd()}}></button>*/}
                    </InputGroup>
                </div>
                <div style={{width: '18%'}}>
                    <InputGroup style={{border: 'solid 1px'}}>
                      <Input style={{height: '25px',padding: '0px'}} placeholder="Nombre, Provincia" id="input_search_specific"/>

                      {/*<button style={{height: '25px',padding: '0px'}} className="btn fa fa-search" onClick={()=>{this.putInfoProd()}}></button>*/}
                    </InputGroup>
                </div>
              <Col md="2">
                  <InputGroup style={{border: 'solid 1px'}}>
                      <Input style={{height: '25px',padding: '0px'}} 
                      type="select" 
                      value={this.state.type_search} 
                      onChange={e => this.putInfoProdSelect(e.target.value,this.state.type_traza_f_users)}>
                        <option value="0">Seleccione</option>
                        <option value="1">Productores Favoritos</option>
                      </Input>
                  </InputGroup>
              </Col>
              <Col md="2">
                  <InputGroup style={{border: 'solid 1px'}}>
                      <Input 
                      style={{height: '25px',padding: '0px'}} 
                      type="select" 
                      value={this.state.type_nama} 
                      onChange={e => {
                        this.setState({type_nama:e.target.value})
                        jQuery('#input_search_specific').val(e.target.value)
                        //this.putInfoProd()
                      } }>
                        <option value="0">Seleccione tipo de parcela</option>
                        <option value="Nama">Nama</option>
                        <option value="No NAMA">No Nama</option>
                        <option value="Demostrativa">Nama Demostrativa</option>
                        <option value="Otra">Otra</option>
                      </Input>
                  </InputGroup>
              </Col>
              <Col md="2">
                <InputGroup style={{border: 'solid 1px'}}>
                  <Input 
                  style={{height: '25px',padding: '0px'}} 
                  type="select" 
                  // value={trazabilidad} 
                  onChange={e => {
                    this.changeRegistersType(e.target.value)
                  }}
                  >
                    <option value="0">Filtro de Registros</option>
                    {(this.state.type_role_user?.indexOf("VCF")!=-1)?(<option value="VCF">Frutas y Vegetales</option>):(null)}
                    {(this.state.type_role_user?.indexOf("P")!=-1)?(<option value="P">Granos</option>):(null)}
                  </Input>
                </InputGroup>
              </Col>
              <Col md="12">
                <button style={{height: '25px',padding: '0px'}} className="btn fa fa-search" onClick={()=>{this.putInfoProd()}}></button>
              </Col>

            </Row>
          </CardHeader>

{/*                    <DataTable
                        value={(this.state.data_users_prod[2]!=undefined)?(Object.values(this.state.data_users_prod[2])):([])}
                        dataKey="id"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} Registros"
                        filters={this.state.filters}
                        className="datatable-responsive"
                        emptyMessage="Sin registros encontrados."
                        responsiveLayout="scroll"
                        header={header}
                    >
                        <Column field="id" header="#" sortable  headerStyle={{ minWidth: '2rem' }}></Column>
                        {this.setContentTh(this.state.data_users_prod[1]).map((a,b)=>{
                          return <Column body={(data)=>{
                            if (data[a.table]!=null) {
                              if (data[a.table].length>10) {
                                return `${data[a.table].substring(0, 10)}...`
                              }else{
                                return data[a.table]
                              }
                            }else{
                              return "Sin datos"
                            }
                          }} field={a.table} header={a.title} ></Column>
                        })}
                        <Column field="created_at" header="Creado" sortable></Column>
                    </DataTable>*/}
{/*              {this.setContentTh(this.state.data_users_prod[2],this.state.data_users_prod[1])}
                <th>Opciones</th>*/}

          <Table className="align-items-center table-flush adjustTable" responsive>
            <thead className="thead-light">
              <tr className={"putContentTh"} key={0}>
              </tr>
            </thead>
            <tbody>
            {(this.state.data_users_prod!=undefined)?(
                this.setContent(this.state.data_users_prod[2],this.state.data_users_prod[1],this.state.data_users_prod[3])
              ):(null)}
            </tbody>
          </Table>
          
        </Card>
      </div>
    </>
  );
 }
}

class SwiftComponent extends React.Component {
  static contextType = UserContext
  state={
    get_permission:'load',
    company_id_act:null,
    setinfoState:false,
    type_traza_user_type:null,

  }
 async componentDidMount(){
    await this.context.getAffiliates()
    //await this.context.valueGlobal
    this.setInfoInit()
  }
 setInfoInit = () =>{
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    var gerParam = new URLSearchParams(this.props.location.search).get("company");
    if (this.state.setinfoState) {
    return;  
    }
    if (this.context.valueGlobal.length!=0) {
          this.setState({setinfoState:true})
        }else{
          return
        }

    var dataPermission = this.context.valueGlobal
          var data_type_area_on = dataPermission.type_traza.indexOf('ON')
          var data_type_area_re = dataPermission.type_traza.indexOf('RE')
          var data_type_area_va = dataPermission.type_traza.indexOf('VA')
          if (dataPermission.type_traza=="ON-RE-VA") {
            this.setState({data_user_traza:jsondata.type_traza})
          }else{
            this.setState({data_user_traza:dataPermission.type_traza})
          }
          if (data_type_area_on==-1 || data_type_area_re==-1 || data_type_area_va==-1) {
            this.setState({get_permission:false})
          }
          // if (data_type_area_on==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_re==-1) {
          //   this.setState({get_permission:false})
          // }
          // if (data_type_area_va==-1) {
          //   this.setState({get_permission:false})
          // }
        this.setState({company_id_act:dataPermission})
        if (dataPermission.role==1) {
          this.setState({get_permission:true})
          if (this.context.valueDataMain[0]==undefined) {
            this.setState({get_permission:false})
          }
        }else{
          if (dataPermission.role==4) {
            this.setState({get_permission:true})
          }
        }
      }  
render(){
  
  return (
    <>
    {(this.state.get_permission=='load')?(
      <h1 className="text-center">Cargando...</h1>
      ):(
      (this.state.get_permission)?(
        <ReportProductionMVR {...this.props} dataTypeRole={this.state.data_user_traza} sendDataCompany={this.state.company_id_act}/>
        ):(
        <ViewPermision />
        )
      )}
    </>
  );
 }
}
export default SwiftComponent;
//export default BuildingProduction;
