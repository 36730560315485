
import React, { useContext } from 'react';
import AppMenuitem from './AppMenuitem';
//import { LayoutContext } from './context/layoutcontext';
import { MenuProvider } from './context/menucontext';
//import Link from 'next/link';
//import { AppMenuItem } from '@/types';
import {UserContext} from "layouts/store";
import jQuery from 'jquery';
import { Link, useLocation } from "react-router-dom";

const AppMenu = () => {
    const [infoState, setinfoState] = React.useState(false);  
    const get_permission_extend = useContext(UserContext);
    var userdata = localStorage.getItem('user');
    var jsondata = JSON.parse(userdata);
    const AppMenuItem = [
        {
            label: '',
            items: [{ label: 'Inicio', icon: 'size-icons custom-icon-home', to: (jsondata.userType==1)?('/admin/home'):('/admin/home'+window.location.search) }]
        },
        {
            label: 'Gestión de cultivos',
            items: [
                //{ label: 'Cárnicos', icon: 'size-icons custom-icon-meat', to: '/admin/start-trace/meats' },
                //{ label: 'Granos', icon: 'size-icons custom-icon-grains', to: (jsondata.userType==1)?('/admin/info_crops_t'):('/admin/info_crops_t'+window.location.search) },
                { label: 'Frutas y Vegetales GC', icon: 'size-icons custom-icon-fruit', to: (jsondata.userType==1)?('/admin/fruit_vegetable_gc'):('/admin/fruit_vegetable_gc'+window.location.search), badge: 'NEW' },
                { label: 'Granos GC', icon: 'size-icons custom-icon-prod', to: (jsondata.userType==1)?('/admin/grains_gc'):('/admin/grains_gc'+window.location.search) },
                //{ label: 'Satélites', icon: 'size-icons custom-icon-sat', to: '/admin/list-trace-satelital' },
                { label: 'Vista gestión de Cultivos', icon: 'size-icons custom-icon-time', to: (jsondata.userType==1)?('/admin/process-production'):('/admin/process-production'+window.location.search) },
                { label: 'Blockchain', icon: 'size-icons custom-icon-bk', to: (jsondata.userType==1)?('/admin/blockchain-production'):('/admin/blockchain-production'+window.location.search), class: 'rotated-icon' },
                { label: 'Historial', icon: 'size-icons custom-icon-box', to: (jsondata.userType==1)?('/admin/finalized-production'):('/admin/finalized-production'+window.location.search) },
            ]
        },
        {
            label: 'Trazabilidad',
            items: [
                //{ label: 'Frutas y Vegetales', icon: 'size-icons custom-icon-fruit', to: (jsondata.userType==1)?('/admin/process-trace'):('/admin/process-trace'+window.location.search), badge: 'NEW' },
                { label: 'Frutas y Vegetales', icon: 'size-icons custom-icon-fruit', to: (jsondata.userType==1)?('/admin/fruit_vegetable_user'):('/admin/fruit_vegetable_user'+window.location.search), badge: 'NEW' },
                //{ label: 'Cárnicos', icon: 'size-icons custom-icon-meat', to: '/admin/start-trace/meats' },
                // { label: 'Granos', icon: 'size-icons custom-icon-grains', to: (jsondata.userType==1)?('/admin/info_crops_t'):('/admin/info_crops_t'+window.location.search) },
                //{ label: 'Procesados', icon: 'pi pi-fw pi-clone', to: '/admin/start-trace/processed' },
                //{ label: 'Pescados y Mariscos', icon: 'size-icons custom-icon-fish', to: '/admin/start-trace/seafood' },
                { label: 'Trazabilidad KDEs', icon: 'size-icons custom-icon-fruit', to: (jsondata.userType==1)?('/admin/process-kdes'):('/admin/process-kdes'+window.location.search), badge: 'NEW' },
                { label: 'Historial KDEs', icon: 'size-icons custom-icon-box', to: (jsondata.userType==1)?('/admin/history-kdes'):('/admin/history-kdes'+window.location.search) },
                //{ label: 'Trazabilidad', icon: 'size-icons custom-icon-map', to: '/admin/process-trace', preventExact: true },
                { label: 'Vista Trace', icon: 'size-icons custom-icon-time', to: (jsondata.userType==1)?('/admin/view-trace'):('/admin/view-trace'+window.location.search) },
                { label: 'Blockchain', icon: 'size-icons custom-icon-bk', to: (jsondata.userType==1)?('/admin/vista-blockchain'):('/admin/vista-blockchain'+window.location.search) },
                { label: 'Historial', icon: 'size-icons custom-icon-box', to: (jsondata.userType==1)?('/admin/finalized-trace'):('/admin/finalized-trace'+window.location.search) },
            ]
        },
        {
            label: 'Productores',
            items: [
                { label: 'Lista de Productores', icon: 'size-icons custom-icon-prod', to: (jsondata.userType==1)?('/admin/report-production-users'):('/admin/report-production-users'+window.location.search) },
                { label: 'Lista de actividades de productores', icon: 'size-icons custom-icon-time', to: (jsondata.userType==1)?('/admin/report_production_activity_users'):('/admin/report_production_activity_users'+window.location.search) },
                { label: 'Historial', icon: 'size-icons custom-icon-box', to: (jsondata.userType==1)?('/admin/history-mrv'):('/admin/history-mrv'+window.location.search) },
            ]
        },
        {
            label: 'Usuarios',
            items: [
                {
                    label: 'Agregar Usuario',
                    icon: 'size-icons custom-icon-adduser',
                    to: (jsondata.userType==1)?('/admin/add-collaborator'):('/admin/add-collaborator'+window.location.search)
                },
                {
                    label: 'Usuarios autorizados',
                    icon: 'size-icons custom-icon-userautorization',
                    to: (jsondata.userType==1)?('/admin/users'):('/admin/users'+window.location.search)
                }
            ]
        },
        {
            label: 'Administración',
            icon: 'pi pi-fw pi-briefcase',
            to: (jsondata.userType==1)?('/admin/home'):('/admin/home'+window.location.search),
            items: [
                {
                    label: 'Mi perfil',
                    icon: 'size-icons custom-icon-useri',
                    to: (jsondata.userType==1)?('/admin/adm-profile'):('/admin/adm-profile'+window.location.search)
                },
                {
                    label: 'Mi Billetera',
                    icon: 'size-icons custom-icon-wallet',
                    to: (jsondata.userType==1)?('/admin/adm-wallet'):('/admin/adm-wallet'+window.location.search)
                },
                {
                    label: 'Servicio Contratado',
                    icon: 'size-icons custom-icon-bouthservice',
                    to: (jsondata.userType==1)?('/admin/services-invoice'):('/admin/services-invoice'+window.location.search)
                },
                {
                    label: 'Comprar Servicios',
                    icon: 'size-icons custom-icon-buyservice',
                    to: (jsondata.userType==1)?('/admin/services-pay'):('/admin/services-pay'+window.location.search)
                },
                // {
                //     label: 'Mi Plan',
                //     icon: 'size-icons custom-icon-task',
                //     to: '/pages/timeline'
                // }
            ]
        },
        
        {
            label: 'Utilidades',
            items: [
                {
                    label: 'Documentación',
                    icon: 'size-icons custom-icon-manual',
                    to: (jsondata.userType==1)?('/admin/documentation'):('/admin/documentation'+window.location.search)
                },
                {
                    label: 'Soporte',
                    icon: 'size-icons custom-icon-support',
                    to: (jsondata.userType==1)?('/admin/send-mail'):('/admin/send-mail'+window.location.search),
                }
            ]
        }
    ];
   const setInfoInit = () =>{
        var userdata = localStorage.getItem('user');
        var jsondata = JSON.parse(userdata);
        if (infoState) {
        return;  
        }
        if (get_permission_extend.valueGlobal.length!=0) {
              setinfoState(true)
            }else{
              return
            }
   var getTypeAreaWork = localStorage.getItem('type_area_work')
   if (getTypeAreaWork=='trazability') {
    jQuery("#gestióndecultivos").remove()
    jQuery("#comercialización").remove()
   }
   if (getTypeAreaWork=='production') {
    jQuery("#trazabilidad").remove()
    jQuery("#comercialización").remove()
   }
   if (getTypeAreaWork=='commerce') {
    jQuery("#trazabilidad").remove()
    jQuery("#gestióndecultivos").remove()
   }

    var dataPermission = get_permission_extend.valueGlobal
    var dataPermissionMain = get_permission_extend.valueDataMain
        var regexStr = /V1F|V2F|V3F|V4F/gi
      if (dataPermission.type_traza.indexOf('T')==-1) {
        if (dataPermission.type_traza.search(regexStr)==-1) {
          jQuery("#trazabilidad").remove()
        }
        if (dataPermission.role==4) {
          jQuery("#gestióndecultivos").remove()
        }
        if (dataPermission.type_traza.search(regexStr)!=-1) {
          jQuery("#frutasyvegetales").remove()
          jQuery("#frutasyvegetalesgc").remove()
          jQuery("#frutasyvegetaleskdes").remove()
          jQuery("#cárnicos").remove()
          jQuery("#granos").remove()
          jQuery("#procesados").remove()
          jQuery("#pescadosymariscos").remove()
          jQuery("#vistatrace").remove()
          jQuery("#trazabilidadenproceso").remove()
          jQuery("#historial").remove()
          jQuery("#blockchain").remove()
        }
      }else{
        if (dataPermission.type_traza.search(regexStr)==-1) {
        //console.log(dataPermission.type_traza)
          if (jsondata.userType!=1) {
            jQuery("#trazabilidad").remove()
          }
        }
        if (dataPermission.type_traza.search(regexStr)!=-1) {
          jQuery("#frutasyvegetales").remove()
          jQuery("#frutasyvegetalesgc").remove()
          jQuery("#frutasyvegetaleskdes").remove()
          jQuery("#cárnicos").remove()
          jQuery("#granos").remove()
          jQuery("#procesados").remove()
          jQuery("#pescadosymariscos").remove()
          jQuery("#vistatrace").remove()
          jQuery("#trazabilidadenproceso").remove()
          jQuery("#historial").remove()
          jQuery("#blockchain").remove()
        }

      }
      if (dataPermission.type_traza.indexOf('P')==-1) {
        jQuery("#granosgc").remove()
      }
      // if (dataPermission.type_traza==null) {
      //   jQuery("#frutasyvegetalesgc").remove()
      // }else{
      // }
        if (dataPermission.type_traza.indexOf('VCF')==-1) {
          jQuery("#frutasyvegetalesgc").remove()
        }

      if (dataPermission.type_traza.indexOf('C')==-1) {
        jQuery("#comercialización").remove()
      }
      //console.log(jsondata.userType)
      if (jsondata.userType==1 || jsondata.userType==2 || jsondata.userType==5) {
          jQuery("#productores").remove()
          jQuery("#producciónmonitorear").remove()
      }
    if (jsondata.userType==2) {
          jQuery(".navbar-nav").show()
          //console.log(dataPermission.type_traza)
            if (dataPermission.type_traza.indexOf('P')==-1) {
              jQuery("#registrarnuevaproducción").remove()
              jQuery("#producciónenproceso").remove()
              jQuery("#vistaproducción").remove()
              //jQuery("#blockchain").remove()
              //jQuery("#historial").remove()
            }
      
            jQuery("#administración").remove()
            jQuery("#agregarcolaborador").remove()
            if (dataPermission.type_traza=='VF') {
              jQuery("#cárnicos").remove()
              jQuery("#procesados").remove()
              jQuery("#pescadosymariscos").remove()
            }
           if (dataPermission.type=='view') {
            jQuery("#satélitesenproceso").remove()
            jQuery("#registrarnuevaproducción").remove()
            jQuery("#producciónenproceso").remove()
            jQuery("#blockchain").remove()
            //jQuery("#historial").remove()
           }
            if (dataPermission.type_traza.indexOf('VF')!=-1) {
              
              jQuery("#iniciartrazabilidad").remove()
              jQuery("#operaciones").remove()
              jQuery("#trazabilidadenprocesokdes").remove()
              jQuery("#historialkdes").remove()
              //jQuery("#trazabilidadenproceso").remove()
            }
            if (dataPermission.type=='admin') {
              jQuery("#satélitesenproceso").remove()
              jQuery("#registrarnuevaproducción").remove()
              jQuery("#blockchain").remove()
              jQuery("#historial").remove()
            }
            // if (dataPermission.type_traza.search(regexStr)!=-1) {
            //   return
            // }
            if (dataPermission.type=='options' && dataPermission.type_traza.search(regexStr)==-1) {
              jQuery("#satélitesenproceso").remove()
              jQuery("#registrarnuevaproducción").remove()
              jQuery("#blockchain").remove()
              jQuery("#historial").remove()
              jQuery("#vegetalesyfrutas").remove()
              jQuery("#cárnicos").remove()
              jQuery("#granos").remove()
              jQuery("#procesados").remove()
              jQuery("#pescadosymariscos").remove()
            }
            jQuery("#usuarios").remove()
        }else{

          if (dataPermission.type_traza.indexOf('VF')!=-1) {
            jQuery("#trazabilidadenprocesokdes").remove()
            jQuery("#historialkdes").remove()
          }
          jQuery(".navbar-nav").show()
        }

        if (jsondata.userType==4) {
          if (dataPermission.type_traza!="ON") {
            jQuery("#monitorear").remove()
          }
          jQuery("#operaciones").remove()
          jQuery("#usuarios").remove()
          jQuery("#capacitación").remove()
          jQuery("#mibilletera").remove()
          jQuery("#serviciocontratado").remove()
          jQuery("#comprarservicios").remove()
          
        }

      }
      setInfoInit()
    return (
        <MenuProvider>
            <ul className="layout-menu">
                {AppMenuItem.map((item, i) => {
                    return !item?.seperator ? <AppMenuitem item={item} root={true} index={i} key={item.label} /> : <li className="menu-separator"></li>;
                })}

            </ul>
        </MenuProvider>
    );
};

export default AppMenu;
